<template>
	<div class="card card-custom bg-gray-100 card-stretch">
		<div class="card-header border-0"  v-if="forList.title">
			<h3>{{ forList.title }}</h3>
		</div>
		<div class="card-body pt-2">
			 <b-row>
				<div class="col">
					<v-btn depressed color="success"@click="forList.onAdd" v-if="forList.rights.add">
						<v-icon left>
							far fa-plus-square
						</v-icon>
						 {{ forList.titleAdd }}
					</v-btn>
				</div>
				<div class="col-auto" v-if="forList.withQickSearch">
					<v-text-field filled 
						v-model="forList.quickSearch" 
						@click:clear="forList.clearSearch"
						v-on:keyup.enter="forList.onSearch"
						:placeholder="$t('general.search')"
						:loading="forList.isLoading"
						:disabled="forList.isLoading"
						prepend-inner-icon="far fa-search"
						clearable
						dense
						filled>
					</v-text-field>
				</div>
			</b-row>
			<v-data-table
				:headers="forTable.headers"
				:items="forTable.items"
				:items-per-page="forTable.itemsPerPage"
				:options.sync="forTable.options"
				:server-items-length="forTable.totalItems"
				:loading="forList.isLoading"
				:footer-props="{
					showFirstLastPage: true,
					itemsPerPageOptions : [
						20,
						50,
						100
					],
					firstIcon: 'far fa-arrow-to-left',
					lastIcon: 'far fa-arrow-to-right',
					prevIcon: 'fas fa-arrow-alt-left',
					nextIcon: 'fas fa-arrow-alt-right'
				}"
				:no-data-text="$t('list.noData')"
				:loading-text="$t('list.isLoading')"
				class="elevation-1 table-striped">
					<template v-slot:item.active="{ item }">
						<v-icon color="success" v-if="item.active == 1">far fa-check</v-icon>
						<v-icon color="warning" v-else>far fa-ban</v-icon>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn icon color="primary" @click="forTable.onEdit(item)" :title="forList.titleEdit" v-if="item.rights.edit">
							<v-icon>far fa-pencil</v-icon>
						</v-btn>
						<slot name="item.customactions"  :item="item"></slot>
						<v-btn icon color="danger" @click="forTable.onDelete(item)" :title="forList.titleDelete" v-if="item.rights.delete">
							<v-icon>far fa-trash</v-icon>
						</v-btn>
					</template>
					<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
						<slot :name="slot" v-bind="scope" />
					</template>
			</v-data-table>
		</div>
		<delete-modal 
			v-if="forDelete.open" 
			:forDelete="forDelete">
			<slot name="deleteLayout"></slot>
		</delete-modal>
		<v-overlay :value="forList.isLoading" opacity="0.2" absolute>
			<v-progress-circular
				indeterminate
				size="64" color="primary"></v-progress-circular>
		</v-overlay>
	</div>
</template>
<script>
	//https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component/52823029#52823029
	import Vue from 'vue';
	const deleteDialog = Vue.Aq.Loader.layout().module('DeleteDialog').get();

	export default {
		components: {
			'delete-modal': deleteDialog
		},
		computed: {
			tableOptions() 
			{
				return this.forTable.options;
			}
		},
		watch: {
			tableOptions() 
			{
				this.forTable.loadData();
			}
		},
		props: {
			forList : { type : Object },
			forDelete: { type : Object },
			forTable : {type : Object}
		}
	};
</script>