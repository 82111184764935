<template>
	<div class="d-lg-flex flex-row-fluid">
		<div class="content-wrapper flex-row-fluid">
			<div class="d-flex flex-row">
				<div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
					<MenuAside
						:data="this.data"
						:boolIsEditEmployee="this.boolIsEditEmployee"
						@changeEmployeeTab="changeEmployeeTab($event)"
					/>
				</div>
				<div class="flex-row-fluid ml-lg-8">
					<div class="tab-content">
						<div class="tab-pane active">
							<div class="row">
								<div class="col-lg-12">
									<div
										class="card card-custom bg-white-100 card-stretch gutter-b"
									>
										<b-card no-body>
											<b-form
												name="EditEmployeeForm"
												@submit.prevent="onSubmit"
											>
												<b-card-body class="p-0">
													<ValidationObserver
														ref="observerStammdaten"
													>
														<b-card
															v-show="
																this.isTabSelected(
																	'tabStammdaten',
																)
															"
														>
															<MitarbeiterCardHeader
																title="Stammdaten"
																description="Aktualisieren sie die Mitarbeiterinformationen"
															/>
															<b-card-text>
																<TabStammdaten
																	:data="
																		this
																			.data
																	"
																/>
															</b-card-text>
														</b-card>
													</ValidationObserver>

													<ValidationObserver
														ref="observerPersonalinformation"
													>
														<b-card
															v-show="
																this.isTabSelected(
																	'tabPersonalinformationen',
																)
															"
														>
															<MitarbeiterCardHeader
																title="Personalinformationen"
																description="Aktualisieren sie die Personalinformationen"
															/>
															<b-card-text>
																<TabPersonalinformation
																	:data="
																		this
																			.data
																	"
																/>
															</b-card-text>
														</b-card>
													</ValidationObserver>

													<b-card
														v-show="
															this.isTabSelected(
																'tabVerguetung',
															)
														"
													>
														<MitarbeiterCardHeader
															title="Vergütung"
															description="Pflegen sie das aktuelle Gehalt & etwaige Provisionen des Mitarbeiters"
														/>
														<b-card-text>
															<TabVerguetung
																:boolIsEditEmployee="
																	this
																		.boolIsEditEmployee
																"
																@saveNewSalary="
																	tabSaveNewSalary
																"
																:data="
																	this.data
																"
															/>
														</b-card-text>
													</b-card>
													<b-card
														v-if="
															boolIsEditEmployee
														"
														v-show="
															this.isTabSelected(
																'tabGehaltsentwicklung',
															)
														"
													>
														<MitarbeiterCardHeader
															title="Gehaltsentwicklung"
															description="Übersicht über die Gehaltsentwicklung des Mitarbeiters"
														/>
														<TabGehaltsentwicklung
															v-if="
																this.data.salary
															"
															:data="this.data"
														/>
													</b-card>
													<b-card
														v-if="
															boolIsEditEmployee
														"
														v-show="
															this.isTabSelected(
																'tabDokumente',
															)
														"
													>
														<MitarbeiterCardHeader
															title="Dokumente"
															description="Ablage wichtiger Dokumente zum Mitarbeiter"
														/>
														<TabDokumente
															@afterSaveDocument="
																getData
															"
															:data="this.data"
														/>
													</b-card>
													<b-card
														v-if="
															boolIsEditEmployee
														"
														v-show="
															this.isTabSelected(
																'tabPersonalakt',
															)
														"
													>
														<MitarbeiterCardHeader
															title="Personalakt"
															description="Übersicht der Personaleinträge"
														/>
														<TabPersonalakt
															@tabSaveEvent="
																tabSaveEvent
															"
															:data="this.data"
														/>
													</b-card>
												</b-card-body>
												<b-card-footer
													class="card card-body bg-light"
												>
													<div class="text-right">
														<b-button
															variant="secondary"
															>Abbrechen</b-button
														>&nbsp;
														<b-button
															variant="primary"
															type="submit"
															><em
																class="fad fa-save"
															></em
															>&nbsp;Speichern</b-button
														>
													</div>
												</b-card-footer>
											</b-form>
										</b-card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Mitarbeiter();

import { dateFormatFromDatabaseToView, dateFormatFromViewToDatabase } from '@/core/project/plugins/dayjs';
import { fetchEmployee, saveEmployee } from '@/view/project/api/EmployeeData';
import { fetchSkills } from '@/view/project/api/BaseData';
import AqToast from '@/view/project/components/AqToast';

let MitarbeiterCardHeader = Controller.loadComponent('MitarbeiterCardHeader');
let TabStammdaten = Controller.loadPartial('TabStammdaten');
let TabPersonalinformation = Controller.loadPartial('TabPersonalinformation');
let TabVerguetung = Controller.loadPartial('TabVerguetung');
let TabGehaltsentwicklung = Controller.loadPartial('TabGehaltsentwicklung');
let TabDokumente = Controller.loadPartial('TabDokumente');
let TabPersonalakt = Controller.loadPartial('TabPersonalakt');
let MenuAside = Controller.loadPartial('MenuAside');

export default {
	name: 'MitarbeiterIndex',
	components: {
		MenuAside,
		TabStammdaten,
		TabPersonalinformation,
		TabVerguetung,
		TabGehaltsentwicklung,
		TabDokumente,
		TabPersonalakt,
		MitarbeiterCardHeader,
	},
	created() {
		if (this.boolIsEditEmployee) {
			Controller.setBreadcrump('edit');
			this.getData();
		} else {
			Controller.setBreadcrump('new');
		}
	},
	data() {
		return {
			selectedTab: 'tabStammdaten',
			data: {
				firstname: '',
				lastname: '',
				email: '',
				gender: 'm',
				privatedata: {
					country_code: 'AT',
					children: 0,
				},
				businessdata: {
					phone: '',
					phone_2: '',
					email: '',
				},
        skillswerte: {},
				branchoffice: {},
				employmentdata: {
					hours_per_week: 38.5,
					emplyoment_id: 1,
				},
			},
		};
	},
	computed: {
		boolIsEditEmployee() {
			return typeof this.$route.params.viewKey !== 'undefined';
		},
	},
	methods: {
		changeEmployeeTab(changedTabName) {
			this.selectedTab = changedTabName;
		},
		isTabSelected(tabName) {
			return this.selectedTab === tabName;
		},
		async tabSaveEvent() {
			await this.onSubmit();
			await this.getData();
		},
		async tabSaveNewSalary(objSalary) {
			this.data.salary.push(objSalary);
			await this.onSubmit();
		},
		async getData() {
			fetchEmployee(this.$route.params.viewKey)
				.then(response => {
					if (response.data.status === true) {
						this.data = response.data.data;

						this.data.salary.map(objSalary => {
							objSalary.strPopoverId =
								'popoverVerguetungHistory' + objSalary.id;
							return objSalary;
						});
						if (this.data.employmentdata.employment_end) {
              this.data.employmentdata.employment_end_formatted = dateFormatFromDatabaseToView(
                  this.data.employmentdata.employment_end,
              );
            }
						this.data.employmentdata.employment_start_formatted = dateFormatFromDatabaseToView(
							this.data.employmentdata.employment_start,
						);

            this.getSkills();

					} else {
						new AqToast().callToast(response.message);
					}
				})
				.catch(error => {
					new AqToast().callToast(error);
				});
		},
		async onSubmit() {
			let _boolPersonaInformationValidation = await this.$refs.observerPersonalinformation.validate();
			let _boolStammdatenValidation = await this.$refs.observerStammdaten.validate();

			if (
				!_boolPersonaInformationValidation ||
				!_boolStammdatenValidation
			) {
				if (!_boolPersonaInformationValidation) {
					new AqToast().showValidationErrors(
						this.$refs.observerPersonalinformation.errors,
						'Personalinformationen',
					);
				}

				if (!_boolStammdatenValidation) {
					new AqToast().showValidationErrors(
						this.$refs.observerStammdaten.errors,
						'Stammdaten',
					);
				}
			} else {

        let objToSubmit = Object.assign({}, this.data);

        objToSubmit.employmentdata.employment_start = dateFormatFromViewToDatabase(
            this.data.employmentdata.employment_start_formatted,
        );

        if (this.data.employmentdata.employment_end_formatted) {
          objToSubmit.employmentdata.employment_end = dateFormatFromViewToDatabase(
              this.data.employmentdata.employment_end_formatted,
          );
        }

        objToSubmit.skillswerte = this.data.skillswerte
            .filter(skill => (skill.checked === true || skill.checked === 'true'))
            .map((item) => {
              return {
                'people_id': this.data.id,
                'skill_id': item.value
              }
          });

        saveEmployee(objToSubmit).then(response => {
					if (!this.boolIsEditEmployee) {
						let _userData = response.data;
						this.showSuccessToastr();
            this.$router.push({
              path: 'edit/' + _userData.data.slug
            });
					} else {
						this.showSuccessToastr();
						this.getData();
					}
				});
			}
		},
		showSuccessToastr() {
			new AqToast().callToast(
				this.boolIsEditEmployee
					? 'Mitarbeiter wurde erfolgreich gespeichert'
					: 'Mitarbeiter wurde erfolgreich angelegt',
				'success',
			);
		},
    getSkills() {
      fetchSkills().then(response => {
        this.skills = response.data.data[1].map(object => ({
          value: object.id,
          text: object.title,
        }));

        const boolIsNewObject = this.data.skillswerte && Object.keys(this.data.skillswerte).length === 0;
        const selectedSkills = (boolIsNewObject) ? this.data.skillswerte : [...this.data.skillswerte];
        this.data.skillswerte = [...this.skills.map((item) => Object.assign({}, item, (boolIsNewObject) ? {} :
            selectedSkills.find(skill => item.value === skill.skill_id) ?? {}
        ))
        ];
        this.data.skillswerte.map(item => {
          return item.checked = (item.people_id) ? true : false;
        });

      });
    }
	},
};
</script>
