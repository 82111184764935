<template>
	<div>
		<b-row class="border-top my-3 pt-5">
			<h4>Reifen</h4>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0 border-right">
				<ReifenPartialComponent
					:text="'Vorne links'"
					:data="this.data"
					:data-binding="this.frontLeftBinding"
				/>
				<ReifenPartialComponent
					:text="'Hinten links'"
					:data="this.data"
					:data-binding="this.rearLeftBinding"
				/>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<ReifenPartialComponent
					:text="'Vorne rechts'"
					:data="this.data"
					:data-binding="this.frontRightBinding"
				/>
				<ReifenPartialComponent
					:text="'Hinten rechts'"
					:data="this.data"
					:data-binding="this.rearRightBinding"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import ReifenPartialComponent from './ReifenPartialComponent';

export default {
	name: 'ReifenComponent',
	components: { ReifenPartialComponent },
	props: {
		data: Object,
	},
	data() {
		return {
			frontLeftBinding: {
				name: 'front_left',
				profile: 'tyre_front_left_profile',
				defect: 'tyre_front_left_defect',
				type: 'tyre_front_left_type',
				kind: 'tyre_front_left_kind',
			},
			frontRightBinding: {
				name: 'front_right',
				profile: 'tyre_front_right_profile',
				defect: 'tyre_front_right_defect',
				type: 'tyre_front_right_type',
				kind: 'tyre_front_right_kind',
			},
			rearLeftBinding: {
				name: 'rear_left',
				profile: 'tyre_rear_left_profile',
				defect: 'tyre_rear_left_defect',
				type: 'tyre_rear_left_type',
				kind: 'tyre_rear_left_kind',
			},
			rearRightBinding: {
				name: 'rear_right',
				profile: 'tyre_rear_right_profile',
				defect: 'tyre_rear_right_defect',
				type: 'tyre_rear_right_type',
				kind: 'tyre_rear_right_kind',
			},
		};
	},
};
</script>

<style scoped></style>
