<template>
  <div class="card-header py-0">
    <div class="card-title align-items-start flex-column" >
      <h3 class="card-label font-weight-bolder text-dark"> {{this.title}} </h3>
      <span class="text-muted font-weight-bold font-size-sm mt-1"> {{this.description}}</span>
    </div>
  </div>
</template>

<script>
export default {
	name: 'MitarbeiterCardHeader',
	components: {},
	data() {
		return {};
	},
	computed: {},
	props: {
		title: String,
		description: String,
	},
};
</script>
