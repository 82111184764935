/**
 * General Class for the creation of a list view with datatables
 */
//import Vue from 'vue';
import $ from 'jquery';
import i18n from '@/core/plugins/vue-i18n';

import {
	AqDtDefaultClassName,
	AqDtOptionsBuilder,
	AqDtColumnBuilder,
} from '@/core/project/plugins/datatables/aqDtIndex';

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

class AqViewList {
	constructor() {
		//the AqDtOptionsBuilder() object
		this.OptionsBuilder = null;

		//the url for the backend, created via buildUrl()
		this.strApiUrl = null;

		//default options
		this.options = {
			route: 'module',
			key: false,
			url: false,
		};

		//the actual data, set via setData()
		this.data = null;
	}

	/**
	 * Builds the API Url via AqDtOptionsBuilder
	 */
	init(options) {
		//merge of default and normal
		if (typeof options === 'object') {
			this.options = { ...this.options, ...options };
		}

		if (this.options.url) {
			this.buildUrl(this.options.url);
		}
	}

	/**
	 * Builds the API Url via AqDtOptionsBuilder
	 */
	buildUrl(url) {
		this.strApiUrl = process.env.VUE_APP_API_PATH + url;
		this.OptionsBuilder = new AqDtOptionsBuilder().setAjax(this.strApiUrl);
	}

	/**
	 * Sets the current Breadcrump via SET_BREADCRUMB
	 */
	setBreadcrump(breadcrump) {
		window.Aq.App.$store.dispatch(SET_BREADCRUMB, breadcrump);
	}

	/**
	 * The default Data for every List View
	 */
	initData(data) {
		let defaultData = {
			className: AqDtDefaultClassName,
			title: false,
			details: {},
			quickSearch: null,
			withQickSearch: true,
			showDelete: false,
			passEntry: null,
			rights: {
				add: false,
			},
		};
		if (this.OptionsBuilder !== null) {
			defaultData.options = this.OptionsBuilder.buildOptions();
		}

		//merge of default and normal
		if (typeof data === 'object') {
			return { ...defaultData, ...data };
		} else {
			return defaultData;
		}
	}

	/**
	 * The current Data from the List
	 */
	setData(data) {
		this.data = data;
	}

	/**
	 * Build a Column via the AqDtColumnBuilder Object
	 */
	buildColumn() {
		return new AqDtColumnBuilder();
	}

	columnID() {
		return this.buildColumn()
			.setName('id')
			.setTitle(i18n.tc('general.id'))
			.build();
	}

	columnTitle() {
		return this.buildColumn()
			.setName('title')
			.setTitle(i18n.tc('general.title'))
			.build();
	}

	/**
	 * creates the Actions for a list Entry
	 */
	columnActions() {
		let obj = this;

		return {
			name: null,
			data: null,
			isLocal: true,
			searchable: false,
			sortable: false,
			label: i18n.tc('general.actions'),
			className: 'text-right',
			render: function(data, type, row) {
				let actions = '';
				let rights = obj.setRights(row);
				if (rights.edit) {
					actions += obj.actionEdit();
				}
				if (rights.delete) {
					actions += obj.actionDelete();
				}
				return actions;
			},
		};
	}

	/**
	 * Creates the right object and merges it with the available rights from the row
	 */
	setRights(row) {
		let rights = {
			edit: true,
			delete: true,
		};
		if (typeof row.rights === 'object') {
			rights = { ...rights, ...row.rights };
		}
		return rights;
	}

	/**
	 * The Html for the edit Button
	 */
	actionEdit() {
		return (
			`
			<a class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1" href="javascript:void(0);" data-action="edit" title="` +
			i18n.tc('general.editEntry') +
			`">
				<em class="far fa-fw fa-edit"></em>
			</a>`
		);
	}

	/**
	 * The Html for the delete Button
	 */
	actionDelete() {
		return (
			`
		<a class="btn btn-icon btn-light btn-hover-danger btn-sm ml-1" href="javascript:void(0);" data-action="delete" title="` +
			i18n.tc('general.deleteEntry') +
			`">
		<em class="far fa-fw fa-trash"></em>
		</a>`
		);
	}

	/**
	 * Builds a route for the current module
	 */
	route(view, optionalParams) {
		let params = {
			module: this.options.key,
			view: view,
		};
		if (typeof optionalParams === 'object') {
			params = { ...params, ...optionalParams };
		}

		console.log(params);

		window.Aq.App.$router.push({
			name: this.options.route,
			params: params,
		});
	}

	paramsForRoute(data) {
		let params = {};
		if (typeof data.slug !== 'undefined' && data.id != '1') {
			params.slug = data.slug;
			params.viewKey = params.slug;
		} else {
			params.id = data.id;
			params.viewKey = params.id;
		}
		return params;
	}

	/**
	 * the default Methods which are available
	 */
	initMethods(methods) {
		let obj = this;

		let defaultMethods = {
			async doLoadTable(cb) {
				console.log('doLoadTable ' + obj.options.key);
				await $.getJSON(
					this.strApiUrl,
					{ withCredentials: true },
					data => {
						console.log(data);
						cb(data);
					},
				);
			},
			onSearch() {
				console.log('onSearch ' + obj.options.key);
				//this.$refs.table.search(this.quickSearch);
			},
			onEdit(data) {
				obj.route('edit', obj.paramsForRoute(data));
			},
			onDelete(data) {
				console.log('showDelete');
				obj.data.showDelete = true;
				obj.data.passEntry = data;
			},
			hideDelete() {
				obj.data.showDelete = false;
				obj.data.passEntry = null;
				console.log('hideDelete');
			},
			onAdd() {
				obj.route('new');
			},
		};

		defaultMethods.doDelete = function(data) {
			console.log(data);
			defaultMethods.hideDelete();
		};

		if (typeof methods === 'object') {
			return { ...defaultMethods, ...methods };
		} else {
			return defaultMethods;
		}
	}

	/**
	 * the default functions for computed
	 */
	initComputed(computed) {
		let defaultComputed = {
			backgroundImage() {
				return process.env.BASE_URL + 'assets/img/bg1.jpg';
			},
		};

		if (typeof computed === 'object') {
			return { ...defaultComputed, ...computed };
		} else {
			return defaultComputed;
		}
	}
}

export default AqViewList;
