import Vue from 'vue';
//import { BToast } from 'bootstrap-vue';

class AqToast {
	constructor() {
		this.strDefaultType = 'error';
	}

	callToast(strMessage, strType = this.strDefaultType) {
		Vue.$toast.open({
			message: strMessage,
			type: strType,
			dismissible: true,
			position: 'top-right',
			duration: 10000,
		});
	}

	showValidationErrors(objErrors, strTitle) {
		let strValidationErrorText =
			Object.values(objErrors)
				.filter(value => value.length > 0)
				.map(value => '<li>' + value + '</li>') ?? '';

		strValidationErrorText = strValidationErrorText
			.toString()
			.replace(/,/g, '');

		Vue.$toast.open({
			message:
				'<strong>' +
				strTitle +
				'</strong><br /><br />' +
				strValidationErrorText,
			type: 'error',
			dismissible: true,
			position: 'top-right',
			duration: 30000,
		});
	}
}

export default AqToast;
