/**
 *A creater for Parameters for an Api Call
 * @author E.Neuwirth
 */
import Vue from 'vue';

class AqApiParams {
	constructor() {
		this.params = {};

		this._columns_index = 0;
		this._column_index = {};

		//https://aqendo.loc/api/v1/resources/aqe/people/items?where[gender]=m&where[title]=dr.
		//https://aqendo.loc/api/v1/resources/aqe/people/items?where[user:id]=1
		//&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true&columns[0][search][value]=&columns[0][search][regex]=false
	}

	/* types ----------------------------------------------- start*/

	forList() {
		this.params.draw = 1;
		return this;
	}

	/* types ----------------------------------------------- end*/

	/* params ----------------------------------------------- start*/

	where(key, value) {
		if (typeof this.params.where === 'undefined') {
			this.params.where = {};
		}
		this.params.where[key] = value;
		return this;
	}

	whereID(value) {
		return this.where('id', parseInt(value));
	}

	connection(value) {
		if (typeof this.params.connections === 'undefined') {
			this.params.connections = new Array();
		}
		this.params.connections.push(value);

		return this;
	}

	connections(connectionsArray) {
		if (typeof connectionsArray === 'object') {
			for (let index in connectionsArray) {
				this.connection(connectionsArray[index]);
			}
		}
		return this;
	}

	column(column) {
		if (typeof this.params.columns === 'undefined') {
			this.params.columns = new Array();
		}
		this.params.columns.push(column);
		this._column_index[column.data] = this._columns_index;
		this._columns_index++;
		return this;
	}

	columnsArray(columns) {
		if (typeof columns === 'object') {
			for (let index in columns) {
				let column = columns[index];
				if (typeof column.value !== 'undefined') {
					let apiColumn = Vue.Aq.Column().buildBackend();

					apiColumn.data = column.value;
					apiColumn.name = column.value;
					if (typeof column.data !== 'undefined') {
						apiColumn.data = column.data;
					}

					if (typeof column.sortable !== 'undefined') {
						apiColumn.orderable = column.sortable;
					}
					if (typeof column.searchable !== 'undefined') {
						apiColumn.searchable = column.searchable;
					}
					if (apiColumn.searchable || apiColumn.orderable) {
						this.column(apiColumn);
					}
				}
			}
		}
		return this;
	}

	order(key, dir) {
		if (typeof this.params.order === 'undefined') {
			this.params.order = new Array();
		}

		let column_index = 0;
		if (typeof this._column_index[key] !== 'undefined') {
			column_index = this._column_index[key];
		}

		this.params.order.push({
			column: column_index,
			dir: dir,
		});
		return this;
	}

	search(value) {
		this.params.search = {
			value: value,
		};
		return this;
	}

	orderArray(sorting, directions) {
		if (typeof sorting === 'object') {
			for (let index in sorting) {
				let key = sorting[index];
				let dir = 'asc';
				if (
					typeof directions === 'object' &&
					directions[index] !== 'undefined' &&
					directions[index]
				) {
					dir = 'desc';
				}
				this.order(key, dir);
			}
		}
		return this;
	}

	paging(page, limit) {
		this.params.length = limit;
		if (page == 1) {
			this.params.start = 0;
		} else {
			this.params.start = parseInt((page - 1) * limit);
		}
		return this;
	}

	/* params ----------------------------------------------- end*/

	build() {
		return this.params;
	}
}

export default AqApiParams;
