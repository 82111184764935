<template>
	<view-list :forList="forList" :forDelete="forDelete" :forTable="forTable">
		<template v-slot:deleteLayout>
			<b-row>
				<div class="col-auto">
					{{ forDelete.passEntry.id }}
				</div>
				<div class="col">
					{{ forDelete.passEntry.firstname }}
				</div>
				<div class="col">
					{{ forDelete.passEntry.lastname }}
				</div>
			</b-row>
		</template>
	</view-list>
</template>
<script>
import Vue from 'vue';

const Controller = Vue.Aq.Modules.Mitarbeiter();
const ViewList = Controller.newList();

export default {
	name: 'Mitarbeiter-Index',
	components: ViewList.initComponents(),
	mounted() {
		Controller.setBreadcrump('index');
	},
	data() {
		return ViewList.forTable({
			headers: [
				ViewList.Column()
					.columnID()
					.build(),
				ViewList.Column()
					.textTranslation('branchoffice')
					.crud('branchoffice.')
					.field('title')
					.build(),
				ViewList.Column()
					.columnTitle()
					.build(),
				ViewList.Column()
					.columnFirstName()
					.build(),
				ViewList.Column()
					.columnLastName()
					.build(),
				ViewList.Column().columnActions(),
			],
			forApi: function(apiParams) {
				return apiParams
					.connection('businessdata')
					.connection('branchoffice');
			},
		}).initData(this);
	},
	computed: ViewList.initComputed(),
};
</script>
