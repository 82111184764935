<template>
	<div v-if="data && data.privatedata">
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Vorname"
					rules="required"
				>
					<AqFormGroup
						label="Vorname"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-firstname"
							v-model="data.firstname"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Nachname"
					rules="required"
				>
					<AqFormGroup
						label="Nachname"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-lastname"
							v-model="data.lastname"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Geschlecht"
					rules="required"
				>
					<AqFormGroup
						label="Geschlecht"
						labelcolssm="6"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-geschlecht"
							:options="genders"
							v-model="data.gender"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Geschlecht"
					rules="required"
				>
					<AqFormGroup
						label="Nationalität"
						labelcolssm="6"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-nationalitaet"
							:options="countries"
							v-model="data.privatedata.country_code"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="py-5">
			<h4>Geschäftliche Daten</h4>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Telefonnummer"
					rules=""
				>
					<AqFormGroup label="Telefonnummer" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-phone"
								v-model="data.businessdata.phone"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Telefonnummer2"
					rules=""
				>
					<AqFormGroup label="Telefonnummer 2" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-phone_2"
								v-model="data.businessdata.phone_2"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="E-Mail"
					rules=""
				>
					<AqFormGroup label="E-Mail" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-envelope"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-email"
								v-model="data.businessdata.email"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="py-5">
			<h4>Private Daten</h4>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Strasse"
					rules=""
				>
					<AqFormGroup label="Strasse" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-house"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-street"
								v-model="data.privatedata.street"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="PLZ"
					rules=""
				>
					<AqFormGroup
						label="PLZ"
						labelcolssm="6"
						labelcolslg="6"
						:state="state"
					>
						<b-form-input
							id="input-postal_code"
							v-model="data.privatedata.postal_code"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Ort"
					rules=""
				>
					<AqFormGroup
						label="Ort"
						labelcolssm="6"
						labelcolslg="6"
						:state="state"
					>
						<b-form-input
							id="input-place"
							v-model="data.privatedata.place"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Private Telefonnummer"
					rules=""
				>
					<AqFormGroup label="Telefonnummer" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-private_phone"
								v-model="data.privatedata.private_phone"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Private Telefonnummer 2"
					rules=""
				>
					<AqFormGroup label="Telefonnummer 2" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-private_phone_2"
								v-model="data.privatedata.private_phone_2"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Private E-Mail"
					rules=""
				>
					<AqFormGroup label="E-Mail" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-envelope"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-private_email"
								v-model="data.privatedata.private_email"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Kinder"
					rules=""
				>
					<AqFormGroup
						label="Anzahl Kinder"
						labelcolssm="6"
						labelcolslg="6"
						:state="state"
					>
						<b-form-select
							id="input-kinder"
							:options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
							v-model="data.privatedata.children"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="py-5">
			<h4>Notfallskontakt</h4>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Notfallskontakt - Vorname"
					rules=""
				>
					<AqFormGroup label="Vorname" :state="state">
						<b-form-input
							id="input-emergencyfirstname"
							v-model="
								data.privatedata.emergencycontact_firstname
							"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Notfallskontakt - Nachname"
					rules=""
				>
					<AqFormGroup label="Nachname" :state="state">
						<b-form-input
							id="input-emergencylastname"
							v-model="data.privatedata.emergencycontact_lastname"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Notfallskontakt - Verhaeltnis"
					rules=""
				>
					<AqFormGroup
						label="Verhältnis zu Notfallskontakt"
						:state="state"
					>
						<b-form-input
							id="input-relationship"
							v-model="
								data.privatedata.emergencycontact_relationship
							"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>

		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Notfallskontakt - Telefonnummer"
					rules=""
				>
					<AqFormGroup label="Telefonnummer" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-emergencyphone"
								v-model="
									data.privatedata.emergencycontact_phone
								"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>

		<b-row>
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Notfallskontakt - Telefonnummer2"
					rules=""
				>
					<AqFormGroup label="Telefonnummer 2" :state="state">
						<b-input-group class="mt-3">
							<template v-slot:prepend>
								<b-input-group-text
									><em class="fad fa-phone"></em
								></b-input-group-text>
							</template>
							<b-form-input
								id="input-emergencyphone2"
								v-model="
									data.privatedata.emergencycontact_phone_2
								"
								:class="classes"
							/>
						</b-input-group>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';
import { fetchCountries, fetchGenders } from '@/view/project/api/BaseData';

export default {
	name: 'TabStammdaten',
	components: { AqFormGroup },
	data() {
		return {
			genders: [],
			countries: [],
		};
	},
	mounted() {
		fetchCountries().then(response => {
			this.countries = response.data.data[1].map(object => ({
				value: object.code,
				text: object.de,
			}));
		});
		this.genders = fetchGenders();
	},
	props: {
		data: Object,
	},
};
</script>
