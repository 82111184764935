<template>
	<div>
		<table class="table table-head-custom table-vertical-center">
			<thead>
				<tr>
					<th>Datum</th>
					<th>Typ</th>
					<th>Schaden</th>
					<th>Aktionen</th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="this.arrItems.length == 0">
					<td colspan="4">
						<b-alert variant="success" show>
							Keine Einträge vorhanden
						</b-alert>
					</td>
				</tr>
				<tr v-for="row in this.arrItems" v-bind:key="row.$index">
					<td>{{ row.date }}</td>
					<td>{{ row.type }}</td>
					<td v-if="row.type === 'Schaden am Fahrzeug'">
						{{ row.damage }}
						<em
							class="fad fa-info-circle"
							v-b-popover.hover="getPopoverContent(row)"
						></em>
					</td>
					<td v-else>
						{{ row.damage }}
					</td>
					<td>
						<b-button variant="primary" size="sm"
							><em class="fad fa-undo"></em>
							Zurücksetzen</b-button
						>
						<b-button class="mx-1" variant="danger" size="sm"
							><em class="fad fa-trash"></em> Löschen</b-button
						>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { fetchVehicleProtocols } from '../../../api/VehicleData';

export default {
	name: 'ReparaturTaskComponent',
	components: {},
	props: {
		data: Object,
		vehicleData: Object,
	},
	data() {
		return {
			arrItems: [],
		};
	},
	methods: {
		getPopoverContent(item) {
			return {
				html: true,
				title: 'Schaden am Fahrzeug',
				content: () => {
					return (
						'<div class="damage-reparatur-vehicle-overlay" style="top: ' +
						this.getDamagePositionX(item) +
						'%; left: ' +
						this.getDamagePositionY(item) +
						'%;"></div>' +
						'<img class="damage-reparatur-vehicle-img" width="184" src="' +
						process.env.BASE_URL +
						'assets/img/vehicle_protocoll.png' +
						'" />'
					);
				},
			};
		},
    getDamagePositionX(item) {
      return item.obj.y;
    },
    getDamagePositionY(item) {
      return item.obj.x;
    },
		loadData() {
			fetchVehicleProtocols(this.vehicleData.id).then(response => {
				if (response.data && response.data.data[1]) {
					response.data.data[1].forEach(item => {
						// hinzufügen von Fahrzeugschäden
						if (item.damage && item.damage.length > 0) {
							item.damage.forEach(damage => {
								if (damage.active == 1) {
									this.arrItems.push({
										type: 'Schaden am Fahrzeug',
										protocol_id: item.id,
										damage: damage.comment,
										date: item.date,
										obj: damage,
									});
								}
							});
						}

						// hinzufügen von fehlenden ausstattungen
						if (item.equipment && item.equipment.length > 0) {
							item.equipment.forEach(equipment => {
								if (
									equipment.note === 'nicht i.O.' ||
									equipment.note === 'Nein'
								) {
									this.arrItems.push({
										type: 'Ausstattung',
										protocol_id: item.id,
										damage:
											equipment.equipment[0].title +
											': ' +
											equipment.note,
										date: item.date,
										obj: item.equipment,
									});
								}
							});
						}
					});
				}
			});
		},
	},
	mounted() {
		this.loadData();
	},
};
</script>

<style>
.damage-reparatur-vehicle-overlay {
	position: relative;
	z-index: 1070;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: grey;
}
.damage-reparatur-vehicle-img {
  margin-top: -20px;
}
</style>
