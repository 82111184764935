<template>
	<div>
		<b-row class="py-5">
			<h4>Grunddaten</h4>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Datum"
					rules="required"
				>
					<AqFormGroup
						label="Datum"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-date"
							v-model="data.date"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Ausgeber/Prüfer"
					rules="required"
				>
					<AqFormGroup
						label="Ausgeber/Prüfer"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-aqe_people_id_controller"
							v-model="data.aqe_people_id_controller"
							:class="classes"
						>
							<b-form-select-option
								v-for="employee in formData.employees"
								v-bind:key="employee.id"
								:value="employee.value"
								>{{ employee.text }}</b-form-select-option
							>
						</b-form-select>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Mitarbeiter (Empfänger)"
					rules="required"
				>
					<AqFormGroup
						label="Mitarbeiter (Empfänger)"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-aqe_people_id_acceptor"
							v-model="data.aqe_people_id_acceptor"
							:class="classes"
						>
							<b-form-select-option
								v-for="employee in formData.employees"
								v-bind:key="employee.id"
								:value="employee.value"
								>{{ employee.text }}</b-form-select-option
							>
						</b-form-select>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';

import { fetchSupervisors } from '@/view/project/api/BaseData';

export default {
	name: 'GrunddatenComponent',
	components: { AqFormGroup },
	props: {
		data: Object,
	},
	data() {
		return {
			formData: {
				employees: [],
			},
		};
	},
	methods: {
		loadData() {
			fetchSupervisors().then(response => {
				this.formData.employees = response.data.data[1].map(object => ({
					value: object.id,
					text: object.fullname,
				}));
			});
		},
	},
	mounted() {
		this.loadData();
	},
};
</script>

<style scoped></style>
