/**
 *Creates a column for vuetify Tables
 * @author E.Neuwirth
 */

class AqColumn {
	constructor() {
		this._objController = null;
		this.crud_field = false;
		this.connection = '';

		this.column = this.buildBackend();
		//vuetify defaults
		this.column.sortable = true;
	}

	buildBackend() {
		return {
			data: false,
			name: false,
			searchable: false,
			orderable: true,
			search: {
				value: '',
				regex: false,
			},
		};
	}

	/* setters ----------------------------------------------- start*/

	withController(objController) {
		this._objController = objController;
		return this;
	}

	/* setters ----------------------------------------------- end*/

	/* helpers ----------------------------------------------- end*/

	Translate(pattern) {
		if (this._objController !== null) {
			return this._objController.Translate().tc(pattern);
		} else {
			return 'no controller set';
		}
	}

	Translation(pattern) {
		if (this._objController !== null) {
			return this._objController.Translation(pattern);
		} else {
			return 'no controller set';
		}
	}

	/* helpers ----------------------------------------------- end*/

	/* fields ----------------------------------------------- start*/

	crud(connection) {
		this.crud_field = true;
		this.connection = connection;
		return this;
	}

	field(field) {
		//backend needs data and name and vuetify needs value
		this.column.data = this.connection + field;
		//if a connection has been given let's replace . with : so the bakcend value is set correctly
		if (this.crud_field) {
			this.column.data = this.column.data.replace(/\./g, ':');
		}
		this.column.name = this.connection + field;
		this.column.value = this.connection + field;

		return this;
	}

	align(value) {
		this.column.align = value;
		return this;
	}

	text(text) {
		this.column.text = text;
		return this;
	}

	textTranslate(pattern) {
		this.column.text = this.Translate(pattern);
		return this;
	}
	textTranslation(pattern) {
		this.column.text = this.Translation(pattern);
		return this;
	}

	withSearch() {
		this.column.searchable = true;
		return this;
	}

	noOrder() {
		//backend needs orderable
		this.column.orderable = false;
		//vuetify needs sortable
		this.column.sortable = false;
		return this;
	}

	/* fields ----------------------------------------------- end*/

	/* default columns ----------------------------------------------- start*/

	columnID() {
		return this.field('id')
			.align('start')
			.textTranslate('general.id');
	}

	columnActive() {
		return this.field('active').textTranslate('fields.active');
	}

	columnTitle() {
		return this.field('title').textTranslate('general.title');
	}

	columnUsername() {
		return this.field('username').textTranslate('fields.username');
	}

	columnFirstName() {
		return this.field('firstname').textTranslate('fields.firstname');
	}

	columnLastName() {
		return this.field('lastname').textTranslate('fields.lastname');
	}

	columnActions() {
		return this.field('actions')
			.align('right')
			.noOrder()
			.textTranslate('general.actions')
			.build();
	}

	columnSmallDescription()
	{
		return this.field('small_description').textTranslate('fields.smallDescription');
	}

	columnVehicleNumber()
	{
		return this.field('vehicle_nr').textTranslate('fields.vehicle_nr');
	}

	columnVin()
	{
		return this.field('vin').textTranslate('fields.vin');
	}

	columnBrand()
	{
		return this.field('marke').textTranslate('fields.brand');
	}

	columnModel()
	{
		return this.field('modell').textTranslate('fields.model');
	}

	/* default columns ----------------------------------------------- end*/

	/* fields ----------------------------------------------- end*/

	build() {
		return this.column;
	}
}
export default AqColumn;
