<template>
	<div>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Fahrzeugnummer"
					rules="required"
				>
					<AqFormGroup
						label="Fahrzeugnummer"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-vehicle-nr"
							v-model="vehicle.vehicle_nr"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Kennzeichen"
					rules="required"
				>
					<AqFormGroup
						label="Kennzeichen"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-number_plate"
							v-model="vehicle.number_plate"
							:class="classes"
              maxlength="30"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Kurzbezeichnung"
					rules="required"
				>
					<AqFormGroup
						label="Kurzbezeichnung"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-small-description"
							v-model="vehicle.small_description"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Art"
					rules="required"
				>
					<AqFormGroup
						label="Art"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-vehicle-type"
							:options="formData.arrVehicleTypes"
							v-model="vehicle.vehicle_type"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Marke"
					rules="required"
				>
					<AqFormGroup
						label="Marke"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-marke"
							v-model="vehicle.marke"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Modell"
					rules="required"
				>
					<AqFormGroup
						label="Modell"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-modell"
							v-model="vehicle.modell"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Fahrgestellnummer"
					rules="required"
				>
					<AqFormGroup
						label="Fahrgestellnummer"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-vin"
							v-model="vehicle.vin"
							:class="classes"
							maxlength="17"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Baujahr"
					rules="required"
				>
					<AqFormGroup
						label="Baujahr"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-manufacture-year"
							v-model="vehicle.manufacture_year"
							:class="classes"
							maxlength="4"
							max="9999"
							min="1900"
							type="number"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Besitzart"
					rules="required"
				>
					<AqFormGroup
						label="Besitzart"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-ownership-type"
							:options="formData.arrOwnershipTypes"
							v-model="vehicle.ownership_type"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Anhängerkupplung"
					rules="required"
				>
					<AqFormGroup
						label="Anhängerkupplung"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-trailer-hitch"
							:options="formData.arrYesNo"
							v-model="vehicle.trailer_hitch"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Nächstes Service"
					rules="required"
				>
					<AqFormGroup
						label="Nächstes Service"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-next-service"
							v-model="vehicle.next_service"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Nächste §57a"
					rules="required"
				>
					<AqFormGroup
						label="Nächste §57a"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-next-p57avalidation"
							v-model="vehicle.next_p57avalidation"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Versicherung"
					rules="required"
				>
					<AqFormGroup
						label="Versicherung"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-insurance"
							v-model="vehicle.insurance"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Polizze"
					rules="required"
				>
					<AqFormGroup
						label="Polizze"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-insurance-policy"
							v-model="vehicle.insurance_policy"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Versicherungsgesellschaft"
					rules="required"
				>
					<AqFormGroup
						label="Versicherungsgesellschaft"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-insurance-company"
							v-model="vehicle.insurance_company"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Fahrer"
					rules="required"
				>
					<AqFormGroup
						label="Fahrer"
						:boolRequired="true"
						:state="state"
					>
						<b-form-select
							id="input-driver-id"
							v-model="vehicle.driver_id"
							:class="classes"
						>
							<b-form-select-option
								v-for="employee in formData.arrDrivers"
								v-bind:key="employee.id"
								:value="employee.value"
								>{{ employee.text }}</b-form-select-option
							>
						</b-form-select>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Verwendungszweck"
					rules="required"
				>
					<AqFormGroup
						label="Verwendungszweck"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-purpose"
							v-model="vehicle.purpose"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="HD-Aggregat"
					rules="required"
				>
					<AqFormGroup
						label="HD-Aggregat"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-hp-unit"
							v-model="vehicle.hp_unit"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" lg="4" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="TV-Technik"
					rules="required"
				>
					<AqFormGroup
						label="TV-Technik"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-tv-technic"
							v-model="vehicle.tv_technic"
							:class="classes"
							maxlength="100"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';
import { fetchSupervisors } from '../../../api/BaseData';

export default {
	name: 'FahrzeugdatenComponent',
	components: { AqFormGroup },
	props: {
		vehicle: Object,
	},
	data() {
		return {
			formData: {
				arrDrivers: [],
				arrVehicleTypes: ['PKW', 'LKW', 'Anhänger'],
				arrYesNo: ['Ja', 'Nein'],
				arrOwnershipTypes: ['Kauf', 'Leasing', 'Miete'],
			},
		};
	},
	methods: {
		loadData() {
			fetchSupervisors().then(response => {
				this.formData.arrDrivers = response.data.data[1].map(
					object => ({
						value: object.id,
						text: object.fullname,
					}),
				);
			});
		},
	},
	mounted() {
		this.loadData();
	},
};
</script>

<style scoped></style>
