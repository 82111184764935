var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom bg-gray-100 card-stretch"},[(_vm.forList.title)?_c('div',{staticClass:"card-header border-0"},[_c('h3',[_vm._v(_vm._s(_vm.forList.title))])]):_vm._e(),_c('div',{staticClass:"card-body pt-2"},[_c('b-row',[_c('div',{staticClass:"col"},[(_vm.forList.rights.add)?_c('v-btn',{attrs:{"depressed":"","color":"success"},on:{"click":_vm.forList.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" far fa-plus-square ")]),_vm._v(" "+_vm._s(_vm.forList.titleAdd)+" ")],1):_vm._e()],1),(_vm.forList.withQickSearch)?_c('div',{staticClass:"col-auto"},[_c('v-text-field',{attrs:{"filled":"","placeholder":_vm.$t('general.search'),"loading":_vm.forList.isLoading,"disabled":_vm.forList.isLoading,"prepend-inner-icon":"far fa-search","clearable":"","dense":"","filled":""},on:{"click:clear":_vm.forList.clearSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.forList.onSearch($event)}},model:{value:(_vm.forList.quickSearch),callback:function ($$v) {_vm.$set(_vm.forList, "quickSearch", $$v)},expression:"forList.quickSearch"}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1 table-striped",attrs:{"headers":_vm.forTable.headers,"items":_vm.forTable.items,"items-per-page":_vm.forTable.itemsPerPage,"options":_vm.forTable.options,"server-items-length":_vm.forTable.totalItems,"loading":_vm.forList.isLoading,"footer-props":{
				showFirstLastPage: true,
				itemsPerPageOptions : [
					20,
					50,
					100
				],
				firstIcon: 'far fa-arrow-to-left',
				lastIcon: 'far fa-arrow-to-right',
				prevIcon: 'fas fa-arrow-alt-left',
				nextIcon: 'fas fa-arrow-alt-right'
			},"no-data-text":_vm.$t('list.noData'),"loading-text":_vm.$t('list.isLoading')},on:{"update:options":function($event){return _vm.$set(_vm.forTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
			var item = ref.item;
return [(item.active == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("far fa-ban")])]}},{key:"item.actions",fn:function(ref){
			var item = ref.item;
return [(item.rights.edit)?_c('v-btn',{attrs:{"icon":"","color":"primary","title":_vm.forList.titleEdit},on:{"click":function($event){return _vm.forTable.onEdit(item)}}},[_c('v-icon',[_vm._v("far fa-pencil")])],1):_vm._e(),_vm._t("item.customactions",null,{"item":item}),(item.rights.delete)?_c('v-btn',{attrs:{"icon":"","color":"danger","title":_vm.forList.titleDelete},on:{"click":function($event){return _vm.forTable.onDelete(item)}}},[_c('v-icon',[_vm._v("far fa-trash")])],1):_vm._e()]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1),(_vm.forDelete.open)?_c('delete-modal',{attrs:{"forDelete":_vm.forDelete}},[_vm._t("deleteLayout")],2):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.forList.isLoading,"opacity":"0.2","absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }