import {
	configure,
	ValidationProvider,
	ValidationObserver,
	extend,
	localize,
} from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/de.json';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
	extend(rule, {
		...rules[rule], // copies rule configuration
		message: messages[rule], // assign message
	});
});

// Required Highlighting ..
configure({
	classes: {
		invalid: 'is-invalid',
	},
});

export { extend, localize, ValidationProvider, ValidationObserver };
