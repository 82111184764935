<template>
	<b-form-group
		:id="formgroup_id"
		:label-size="labelsize"
		:label-cols-sm="labelcolssm"
		:label-cols-lg="labelcolslg"
		:label="fullLabel"
		:state="state"
		:label-for="input_id"
		:label-class="labelclass"
		:class="cssclass"
	>
		<slot></slot>
	</b-form-group>
</template>

<script>
export default {
	name: 'AqFormGroup',
	components: {},
	props: {
		label: String,
		state: {
			type: Object,
			default: null,
		},
		boolRequired: {
			type: Boolean,
			default: false,
		},
		labelclass: {
			type: String,
			default: 'my-auto',
		},
		labelsize: {
			type: String,
			default: 'sm',
		},
		labelcolssm: {
			type: String,
			default: '3',
		},
		labelcolslg: {
			type: String,
			default: 'lg',
		},
		cssclass: {
			type: String,
			default: 'my-0',
		},
	},
	data: function() {
		return {
			formgroup_id: 'fieldset-' + this.label,
			input_id: 'input-' + this.label,
		};
	},
	computed: {
		fullLabel: function() {
			return this.boolRequired ? this.label + ': *' : this.label + ': ';
		},
	},
};
</script>
