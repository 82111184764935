<template>
	<tr>
		<td>
			<span>{{ objActivity.count }}</span>
		</td>
		<td>
			<ValidationProvider
				v-slot="{ errors, state, classes }"
				name="Beschreibung"
				rules="required"
			>
				<b-form-group
					id="fieldset-performancereviewactivitydescription"
					:state="state"
					class="my-0"
				>
					<b-form-textarea
						id="input-performancereviewactivitydescription"
						rows="3"
						v-model="objActivity.description"
						:classes="classes"
					/>
					<span class="text-danger" v-show="errors[0]">{{
						errors[0]
					}}</span>
				</b-form-group>
			</ValidationProvider>
		</td>
		<td>Responsible Person</td>
		<td>
			<ValidationProvider
				v-slot="{ errors, state, classes }"
				name="Bis wann?"
				rules=""
			>
				<b-form-group
					id="fieldset-performancereviewactivityreminderdate"
					:state="state"
					label-for="input-performancereviewactivityreminderdate"
					class="my-0"
				>
					<b-form-datepicker
						id="example-performancereviewactivityreminderdate"
						v-model="objActivity.reminder_date"
						:class="classes"
					></b-form-datepicker>
					<span class="text-danger" v-show="errors[0]">{{
						errors[0]
					}}</span>
				</b-form-group>
			</ValidationProvider>
		</td>
	</tr>
</template>

<script>
export default {
	name: 'PerformancereviewActivity',
	components: {},
	data() {
		return {};
	},
	props: {
		objActivity: Object,
	},
	created() {},
};
</script>
