<template>
  <div class="topbar-item" v-if="currentUser && currentUser.people">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <span
        class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4"
      >
        {{ currentUser.people.firstname || '' }}
      </span>
      <span class="symbol symbol-35">
        <span
          class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"
        >
          {{ currentUser.people.firstname | limitTo(1) }}
          {{ currentUser.people.lastname | limitTo(1) }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">Benutzerprofil</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <em class="fas fa-window-close"></em>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              class="symbol-label"
              src="http://placekitten.com/100/100"
              alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUser.people.firstname }}
              {{ currentUser.people.lastname }}
            </a>
          </div>
        </div>
        <div class="d-flex align-items-center mt-5 text-right">
          <div class="d-flex flex-column pull-right">
            <button
              class="btn btn-light-primary btn-bold pull-right"
              @click="onLogout"
            >
              Abmelden
            </button>
          </div>
        </div>
        <!--end::Header-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from '@/core/services/store/auth.module';
import { mapGetters } from 'vuex';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';

export default {
  name: 'KTQuickUser',
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }));
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + 'media/users/300_21.jpg';
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    ...mapGetters(['currentUser']),
  },
};
</script>
