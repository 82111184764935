<template>
	<view-form :forForm="forForm">
		<template v-slot:formLayout>
			<ValidationObserver ref="observerStammdaten">
				<h2>Stammdaten</h2>

				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.firstname"
							:forInput="FormInputs.firstname"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.lastname"
							:forInput="FormInputs.lastname"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.dropdown"
							:forInput="FormInputs.autocomplete"
						></input-builder>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.email"
							:forInput="FormInputs.email"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.select"
							:forInput="FormInputs.select"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.userrightsvalue"
							:forInput="FormInputs.autocompleteMultiple"
						></input-builder>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-6">
						<input-builder
							:value.sync="data.checkboxes"
							:forInput="FormInputs.checkboxes"
						></input-builder>
					</div>
					<div class="col-md-6">
						<input-builder
							:value.sync="data.radio"
							:forInput="FormInputs.radio"
						></input-builder>
						<input-builder
							:value.sync="data.radiobtn"
							:forInput="FormInputs.radiobtn"
						></input-builder>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.file"
							:forInput="FormInputs.file"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.files"
							:forInput="FormInputs.files"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.time"
							:forInput="FormInputs.time"
						></input-builder>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.textarea"
							:forInput="FormInputs.textarea"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.color"
							:forInput="FormInputs.color"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.checkbox"
							:forInput="FormInputs.checkbox"
						></input-builder>
						<input-builder
							:value.sync="data.active"
							:forInput="FormInputs.active"
						></input-builder>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.date"
							:forInput="FormInputs.date"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.dates"
							:forInput="FormInputs.dates"
						></input-builder>
					</div>
					<div class="col-md-4">
						<input-builder
							:value.sync="data.daterange"
							:forInput="FormInputs.daterange"
						></input-builder>
					</div>
				</div>
			</ValidationObserver>
		</template>
	</view-form>
</template>
<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Roles();
const ViewForm = Controller.newForm();

let demoOptions = [
	{
		value: 1,
		text: 'Yellow',
		icon: 'fa-fw fa-user',
		description: 'Ich bin ein Text',
	},
	{ value: 2, text: 'Green', icon: 'fa-fw fa-car' },
	{ value: 3, text: 'Blue', icon: 'fa-fw fa-envelope' },
	{ value: 4, text: 'Red', icon: 'fa-fw fa-truck' },
	{ value: 5, text: 'Black', icon: 'fa-fw fa-brain' },
];

const FormInputs = {
	firstname: ViewForm.Input()
		.required()
		.translateLabel('fields.firstname')
		.build(),
	lastname: ViewForm.Input()
		.isDisabled()
		.translateLabel('fields.lastname')
		.build(),
	active: ViewForm.Input()
		.asSwitch()
		.translateLabel('fields.active')
		.build(),

	checkbox: ViewForm.Input()
		.asCheckbox()
		.label('ich bin eine checkbox')
		.build(),
	date: ViewForm.Input()
		.asDate()
		.required()
		.translateLabel('fields.date')
		.build(),
	dates: ViewForm.Input()
		.asDates()
		.required()
		.label('Dates')
		.build(),
	daterange: ViewForm.Input()
		.asDateRange()
		.required()
		.label('Date Range')
		.build(),
	color: ViewForm.Input()
		.asColor()
		.label('Farbe')
		.build(),
	textarea: ViewForm.Input()
		.asTextarea()
		.label('Textarea')
		.icon('fa-fw fa-pencil')
		.build(),
	file: ViewForm.Input()
		.asFile()
		.label('Datei')
		.build(),
	files: ViewForm.Input()
		.asFiles()
		.label('Dateien')
		.build(),
	time: ViewForm.Input()
		.asTime()
		.label('Zeit')
		.build(),
	email: ViewForm.Input()
		.asEmail()
		.build(),
	select: ViewForm.Input()
		.asSelect()
		.label('Land')
		.icon('fa fa-fw fa-map')
		.hint('Bitte wählen Sie ein Land')
		.items(demoOptions)
		.build(),
	autocomplete: ViewForm.Input()
		.asAutocomplete()
		.label('Autocomplete')
		.required()
		.icon('fa fa-fw fa-car')
		.items(demoOptions)
		.build(),
	autocompleteMultiple: ViewForm.Input()
		.asAutocomplete()
		.multiple()
		.withObject()
		.label('Multiple Autocomplete')
		.required()
		.icon('fa fa-fw fa-truck')
		.items(demoOptions)
		.build(),
	checkboxes: ViewForm.Input()
		.asCheckboxes()
		.label('Checkboxes')
		.color('success')
		.items(demoOptions)
		.build(),
	radio: ViewForm.Input()
		.asRadio()
		.label('Radio')
		.items(demoOptions)
		.build(),
	radiobtn: ViewForm.Input()
		.asRadioBtn()
		.label('Radio2')
		.items(demoOptions)
		.build(),
};

export default {
	name: 'Roles-Demo',
	components: ViewForm.initComponents(),
	data() {
		ViewForm.validateRoute(this);

		ViewForm.setInputs(FormInputs)
			.forApi(function(apiParams) {
				return apiParams.connections([
					'salary',
					'privatedata',
					'businessdata',
					'branchoffice',
					'skillswerte',
					'employmentdata:emplyoment',
					'employmentdata:companyscope',
					'annotations:annotationtype',
					'annotations:annotationtypecategory',
					'annotations:lasteditor',
					'annotations:performancereview',
					'annotations:performancereview:goal',
					'annotations:performancereview:goal:reminder',
					'annotations:performancereview:activity',
					'annotations:performancereview:activity:reminder',
					'annotations:reminder',
					'mitarbeiterfiles:category',
					'mitarbeiterfoto',
				]);
			})
			.forItem({
				firstname: 'firstname',
				email: '',
				gender: 'm',
				select: 'b',
				dropdown: 3,
			});

		return ViewForm.initData();
	},
	mounted: function() {
		ViewForm.initMounted();
	},
	computed: ViewForm.initComputed(),
};
</script>
