<template>
	<div>
		<GrunddatenComponent :data="this.data" />

		<ReifenComponent :data="this.data" />

		<AusstattungComponent :data="this.data" />

		<b-row class="border-top">
			<b-col cols="12">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Sonstige Bemerkungen"
					rules=""
				>
					<b-form-group
						id="fieldset-bemerkungen"
						label-size="sm"
						label-cols-sm="4"
						label-cols-lg="3"
						label="Sonstige Bemerkungen: "
						:state="state"
						label-for="input-description"
						label-class="my-auto"
						class="my-0"
					>
						<b-form-textarea
							id="input-description"
							rows="3"
							v-model="data.comment"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</b-form-group>
				</ValidationProvider>
			</b-col>
		</b-row>

		<UnterschriftComponent
			:signature-fields="this.signatureFields"
			class="border-top"
		/>
	</div>
</template>

<script>
import UnterschriftComponent from './UnterschriftComponent';
import GrunddatenComponent from './GrunddatenComponent';
import AusstattungComponent from './AusstattungComponent';
import ReifenComponent from './ReifenComponent';

export default {
	name: 'FahrzeuguebergabeTaskComponent',
	components: {
		ReifenComponent,
		AusstattungComponent,
		GrunddatenComponent,
		UnterschriftComponent,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			signatureFields: [
				{
					name: 'signature_acceptor',
					description: 'Unterschrift Fahrzeugnutzer',
					cols: 6,
					disabled: false,
					signatureImageData: null,
				},
				{
					name: 'signature_controller',
					description: 'Unterschrift Prüfer',
					cols: 6,
					disabled: false,
					signatureImageData: null,
				},
			],
		};
	},
};
</script>

<style scoped></style>
