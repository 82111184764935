/**
 *A Controller Class for Benutzer
 */
import Vue from 'vue';

class Mitarbeiter extends Vue.Aq.Classes.Controller {
	apiUrl() {
		return 'resources/aqe/people/';
	}

	routeNavKey() {
		return 'modules';
	}

	entryKey() {
		return 'slug';
	}

	inputEntries(from) {
		var apiOptions = this.ApiParams();

		switch (from) {
			case 'benutzer-form':
				apiOptions.connection('businessdata');
				break;

			default:
				break;
		}

		let promiseEntries = this.getEntries(apiOptions.build());

		return this.Input()
			.asAutocomplete()
			.withObject()
			.label(this.Translation('title'))
			.icon('fa-fw fa-user')
			.itemText('fullname')
			.withPromise(promiseEntries);
	}
}
export default Mitarbeiter;
