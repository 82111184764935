<template>
	<div>
		<b-row class="border-top my-3 pt-5">
			<h4>Ausstattung</h4>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0 border-right">
				<AusstattungPartialComponent
					:options="arrEquipmentLeft"
					:data="data"
				/>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<AusstattungPartialComponent
					:options="arrEquipmentRight"
					:data="data"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AusstattungPartialComponent from './AusstattungPartialComponent.vue';
import { fetchVehicleEquipment } from '../../../api/VehicleData';

export default {
	name: 'AusstattungComponent',
	components: { AusstattungPartialComponent },
	props: {
		data: Object,
	},
	data() {
		return {
			arrEquipmentLeft: [],
			arrEquipmentRight: [],
		};
	},
	methods: {
		loadData() {
			fetchVehicleEquipment().then(response => {
				let resp = response.data.data[1];
				let half = Math.ceil(resp.length / 2);

				this.arrEquipmentLeft = resp.slice(0, half);
				this.arrEquipmentRight = resp.slice(-half);

				this.data.equipment = [];
			});
		},
	},
	mounted() {
		this.loadData();
	},
};
</script>

<style scoped></style>
