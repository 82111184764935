var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.objActivity.count))])]),_c('td',[_c('ValidationProvider',{attrs:{"name":"Beschreibung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('b-form-group',{staticClass:"my-0",attrs:{"id":"fieldset-performancereviewactivitydescription","state":state}},[_c('b-form-textarea',{attrs:{"id":"input-performancereviewactivitydescription","rows":"3","classes":classes},model:{value:(_vm.objActivity.description),callback:function ($$v) {_vm.$set(_vm.objActivity, "description", $$v)},expression:"objActivity.description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('td',[_vm._v("Responsible Person")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"Bis wann?","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('b-form-group',{staticClass:"my-0",attrs:{"id":"fieldset-performancereviewactivityreminderdate","state":state,"label-for":"input-performancereviewactivityreminderdate"}},[_c('b-form-datepicker',{class:classes,attrs:{"id":"example-performancereviewactivityreminderdate"},model:{value:(_vm.objActivity.reminder_date),callback:function ($$v) {_vm.$set(_vm.objActivity, "reminder_date", $$v)},expression:"objActivity.reminder_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }