import dayjs from 'dayjs';
import de from 'dayjs/locale/de-at';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.locale(de);

let dateFormatFromDatabaseToView = strDate => {
	return dayjs(strDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
};

let dateFormatFromViewToDatabase = strDate => {
	return dayjs(strDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
};

let dateGetTimestamp = () => {
	return dayjs().format('YYYY-MM-DD HH:mm:ss');
};

let dateGetDate = () => {
	return dayjs().format('YYYY-MM-DD');
};

export {
	dayjs,
	dateFormatFromDatabaseToView,
	dateFormatFromViewToDatabase,
	dateGetDate,
	dateGetTimestamp,
};
