<template>
	<input-builder v-model="_value" :forInput="forInput">
		<template v-slot:selection="data">
			<span v-if="data.item">
				#{{ data.item.id }} {{ data.item.fullname }}
				<span
					class="text-muted"
					v-if="
						data.item.businessdata && data.item.businessdata.email
					"
					><i class="fad fa-fw fa-envelope"></i>
					{{ data.item.businessdata.email }}</span
				>
			</span>
		</template>
		<template v-slot:item="data">
			<v-list-item-content>
				<v-list-item-title>
					#{{ data.item.id }} {{ data.item.fullname }}
				</v-list-item-title>
				<v-list-item-subtitle>
					<span
						class="text-muted"
						v-if="
							data.item.businessdata &&
								data.item.businessdata.email
						"
						><i class="fad fa-fw fa-envelope"></i>
						{{ data.item.businessdata.email }}</span
					>
				</v-list-item-subtitle>
			</v-list-item-content>
		</template>
	</input-builder>
</template>
<script>
import Vue from 'vue';
let AqInputBuilder = Vue.Aq.Loader.layout()
	.input('BuilderVuetify')
	.get();

export default {
	props: {
		forInput: {
			type: Object,
		},
		value: {},
	},
	components: {
		'input-builder': AqInputBuilder,
	},
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('update:value', value);
			},
		},
	},
};
</script>
