import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import VueAxios from 'vue-axios';
import AqToast from '@/view/project/components/AqToast';
import router from '@/router';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init() {
		Vue.use(VueAxios, axios);

		Vue.axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
		Vue.axios.defaults.withCredentials = true;

		//'Access-Control-Allow-Origin': '*',
		//'Content-Type': 'application/json',
		Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
		Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = [
			'http://localhost',
			'https://portal.aqendo.at',
		];

		Vue.axios.defaults.headers.common['Allow-Credentials'] = true;

		Vue.axios.interceptors.response.use(
			function(response) {
				if (typeof response.data !== 'object') {
					ApiService.aqendoErrorHandler(response);
					return Promise.reject(response.data);
				}
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					if (router.currentRoute.path !== '/login') {
						router.push('/login');
					}
					return Promise.resolve(error.response);
				} else {
					return Promise.reject(error);
				}
			},
		);
	},

	query(resource, params) {
		return Vue.axios.get(resource, params).catch(error => {
			throw new Error(`[KT] ApiService ${error}`);
		});
	},

	get(resource, params = {}) {
		let _defaultParams = {
			withCredentials: true,
		};
		return Vue.axios.get(`${resource}`, {
			...{ params: params },
			..._defaultParams,
		});
	},

	getSerialzied(resource, params = {}) {
		let _defaultParams = {
			withCredentials: true,
			paramsSerializer: params => {
				let serialized = qs.stringify(params);
				return serialized;
			},
		};
		return Vue.axios.get(`${resource}`, {
			...{ params: params },
			..._defaultParams,
		});
	},

	deleteSerialzied(resource, params = {}) {
		let _defaultParams = {
			withCredentials: true,
			paramsSerializer: params => {
				let serialized = qs.stringify(params);
				return serialized;
			},
		};
		return Vue.axios.delete(`${resource}`, {
			...{ params: params },
			..._defaultParams,
		});
	},

	post(resource, params, config = []) {
		return Vue.axios.post(`${resource}`, params, config);
	},

	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params);
	},

	put(resource, params) {
		return Vue.axios.put(`${resource}`, params);
	},

	delete(resource, params) {
		let deleteParams = {
			data: params,
			header: {
				'Content-Type': 'application/json',
			},
		};
		return Vue.axios.delete(resource, deleteParams).catch(error => {
			// console.log(error);
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},

	uploadFile(event, data) {
		let formData = new FormData();
		//TODO: Error Handling..
		let _strFilename = '';

		if (event.type === 'drop') {
			_strFilename = event.dataTransfer.files[0].name;
			formData.append('file', event.dataTransfer.files[0]);
		} else {
			_strFilename = event.target.files[0].name;
			formData.append('file', event.target.files[0]);
		}
		formData.append(
			'category_id',
			Vue.prototype.$Constants.DOCFILE_CATEGORY_EMPLOYEE_DOCUMENT,
		);
		formData.append(
			'title',
			_strFilename
				.split('.')
				.slice(0, -1)
				.join('.'),
		);
		formData.append('comment', '');
		formData.append('peoplefile[people_id]', data.id);

		return Vue.axios
			.post('resources/doc/file/item', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.catch(error => {
				throw new Error(`ApiService ${error}`);
			});
	},
	downloadFile(fileId, peopleId) {
		window.open( process.env.VUE_APP_SERVER_PATH + '/File/downloadFile?id=' + fileId + '&people_id=' + peopleId, '_blank');
	},
	httpErrorHandler(error) {
		console.log(error);

		// if has response show the error
		if (error.response) {
			new AqToast().callToast(error.response.data.message);
			throw new Error(`ApiService ${error.response.data.message}`);
		}
	},

	aqendoErrorHandler(error) {
		new AqToast().callToast(
			`Error Communicating with Backend!  ${error.config.url} [${error.status}]`,
		);
		throw new Error(`ApiService ${error.data}`);
	},
};

export default ApiService;
