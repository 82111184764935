<template>
	<div class="card card-custom bg-white-100 card-stretch gutter-b">
		<b-card-group deck v-if="this.data">
			<b-card
				style="border-bottom: 0px;"
				img-src="https://placekitten.com/200/200"
				img-alt="Image"
				img-top
			>
				<div
					role="tablist"
					class="navi navi-bold navi-hover navi-active navi-link-rounded"
				>
					<div
						v-for="tab in this.tabs"
						:key="tab.description"
						class="navi-item mb-2"
					>
						<a
							data-tab="0"
							data-toggle="tab"
							role="tab"
							aria-selected="false"
							class="navi-link py-4"
							v-bind:class="{ active: tab.active }"
							style="cursor: pointer;"
							v-on:click="clickTab(tab.id)"
						>
							<span class="navi-icon mr-2">
								<em :class="tab.icon"></em>
							</span>
							<span class="navi-text font-size-lg">{{
								tab.description
							}}</span>
						</a>
					</div>
				</div>
			</b-card>
		</b-card-group>
	</div>
</template>

<script>
export default {
	name: 'MenuAside',
	components: {},
	data() {
		return {
			tabs: [
				{
					id: 'tabStammdaten',
					description: 'Stammdaten',
					icon: 'fa-lg fad fa-database',
					active: true,
          showForNewEmployee: true
				},
				{
					id: 'tabPersonalinformationen',
					description: 'Personalinformation',
					icon: 'fa-lg fad fa-user',
					active: false,
          showForNewEmployee: true
				},
				{
					id: 'tabVerguetung',
					description: 'Vergütung',
					icon: 'fa-lg fad fa-money-bill-alt',
					active: false,
          showForNewEmployee: true
				},
				{
					id: 'tabGehaltsentwicklung',
					description: 'Gehaltsentwicklung',
					icon: 'fa-lg fad fa-chart-line',
					active: false,
          showForNewEmployee: false
				},
				{
					id: 'tabDokumente',
					description: 'Dokumente',
					icon: 'fa-lg fad fa-file-search',
					active: false,
          showForNewEmployee: false
				},
				{
					id: 'tabPersonalakt',
					description: 'Personalakt',
					icon: 'fa-lg fad fa-comments',
					active: false,
          showForNewEmployee: false
				},
			].filter(tab => {
          if (!this.boolIsEditEmployee) {
            return tab.showForNewEmployee === true
          }
          return tab;
      })
		};
	},
	props: {
		data: Object,
		boolIsEditEmployee: Boolean,
	},
	computed: {},
	methods: {
		clickTab(tabId) {
			this.tabs.forEach(function(tab) {
				tab.active = tab.id === tabId;
			});
			this.$emit('changeEmployeeTab', tabId);
		},
	},
};
</script>
