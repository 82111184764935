import Vue from 'vue';
import Router from 'vue-router';
import store from '@/core/services/store';
import AqPaths from '@/core/project/AqPaths';
import { VERIFY_AUTH } from '@/core/services/store/auth.module';

Vue.use(Router);
let AqPath = new AqPaths();

function loadView(relativePart) {
	return () => import('@/view/' + relativePart);
}

let _objRouter = new Router({
	mode: 'hash',
	routes: [
		{
			name: 'login',
			path: '/login',
			component: loadView(AqPath.project('auth/Login').get()),
		},
		{
			name: 'start',
			path: '/',
			redirect: '/login',
			component: loadView(AqPath.layout('Layout').get()),
			children: [
				{
					path: '/dashboard',
					name: 'dashboard',
					component: loadView(
						AqPath.project()
							.module('dashboard/Dashboard')
							.get(),
					),
				},
				{
					path: '/:navKey/:module/:view/:viewKey?',
					name: 'module',
					component: loadView(
						AqPath.project()
							.module('Modules')
							.get(),
					),
				},
			],
		},
		{
			path: '*',
			redirect: '/404',
		},
		{
			// the 404 route, when none of the above matches
			path: '/404',
			name: '404',
			component: loadView(
				AqPath.project()
					.error('Error404.vue')
					.get(),
			),
		},
	],
});

_objRouter.beforeEach((to, from, next) => {
	//first check if user is logged in
	if (store.getters.isAuthenticated) {
		// Ensure we checked auth before each page load.
		Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
	} else {
		if (to.name !== 'login') {
			next({ name: 'login' });
		} else {
			next();
		}
	}
});

export default _objRouter;
