import ApiService from '@/core/services/api.service';

import { dateGetTimestamp } from '@/core/project/plugins/dayjs';

let fetchEmployee = async strSlug => {
	return await ApiService.get('resources/aqe/people/item/', {
		connections: [
			'salary',
			'privatedata',
			'businessdata',
			'branchoffice',
			'skillswerte',
			'employmentdata:emplyoment',
			'employmentdata:companyscope',
			'annotations:annotationtype',
			'annotations:annotationtypecategory',
			'annotations:lasteditor',
			'annotations:performancereview',
			'annotations:performancereview:goal',
			'annotations:performancereview:goal:reminder',
			'annotations:performancereview:activity',
			'annotations:performancereview:activity:reminder',
			'annotations:reminder',
			'mitarbeiterfiles:category',
			'mitarbeiterfoto',
		],
		'where[slug]': strSlug,
	});
};

let saveEmployee = async objData => {
	return await ApiService.put('resources/aqe/people/item/', objData);
};

let savePerformancereview = async (objData, objPerformancereview) => {
	let _performanceReviewAnnotation = {
		description: 'Mitarbeitergespräch',
		annotation_type_id: 1,
		annotation_type_category_id: 2,
		created: dateGetTimestamp(),
		performancereview: {
			comments: objPerformancereview.comments,
			company_scope_id: objPerformancereview.company_scope_id,
			supervisor_id: objPerformancereview.supervisor_id,
			//TODO: retransform!
			date: objPerformancereview.date,
			reason: objPerformancereview.reason,
			supervisor_satisfaction_good: objPerformancereview.supervisor_satisfaction_good,
			supervisor_satisfaction_bad: objPerformancereview.supervisor_satisfaction_bad,
			work_satisfaction_good: objPerformancereview.work_satisfaction_good,
			work_satisfaction_bad: objPerformancereview.work_satisfaction_bad,
			activity: objPerformancereview.activity,
			goal: objPerformancereview.goal
		}
	};

	console.log(_performanceReviewAnnotation);

	//TODO: Filter activity, goal -> only used?
	//TODO: Editors ?!

	objData.annotations.push(_performanceReviewAnnotation);
	return await ApiService.put('resources/aqe/people/item', objData);
};

export { fetchEmployee, saveEmployee, savePerformancereview };
