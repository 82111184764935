<template>
	<div class="symbol symbol-50 symbol-light mt-1">
		<span class="symbol-label">
			<em :title="title" :class="cssclass"></em>
		</span>
	</div>
</template>
<script>
export default {
	name: 'AqSymbolIcon',
	components: {},
	props: {
		cssclass: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
	},
};
</script>
