<template>
	<div v-if="data && data.privatedata">
		<PersonalaktForm v-on:saveAnnotation="saveAnnotation" />
		<hr />
		<b-row class="pt-5">
			<b-col cols="8">
        <!--
				<b-button
					variant="primary"
					v-on:click="openMitarbeitergespraech"
					><em class="fad fa-user-friends"></em
					>&nbsp;Mitarbeitergespräch erfassen</b-button
				>
				-->
			</b-col>
			<b-col cols="4" class="pull-right">
				<b-form-input
					v-model="filtertext"
					placeholder="Personalakt filtern..."
				></b-form-input>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12">
				<div class="timeline timeline-6 mt-3">
					<template
						v-for="(objAnnotation, index) in filteredPersonalakt"
					>
						<PersonalaktLine
							v-bind:objAnnotation="objAnnotation"
							v-bind:key="index"
						/>
					</template>
				</div>
			</b-col>
		</b-row>
		<MitarbeitergespraechForm
			:data="data"
			:supervisor_id="data.employmentdata.supervisor_id"
			:company_scope_id="data.employmentdata.company_scope_id"
			v-bind:mitarbeitergespraechModalOpen="mitarbeitergespraechModalOpen"
		></MitarbeitergespraechForm>
	</div>
</template>

<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Mitarbeiter();

let PersonalaktLine = Controller.loadComponent('PersonalaktLine');
let PersonalaktForm = Controller.loadComponent('PersonalaktForm');
let MitarbeitergespraechForm = Controller.loadComponent(
	'MitarbeitergespraechForm',
);

export default {
	name: 'TabPersonalakt',
	components: {
		PersonalaktForm,
		PersonalaktLine,
		MitarbeitergespraechForm,
	},
	data() {
		return {
			filtertext: '',
			mitarbeitergespraechModalOpen: false,
		};
	},
	computed: {
		filteredPersonalakt() {
			if (!this.data.annotations) {
				return [];
			}
			return this.data.annotations.filter(objAnnotation => {
				return (
					(objAnnotation.description &&
						objAnnotation.description
							.toLowerCase()
							.includes(this.filtertext.toLowerCase())) ||
					(objAnnotation.annotationtypecategory.title &&
						objAnnotation.annotationtypecategory.title
							.toLowerCase()
							.includes(this.filtertext.toLowerCase())) ||
					(objAnnotation.lasteditor.username &&
						objAnnotation.lasteditor.username
							.toLowerCase()
							.includes(this.filtertext.toLowerCase())) ||
					(objAnnotation.created &&
						objAnnotation.created.includes(
							this.filtertext.toLowerCase(),
						))
				);
			});
		},
	},
	props: {
		data: Object,
	},
	methods: {
		saveAnnotation(objAnnotation) {
			this.data.annotations = [objAnnotation, ...this.data.annotations];
			this.$emit('tabSaveEvent');
		},
		openMitarbeitergespraech() {
			this.$bvModal.show('mitarbeitergespraechModal');
		},
	},
};
</script>
<style scoped>
hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.timeline.timeline-6 .timeline-item .timeline-label {
	width: 140px;
}

.timeline.timeline-6:before {
	left: 140px;
}
</style>
