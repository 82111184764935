/**
 *The Base Controller for Moduels
 * @author E.Neuwirth
 */
import Vue from 'vue';

import i18n from '@/core/plugins/vue-i18n';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

import ApiService from '@/core/services/api.service';
import AqToast from '@/view/project/components/AqToast';

class AqController {
	/**
	 * the default constructor gets the path and the module
	 */
	constructor(module, path) {
		this.module = module;
		this.folder = '';
		if (typeof path !== 'undefined') {
			this.folder += path + '/';
		}
		this.folder += module + '/';
	}

	/**
	 * Creates a new AqToast Object and executes the callToast function
	 */
	Toast() {
		return new AqToast();
	}

	/**
	 * Returns a new AqInput Object
	 */
	Input() {
		return Vue.Aq.Inputs();
	}

	/**
	 * Returns a new ApiParams Object
	 */
	ApiParams() {
		return Vue.Aq.ApiParams();
	}

	/**
	 * Returns a new AqError object and sets the current Controller to it
	 */
	Error() {
		return Vue.Aq.Error().withController(this);
	}

	/**
	 * Returns the i18n object so that extended modules can use it
	 */
	Translate() {
		return i18n;
	}

	/**
	 * Creates a pattern with the current module and checks if the translation exists
	 * else it will use the given fallback
	 */
	Translation(relative_path, fallback) {
		let pattern = this.module + '.' + relative_path;
		let exists = this.Translate().te(pattern);

		if (exists) {
			return this.Translate().tc(pattern);
		} else {
			if (typeof fallback === 'undefined') {
				fallback = 'general.' + relative_path;
			}
			let fallback_exists = this.Translate().te(fallback);
			if (fallback_exists) {
				return this.Translate().tc(fallback);
			} else {
				return pattern;
			}
		}
	}

	/**
	 * Retourns the AqLoader set to the current module path
	 */
	Loader() {
		return Vue.Aq.Loader.project().module(this.folder);
	}

	/**
	 * Loads a Input from the current Module
	 */
	loadInput(relative_file) {
		return this.Loader()
			.input(relative_file)
			.get();
	}

	/**
	 * Loads a Component from the current Module
	 */
	loadComponent(relative_file) {
		return this.Loader()
			.component(relative_file)
			.get();
	}

	/**
	 * Loads a Partial from the current Module
	 */
	loadPartial(relative_file) {
		return this.Loader()
			.partial(relative_file)
			.get();
	}

	/**
	 * the name for the route.push function
	 */
	routeName() {
		return 'module';
	}

	/**
	 * The Navkey for the navigation (for example administration)
	 */
	routeNavKey() {
		return 'default';
	}

	/**
	 * the default Route params for the current module
	 */
	defaultRouteParams(view) {
		return {
			navKey: this.routeNavKey(),
			module: this.module,
			view: view,
		};
	}

	/**
	 * Creates the Route of the current Module as String for usage as href parameter.
	 */
	routeLink(view) {
		return '/' + this.routeNavKey() + '/' + this.module + '/' + view;
	}

	/**
	 * builds the required route params and
	 * uses $router.push to go to the route
	 */
	route(view, optionalParams) {
		let params = this.defaultRouteParams(view);
		if (typeof optionalParams === 'object') {
			params = { ...params, ...optionalParams };
		}

		Vue.$router.push({
			name: this.routeName(),
			params: params,
		});
	}

	/**
	 * if a slub is available inside the data object it will be used for the route
	 * as an alternative the id will be used
	 */
	routeParamsFromEntry(data, optionalParams) {
		let params = {};
		if (typeof data[this.entryKey()] !== 'undefined') {
			params.viewKey = data[this.entryKey()];
		}
		if (typeof optionalParams === 'object') {
			params = { ...params, ...optionalParams };
		}
		return params;
	}

	/**
	 * builds the default params for a route to an entry
	 */
	routeEntry(view, data, optionalParams) {
		let params = this.routeParamsFromEntry(data, optionalParams);
		this.route(view, params);
	}

	/**
	 *returns a new AqList Object, can be extended
	 */
	newList() {
		return new Vue.Aq.Classes.List(this);
	}

	/**
	 *returns a new AqForm Object , can be extended
	 */
	newForm() {
		return new Vue.Aq.Classes.Form(this);
	}

	/**
	 * Builds the API Url via AqDtOptionsBuilder
	 */
	urlApi() {
		return process.env.VUE_APP_API_PATH + this.apiUrl();
	}

	/**
	 * The Url for the Api Call to get multiple items
	 */
	itemsUrl() {
		let items_url = this.urlApi() + 'items';
		return items_url;
	}

	/**
	 * The Url for the Api Call to get an item
	 */
	itemUrl() {
		let items_url = this.urlApi() + 'item';
		return items_url;
	}

	/**
	 * Sets the current Breadcrump via SET_BREADCRUMB
	 */
	dispatchBreadcrump(breadcrump) {
		Vue.$store.dispatch(SET_BREADCRUMB, breadcrump);
	}

	/**
	 * Builds the Breadcrump array for a module
	 */
	buildBreadcrump(key) {
		let breadcrump = new Array();
		switch (this.routeNavKey()) {
			case 'administration':
				breadcrump.push({
					title: this.Translate().tc('navigation.administration'),
				});
				break;
		}
		breadcrump.push({
			title: this.Translate().tc(this.module + '.title'),
			route: this.routeLink('index'),
		});

		switch (key) {
			case 'new':
				breadcrump.push({ title: this.Translation('newEntry') });
				break;

			case 'edit':
				breadcrump.push({ title: this.Translation('editEntry') });
				break;
		}

		return breadcrump;
	}

	/**
	 * The default setBreadcrump function for a module
	 */
	setBreadcrump(key) {
		this.dispatchBreadcrump(this.buildBreadcrump(key));
	}

	/**
	 * The Api call to to get items
	 */
	async loadEntries(apiOptions) {
		if (typeof apiOptions === 'undefined') {
			apiOptions = {};
		}
		return await ApiService.getSerialzied(this.itemsUrl(), apiOptions);
	}

	/**
	 * Runs the Api Call loadEntries and handles the response
	 */
	getEntries(loadParams) {
		let fetchEntries = async () => {
			return await this.loadEntries(loadParams)
				.then(response => {
					if (response.data.status === true) {
						return response.data.data[1];
					} else {
						this.Toast().callToast(response.message);
					}
				})
				.catch(error => {
					this.Toast().callToast(error);
				});
		};
		return fetchEntries();
	}

	/**
	 * The default key for the where command
	 */
	entryKey() {
		return 'id';
	}

	/**
	 * The Api call to to get an item
	 */
	async loadEntry(key, forApi) {
		var apiOptions = this.ApiParams().where(this.entryKey(), key);
		if (typeof forApi === 'function') {
			apiOptions = forApi(apiOptions);
		}

		return await ApiService.getSerialzied(
			this.itemUrl(),
			apiOptions.build(),
		);
	}

	/**
	 * The Api call to to put an item
	 */
	async putEntry(objData) {
		return await ApiService.put(this.itemUrl(), objData);
	}

	/**
	 * The Api call to to delete an item
	 */
	async deleteEntry(id) {
		var apiOptions = this.ApiParams().whereID(id);
		return await ApiService.deleteSerialzied(
			this.itemUrl(),
			apiOptions.build(),
		);
	}
}
export default AqController;
