<template>
	<div>
		<b-row>
			<h4>Fahrzeugbeurteilung</h4>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Sauberkeit außen"
					rules="required"
				>
					<AqFormGroup
						label="Sauberkeit außen"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-rating
							v-model="data.cleanness_outside"
							:class="classes"
						></b-form-rating>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Sauberkeit innen"
					rules="required"
				>
					<AqFormGroup
						label="Sauberkeit innen"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-rating
							v-model="data.cleanness_inside"
							:class="classes"
						></b-form-rating>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Inhalt Aschenbecher"
					rules="required"
				>
					<AqFormGroup
						label="Inhalt Aschenbecher"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-rating
							v-model="data.substance_ashtray"
							:class="classes"
						></b-form-rating>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Müll"
					rules="required"
				>
					<AqFormGroup
						label="Müll"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-rating
							v-model="data.garbage"
							:class="classes"
						></b-form-rating>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Zustand Sitze"
					rules="required"
				>
					<AqFormGroup
						label="Zustand Sitze"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-rating
							v-model="data.seat_condition"
							:class="classes"
						></b-form-rating>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';

export default {
	name: 'FahrzeugbeurteilungComponent',
	components: { AqFormGroup },
	props: {
		data: Object,
	},
};
</script>

<style scoped></style>
