<template>
	<b-row>
		<b-col cols="12" md="3">
			<div class="card card-custom bg-gray-100 card-stretch gutter-b">
				<b-card-group deck>
					<b-card>
						<b-list-group flush>
							<b-list-group-item
								><strong>TEST</strong></b-list-group-item
							>
							<b-list-group-item>TEST</b-list-group-item>
							<b-list-group-item>2222</b-list-group-item>
							<b-list-group-item>3333</b-list-group-item>
							<b-list-group-item>55555</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-card-group>
			</div>
		</b-col>
		<b-col cols="12" md="9">
			<div class="card card-custom bg-gray-100 card-stretch gutter-b">
				<b-card no-body>
					<b-form
						name="EditFahrzeugmanagementForm"
						@submit.prevent="onSubmit"
					>
						<b-card-body class="p-0">
							<b-tabs card>
								<ValidationObserver ref="observerStammdaten">
									<b-tab title="Grunddaten" active>
										<b-card-text>
											<GrunddatenComponent />
										</b-card-text>
									</b-tab>
									<b-tab title="Reifen">
										<b-card-text>
											<ReifenComponent />
										</b-card-text>
									</b-tab>
									<b-tab title="Ausstattung">
										<b-card-text>
											<AusstattungComponent />
										</b-card-text>
									</b-tab>
									<b-tab title="Fahrzeugschäden">
										<b-card-text>
											<FahrzeugschaedenComponent />
										</b-card-text>
									</b-tab>
									<b-tab
										title="Fahrzeugbeurteilung/Wartungsdaten"
									>
										<b-card-text>
											<FahrzeugbeurteilungComponent />
											<WartungsdatenComponent />
										</b-card-text>
									</b-tab>
								</ValidationObserver>
							</b-tabs>
						</b-card-body>
						<b-card-footer class="card card-body bg-light">
							<div class="text-right">
								<b-button variant="secondary"
									>Abbrechen</b-button
								>&nbsp;
								<b-button variant="primary" type="submit"
									><em class="fad fa-save"></em
									>&nbsp;Speichern</b-button
								>
							</div>
						</b-card-footer>
					</b-form>
				</b-card>
			</div>
		</b-col>
	</b-row>
</template>

<script>
import GrunddatenComponent from './components/GrunddatenComponent.vue';
import ReifenComponent from './components/ReifenComponent.vue';
import AusstattungComponent from './components/AusstattungComponent.vue';
import FahrzeugschaedenComponent from './components/FahrzeugschaedenComponent.vue';
import FahrzeugbeurteilungComponent from './components/FahrzeugbeurteilungComponent.vue';
import WartungsdatenComponent from './components/WartungsdatenComponent.vue';

export default {
	name: 'EditFahrzeugmanagement',
	components: {
		GrunddatenComponent,
		ReifenComponent,
		AusstattungComponent,
		FahrzeugschaedenComponent,
		FahrzeugbeurteilungComponent,
		WartungsdatenComponent,
	},
};
</script>

<style scoped></style>
