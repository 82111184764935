<template>
	<div>
		<br />
		<div class="container">
			<div
				class="row justify-content-md-center bg-white p-15"
				id="kt_login"
			>
				<!--begin::Content-->
				<div class="col-md-6">
					<div
						role="alert"
						class="alert alert-primary pt-10 pb-10 mb-10"
					>
						<div class="alert-text text-center">
							<a href="#">
								<img :src="logoImage" />
							</a>
						</div>
					</div>

					<div class="text-center">
						<h3 class="font-size-h1">Portal Login</h3>
					</div>

					<!--begin::Form-->
					<b-form class="form" @submit.stop.prevent="onSubmit">
						<div
							role="alert"
							v-bind:class="{ show: errors }"
							class="alert fade alert-danger"
						>
							|{{ typeof errors }}|

							<div
								class="alert-text"
								v-for="(error, i) in errors"
								:key="i"
							>
								{{ error }}
							</div>
						</div>

						<b-form-group
							id="example-input-group-1"
							label="E-Mail Adresse:"
							label-for="example-input-1"
							:state="state"
						>
							<b-form-input
								class="form-control form-control-solid h-auto py-5 px-6"
								id="example-input-1"
								name="example-input-1"
								v-model="form.email"
								:state="state"
								aria-describedby="input-1-live-feedback"
							></b-form-input>

							<b-form-invalid-feedback id="input-1-live-feedback">
								Email is required and a valid email address.
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group
							id="example-input-group-2"
							label="Passwort: "
							label-for="example-input-2"
						>
							<b-form-input
								class="form-control form-control-solid h-auto py-5 px-6"
								type="password"
								v-model="form.password"
								aria-describedby="input-2-live-feedback"
							></b-form-input>

							<b-form-invalid-feedback id="input-2-live-feedback">
								Password is required.
							</b-form-invalid-feedback>
						</b-form-group>

						<!--begin::Action-->
						<div
							class="form-group d-flex flex-wrap justify-content-between align-items-center"
						>
							<a
								href="#"
								class="text-dark-60 text-hover-primary my-3 mr-2"
								id="kt_login_forgot"
							>
								Passwort zurücksetzen ?
							</a>
							<button
								ref="kt_login_signin_submit"
								class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
							>
								Login
							</button>
						</div>
						<!--end::Action-->
					</b-form>
					<!--end::Form-->
				</div>
				<!--end::Signin-->
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
	padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'login',
	data() {
		return {
			state: false,
			form: {
				email: '',
				password: '',
			},
		};
	},
	methods: {
		resetForm() {
			this.form = {
				email: null,
				password: null,
			};
		},
		onSubmit() {
			this.$store
				.dispatch('login', {
					username: this.form.email,
					password: this.form.password,
				})
				.then(() => this.$router.push({ name: 'dashboard' }))
				.catch(() => {});
		},
	},
	mounted: function() {
		if (this.$store.getters.isAuthenticated) {
			this.$router.push({ name: 'dashboard' });
		}
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors,
		}),
		...mapGetters(['currentUser']),

		logoImage() {
			return process.env.BASE_URL + 'assets/img/logo.png';
		},
	},
};
</script>
