<template>
	<b-row>
		<b-col cols="12" class="py-0">
			<b-row>
				<b-col cols="12">
					<span class="font-weight-bold">{{ text }}:</span>
				</b-col>
				<b-col cols="6">
					<span>Profil in Ordnung</span>
				</b-col>
				<b-col cols="6">
					<b-form-radio-group
						v-model="data[dataBinding.profile]"
						:name="'radio-profile-' + dataBinding.name"
					>
						<b-form-radio value="i.O.">i.O</b-form-radio>
						<b-form-radio value="nicht i.O."
							>nicht i.O</b-form-radio
						>
					</b-form-radio-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="6">
					<span>Sichtbare Schäden</span>
				</b-col>
				<b-col cols="6">
					<b-form-radio-group
						v-model="data[dataBinding.defect]"
						:name="'radio-defect-' + dataBinding.name"
					>
						<b-form-radio value="Ja">Ja</b-form-radio>
						<b-form-radio value="Nein">Nein</b-form-radio>
					</b-form-radio-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12" class="py-0">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						:name="'Reifentype - ' + text"
						rules="required"
					>
						<AqFormGroup
							label="Reifentype"
							:boolRequired="true"
							:state="state"
						>
							<b-form-input
								v-model="data[dataBinding.type]"
								:class="classes"
							/>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</AqFormGroup>
					</ValidationProvider>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12" class="py-0">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						:name="'Reifenart - ' + text"
						rules="required"
					>
						<AqFormGroup
							label="Reifenart"
							:boolRequired="true"
							:state="state"
						>
							<b-form-input
								v-model="data[dataBinding.kind]"
								:class="classes"
							/>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</AqFormGroup>
					</ValidationProvider>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';

export default {
	name: 'ReifenPartialComponent',
	components: { AqFormGroup },
	props: {
		text: String,
		data: Object,
		dataBinding: Object,
	},
};
</script>

<style scoped></style>
