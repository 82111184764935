/**
 * General Class for the creation of a list view with datatables
 * @author E.Neuwirth
 */
import Vue from 'vue';

class AqList {
	/**
	 * Called from the extended module from src\core\project\modules\AqController.js
	 * gets the object from where it's called from
	 */
	constructor(Controller) {
		//the controller from the current module
		this._objController = Controller;

		//The current vue List Component (set in initData)
		this._vueList = null;

		//init the temp data for chains
		this.temp_data = {
			list: {},
			delete: {},
			table: {},
		};
	}

	/**
	 * Calls the Controller from the current module
	 */
	Controller() {
		return this._objController;
	}

	/**
	 * Calls the Vue List
	 */
	List() {
		return this._vueList;
	}

	/**
	 * Returns a new AqError object
	 * from the Controller and sets the current object to it
	 */
	Error() {
		return this.Controller()
			.Error()
			.withClass(this, 'list');
	}

	/**
	 * Returns a new AqColumn object
	 */
	Column() {
		return Vue.Aq.Column().withController(this.Controller());
	}

	/**
	 * Loads the default compenents for the List
	 */
	initComponents() {
		const viewList = Vue.Aq.Loader.layout()
			.module('ViewList')
			.get();
		return {
			'view-list': viewList,
		};
	}

	/**
	 * The Data for the Vue Component
	 */
	initData(vueList) {
		this._vueList = vueList;

		return {
			forList: this.listData(),
			forTable: this.tableData(),
			forDelete: this.deleteData(),
		};
	}

	/**
	 * chainable function for the initData function
	 * which extends the data from listData()
	 */
	forList(dataToExtend) {
		this.temp_data.list = dataToExtend;
		return this;
	}

	/**
	 * The current Data and Functions for the List
	 */
	listData() {
		let obj = this;

		let listData = {
			title: false,
			titleAdd: obj.Controller().Translation('newEntry'),
			titleEdit: obj.Controller().Translation('editEntry'),
			titleDelete: obj.Controller().Translation('deleteEntry'),
			withQickSearch: true,
			rights: {
				add: true,
			},
			quickSearch: null,
			isLoading: false,
			onSearch: function() {
				obj.onSearch();
			},
			clearSearch: function() {
				obj.onSearch(true);
			},
			onAdd: function() {
				obj.onAdd();
			},
		};

		if (typeof this.temp_data.list === 'object') {
			listData = { ...listData, ...this.temp_data.list };
			this.temp_data.list = null;
		}

		return listData;
	}

	/**
	 * The Function for the QuickSearch
	 */
	onSearch(clear) {
		if (typeof clear !== 'undefined' && clear) {
			this.List().forList.quickSearch = null;
		}
		if (typeof this.List() === 'object') {
			this.loadTableData();
		}
	}

	/**
	 * The Function for creating a new entry
	 */
	onAdd() {
		this.Controller().route('new');
	}

	/**
	 * chainable function for the initData function
	 * which extends the data from tableData()
	 */
	forTable(dataToExtend) {
		this.temp_data.table = dataToExtend;
		return this;
	}

	/**
	 * The current Data and Functions for the Table
	 */
	tableData() {
		let obj = this;

		let tableData = {
			isLoading: true,
			totalItems: 0,
			items: new Array(),
			options: {},
			headers: {},
			itemsPerPage: 50,
			onEdit: function(data) {
				obj.onEdit(data);
			},
			onDelete: function(data) {
				obj.onDelete(data);
			},
			loadData: function() {
				obj.loadTableData();
			},
			setRow: null,
			forApi: null,
		};

		if (typeof this.temp_data.table === 'object') {
			tableData = { ...tableData, ...this.temp_data.table };
			this.temp_data.table = null;
		}

		return tableData;
	}

	/**
	 * The Function for loading entries from the api
	 */
	loadTableData() {
		let obj = this;
		obj.List().forList.isLoading = true;

		var apiOptions = obj.Controller().ApiParams();
		var {
			sortBy,
			sortDesc,
			page,
			itemsPerPage,
		} = obj.List().forTable.options;

		if (typeof obj.List().forTable.forApi === 'function') {
			apiOptions = obj.List().forTable.forApi(apiOptions);
		}

		apiOptions
			.forList()
			.columnsArray(obj.List().forTable.headers)
			.orderArray(sortBy, sortDesc)
			.paging(page, itemsPerPage);

		if (obj.List().forList.withQickSearch) {
			apiOptions.search(obj.List().forList.quickSearch);
		}

		obj.Controller()
			.loadEntries(apiOptions.build())
			.then(response => {
				obj.List().forList.isLoading = false;

				if (
					response.status === 200 &&
					typeof response.data !== 'undefined'
				) {
					obj.List().forTable.totalItems = response.data.recordsTotal;
					if (obj.List().forTable.totalItems) {
						obj.List().forTable.items = obj.setEntries(
							response.data.data,
						);
					} else {
						obj.List().forTable.items = new Array();
					}
				} else {
					obj.Error().critical('load', response);
				}
			})
			.catch(error => {
				obj.List().forList.isLoading = false;
				obj.Error().catched('load', error);
			});
	}

	/**
	 * Gets the items from the backend and calls the setRow on every item
	 */
	setEntries(items) {
		for (let row_index in items) {
			items[row_index] = this.setRow(items[row_index]);
		}
		return items;
	}

	/**
	 * Gets a row from the items result, sets the rights
	 * and calls the setRow from forTable if it is available
	 */
	setRow(row) {
		row.rights = this.setRights(row);
		if (typeof this.List().forTable.setRow === 'function') {
			row = this.List().forTable.setRow(row);
		}
		return row;
	}

	/**
	 * Creates the right object and merges it with the available rights from the row
	 */
	setRights(row) {
		let rights = {
			edit: true,
			delete: true,
		};
		if (typeof row.rights === 'object') {
			rights = { ...rights, ...row.rights };
		}
		return rights;
	}

	/**
	 * The Function for editing an existing entry
	 */
	onEdit(data) {
		this.Controller().routeEntry('edit', data);
	}

	/**
	 * The Function for showing the delete dialog
	 */
	onDelete(data) {
		this.List().forDelete.open = true;
		this.List().forDelete.passEntry = data;
	}

	/**
	 * chainable function for the initData function
	 * which extends the data from deleteData()
	 */
	forDelete(dataToExtend) {
		this.temp_data.delete = dataToExtend;
		return this;
	}

	/**
	 * The current Data and Functions for the Delete Dialog
	 */
	deleteData() {
		let obj = this;

		let deleteData = {
			open: false,
			passEntry: null,
			isDeleting: false,
			size: 'sm',
			title: obj.Controller().Translation('deleteEntry'),
			question: obj.Controller().Translation('deleteQuestion'),
			deletingText: obj.Controller().Translation('deletingText'),
			cancelText: obj
				.Controller()
				.Translate()
				.tc('general.cancel'),
			doText: obj
				.Controller()
				.Translate()
				.tc('general.delete'),
			closeModal() {
				obj.closeDeleteModal();
			},
			doDelete(data) {
				obj.doDelete(data);
			},
		};

		if (typeof this.temp_data.delete === 'object') {
			deleteData = { ...deleteData, ...this.temp_data.delete };
			this.temp_data.delete = null;
		}

		return deleteData;
	}

	/**
	 * The Function for hiding the delete dialog
	 */
	closeDeleteModal() {
		this.List().forDelete.open = false;
		this.List().forDelete.isDeleting = false;
		this.List().forDelete.passEntry = null;
	}

	/**
	 * The Function for deleting an existing entry
	 */
	doDelete(data) {
		let obj = this;
		obj.List().forDelete.isDeleting = true;

		obj.Controller()
			.deleteEntry(data.id)
			.then(response => {
				obj.closeDeleteModal();

				if (response.status === true) {
					obj.loadTableData();

					obj.Controller()
						.Toast()
						.callToast(
							obj.Controller().Translation('entryDeleted'),
						);
				} else {
					obj.Error().critical('delete', response);
				}
			})
			.catch(error => {
				obj.closeDeleteModal();
				obj.Error().catched('delete', error);
			});
	}

	/**
	 * the default functions for computed
	 */
	initComputed(computed) {
		let defaultComputed = {
			backgroundImage() {
				return process.env.BASE_URL + 'assets/img/bg1.jpg';
			},
		};

		if (typeof computed === 'object') {
			return { ...defaultComputed, ...computed };
		} else {
			return defaultComputed;
		}
	}
}

export default AqList;
