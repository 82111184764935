<template>
	<b-modal
		@ok="saveMitarbeitergespraech"
		no-close-on-backdrop
		size="xl"
		id="mitarbeitergespraechModal"
		title="Mitarbeitergespräch erfassen"
	>
		<b-container fluid>
      <b-row>
				<b-col cols="6" class="py-0">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						name="Datum"
					>
						<AqFormGroup
							label="Datum"
							labelcolssm="4"
							:state="state"
						>
							<b-form-datepicker
								id="example-performancereviewDate"
								v-model="objPerformanceReview.date"
								:class="classes"
							></b-form-datepicker>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</AqFormGroup>
					</ValidationProvider>
				</b-col>
				<b-col cols="6" class="py-0">
					<AqFormGroup label="Name" labelcolssm="4">
						{{ employeename }}
					</AqFormGroup>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="6" class="py-0">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						name="Datum"
					>
						<AqFormGroup
							label="Vorgesetzter"
							labelcolssm="4"
							:state="state"
						>
							<b-form-select
								id="input-performancereviewSupervisorId"
								:options="arrSupervisor"
								v-model="objPerformanceReview.supervisor_id"
								:class="classes"
							/>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</AqFormGroup>
					</ValidationProvider>
				</b-col>
				<b-col cols="6" class="py-0">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						name="Abteilung"
						rules="required"
					>
						<AqFormGroup
							label="Abteilung"
							labelcolssm="4"
							:state="state"
						>
							<b-form-select
								id="input-performancereviewCompanyScope"
								:options="arrCompanyscope"
								v-model="objPerformanceReview.company_scope_id"
								:class="classes"
							/>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</AqFormGroup>
					</ValidationProvider>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-tabs
						content-class="mt-3"
						justified
						active-nav-item-class="font-weight-bold text-primary"
					>
						<b-tab active>
							<template #title>
								<em class="fad fa-comments pr-1"></em>
								<strong>Allgemein</strong>
							</template>
							<b-row>
								<b-col>
									<h5></h5>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<div class="table-responsive">
										<table
											class="table table-head-custom table-vertical-center"
										>
											<thead>
												<tr>
													<td colspan="2" width="30%">
														FRAGE
													</td>
													<td>BESCHREIBUNG</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<AqSymbolIcon
															cssclass="fad fa-2x fa-handshake-alt"
														/>
													</td>
													<td>
														Grund/Anlass (nähere
														Beschreibung)
													</td>
													<td>
														<ValidationProvider
															v-slot="{
																errors,
																classes,
															}"
															name="Grund/Anlass"
															rules="required"
														>
															<b-form-textarea
																id="input-reason"
																rows="3"
																v-model="
																	objPerformanceReview.reason
																"
																:classes="
																	classes
																"
															/>

															<span
																class="text-danger"
																v-show="
																	errors[0]
																"
																>{{
																	errors[0]
																}}</span
															>
														</ValidationProvider>
													</td>
												</tr>
												<tr>
													<td>
														<AqSymbolIcon
															cssclass="fad fa-2x fa-smile"
														/>
													</td>
													<td>
														Vorgesetze(r) / Was
														funktioniert gut?
													</td>
													<td>
														<ValidationProvider
															v-slot="{
																errors,
																classes,
															}"
															name="'Zusammenarbeit mit Vorgesetzten - Was funktioniert gut'"
															rules="required"
														>
															<b-form-textarea
																id="input-supervisorSatisfactionGood"
																rows="3"
																v-model="
																	objPerformanceReview.supervisor_satisfaction_good
																"
																:classes="
																	classes
																"
															/>
															<span
																class="text-danger"
																v-show="
																	errors[0]
																"
																>{{
																	errors[0]
																}}</span
															>
														</ValidationProvider>
													</td>
												</tr>
												<tr>
													<td>
														<AqSymbolIcon
															cssclass="fad fa-2x fa-frown"
														/>
													</td>
													<td>
														Vorgesetze(r) / Was
														funktioniert nicht gut?
													</td>
													<td>
														<ValidationProvider
															v-slot="{
																errors,
																classes,
															}"
															name="'Zusammenarbeit mit Vorgesetzten - Was funktioniert weniger gut'"
															rules="required"
														>
															<b-form-textarea
																id="input-supervisorSatisfactionBad"
																rows="3"
																v-model="
																	objPerformanceReview.supervisor_satisfaction_bad
																"
																:classes="
																	classes
																"
															/>
															<span
																class="text-danger"
																v-show="
																	errors[0]
																"
																>{{
																	errors[0]
																}}</span
															>
														</ValidationProvider>
													</td>
												</tr>
												<tr>
													<td>
														<AqSymbolIcon
															cssclass="fad fa-2x fa-user-hard-hat"
														/>
													</td>
													<td>
														Arbeitszufriedenheit /
														Was funktioniert gut?
													</td>
													<td>
														<ValidationProvider
															v-slot="{
																errors,
																classes,
															}"
															name="'Arbeitszufriedenheit Allgemein - Was funktioniert gut'"
															rules="required"
														>
															<b-form-textarea
																id="input-workSatisfactionGood"
																rows="3"
																v-model="
																	objPerformanceReview.work_satisfaction_good
																"
																:classes="
																	classes
																"
															/>
															<span
																class="text-danger"
																v-show="
																	errors[0]
																"
																>{{
																	errors[0]
																}}</span
															>
														</ValidationProvider>
													</td>
												</tr>
												<tr>
													<td>
														<AqSymbolIcon
															cssclass="fad fa-2x fa-user-hard-hat"
														/>
													</td>
													<td>
														Arbeitszufriedenheit /
														Was funktioniert weniger
														gut?
													</td>
													<td>
														<ValidationProvider
															v-slot="{
																errors,
																classes,
															}"
															name="'Arbeitszufriedenheit Allgemein - Was funktioniert weniger gut'"
															rules="required"
														>
															<b-form-textarea
																id="input-workSatisfactionBad"
																rows="3"
																v-model="
																	objPerformanceReview.work_satisfaction_bad
																"
																:classes="
																	classes
																"
															/>
															<span
																class="text-danger"
																v-show="
																	errors[0]
																"
																>{{
																	errors[0]
																}}</span
															>
														</ValidationProvider>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab>
							<template #title>
								<em class="fad fa-chart-line pr-1"></em>
								<strong>Zielvereinbarung</strong>
							</template>
							<b-row>
								<b-col>
									<div class="table-responsive">
										<table
											class="table table-head-custom table-vertical-center"
										>
											<thead>
												<tr>
													<th width="20%" scope="col">
														Ziel/Priorität
													</th>
													<th scope="col">
														Beschreibung des Ziels
													</th>
													<th width="20%" scope="col">
														Fällig bis?
													</th>
												</tr>
											</thead>
											<tbody>
												<PerformancereviewGoal
													v-for="(objGoal,
													index) in objPerformanceReview.goal"
													:key="index"
													:objGoal="objGoal"
												/>
											</tbody>
										</table>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab>
							<template #title>
								<em class="fad fa-tasks pr-1"></em
								><strong>Maßnahmen</strong>
							</template>
							<b-row>
								<b-col>
									<div class="table-responsive">
										<table
											class="table table-head-custom table-vertical-center"
										>
											<thead>
												<tr>
													<th width="5%" scope="col">
														Nr.
													</th>
													<th scope="col">
														Beschreibung des Ziels
													</th>
													<th width="20%" scope="col">
														Verantworlich?
													</th>
													<th width="20%" scope="col">
														Fällig bis?
													</th>
												</tr>
											</thead>
											<tbody>
												<PerformancereviewActivity
													v-for="(objActivity,
													index) in objPerformanceReview.activity"
													:key="index"
													:objActivity="objActivity"
												/>
											</tbody>
										</table>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab>
							<template #title>
								<em class="fad fa-info-circle pr-1"></em>
								<strong>Sonstige Anmerkungen</strong>
							</template>
							<b-row>
								<b-col>
									<strong
										>Sonstige Anmerkungen, Wünsche,
										Beschwerden</strong
									>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-textarea
										id="input-description"
										rows="3"
										v-model="objPerformanceReview.comments"
									/>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
		</b-container>

		<template #modal-footer="{ ok, cancel }">

			<b-button variant="secondary" @click="cancel">Abbrechen</b-button
			>&nbsp;
			<b-button variant="primary" @click="ok"
				><em class="fad fa-save"></em>&nbsp;Speichern</b-button
			>
		</template>
	</b-modal>
</template>

<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Mitarbeiter();

import { savePerformancereview } from '@/view/project/api/EmployeeData';

let AqFormGroup = Vue.Aq.Loader.project()
	.component('AqFormGroup')
	.get();
let AqSymbolIcon = Vue.Aq.Loader.project()
	.component('AqSymbolIcon')
	.get();
let PerformancereviewGoal = Controller.loadComponent('PerformancereviewGoal');
let PerformancereviewActivity = Controller.loadComponent(
	'PerformancereviewActivity',
);

import {
	fetchSupervisors,
	fetchCompanyscopes,
} from '@/view/project/api/BaseData';

export default {
	name: 'MitarbeitergespraechForm',
	components: {
		PerformancereviewGoal,
		PerformancereviewActivity,
		AqSymbolIcon,
		AqFormGroup,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		company_scope_id: {
			type: String,
		},
		supervisor_id: {
			type: String,
		},
	},
	data() {
		return {
			objPerformanceReview: {
				date: new Date(),
			},
			arrSupervisor: [],
			arrCompanyscope: [],
		};
	},
	computed: {
		employeename() {
			return this.data.fullname;
		},
	},
	created() {
		this.initGoals();
		this.initActivities();
	},
	mounted() {
		fetchSupervisors().then(response => {
			this.arrSupervisor = response.data.data[1].map(object => ({
				value: object.id,
				text: object.fullname,
			}));
			this.objPerformanceReview.supervisor_id = this.supervisor_id;
		});

		fetchCompanyscopes().then(response => {
			this.arrCompanyscope = response.data.data[1].map(object => ({
				value: object.id,
				text: object.title,
			}));
			this.objPerformanceReview.company_scope_id = this.company_scope_id;
		});
	},
	methods: {
		initGoals() {
			this.objPerformanceReview.goal = [];
			for (let i = 0; i < 4; i++) {
				this.objPerformanceReview.goal.push({
					priority: i + 1,
					description: '',
					reminder_date: new Date(),
				});
			}
		},
		initActivities() {
			this.objPerformanceReview.activity = [];
			for (let i = 0; i < 4; i++) {
				this.objPerformanceReview.activity.push({
					count: i + 1,
					description: '',
					responsible_person_id: 1,
					reminder_date: new Date(),
				});
			}
		},
		saveMitarbeitergespraech() {

      savePerformancereview(this.data,this.objPerformanceReview)
        .then(function() {
            this.showSuccessToastr();
        });



      //     .then(response => {
      //   if (!this.boolIsEditEmployee) {
      //     let _userData = response.data;
      //     this.showSuccessToastr();
      //     this.$router.go({
      //       name: 'administrationmitarbeiteredit',
      //       params: { slug: _userData.data.slug },
      //     });
      //   } else {
      //     this.showSuccessToastr();
      //     this.getData();
      //   }
      // });


			/*this.$emit(
        'mitarbeitergespraechModalOpen',
        !this.mitarbeitergespraechModalOpen,
      );*/
		},
	},
};
</script>

<style scoped></style>
