var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('h4',{staticClass:"py-3"},[_vm._v("Wartungsdaten")])]),_c('b-row',{staticClass:"my-2"},[_c('h5',[_vm._v("Öl nachgefüllt")])]),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Liter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Liter","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-oil_refill_quantity"},model:{value:(_vm.data.oil_refill_quantity),callback:function ($$v) {_vm.$set(_vm.data, "oil_refill_quantity", $$v)},expression:"data.oil_refill_quantity"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Datum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Datum","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-oil_refill_date","type":"date"},model:{value:(_vm.data.oil_refill_date),callback:function ($$v) {_vm.$set(_vm.data, "oil_refill_date", $$v)},expression:"data.oil_refill_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"my-2"},[_c('h5',[_vm._v("Kühlwasser nachgefüllt")])]),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Liter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Liter","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-heat_exchange_water_refill_quantity"},model:{value:(_vm.data.heat_exchange_water_refill_quantity),callback:function ($$v) {_vm.$set(_vm.data, "heat_exchange_water_refill_quantity", $$v)},expression:"data.heat_exchange_water_refill_quantity"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Datum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Datum","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-heat_exchange_water_refill_date","type":"date"},model:{value:(_vm.data.heat_exchange_water_refill_date),callback:function ($$v) {_vm.$set(_vm.data, "heat_exchange_water_refill_date", $$v)},expression:"data.heat_exchange_water_refill_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"border-top mt-2 pt-2"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"letzte Inspektion am","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Letzte Inspektion am","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-last_service","type":"date"},model:{value:(_vm.data.last_service),callback:function ($$v) {_vm.$set(_vm.data, "last_service", $$v)},expression:"data.last_service"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ablaufdatum Verbandskasten","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Ablaufdatum Verbandskasten","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-first_aid_kid_expiration_date","type":"date"},model:{value:(_vm.data.first_aid_kid_expiration_date),callback:function ($$v) {_vm.$set(_vm.data, "first_aid_kid_expiration_date", $$v)},expression:"data.first_aid_kid_expiration_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Gültigkeit Vignette","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Gültigkeit Vignette","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-vignette_expiration_date","type":"date"},model:{value:(_vm.data.vignette_expiration_date),callback:function ($$v) {_vm.$set(_vm.data, "vignette_expiration_date", $$v)},expression:"data.vignette_expiration_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }