<template>
	<b-modal
		no-close-on-backdrop
		size="xl"
		id="newTaskModal"
		@hidden="resetModal"
		@show="showModal"
		:title="
			'Neuen Fahrzeugtask erfassen für ' +
				this.modalData.marke +
				' ' +
				this.modalData.modell +
				' (Fahrgestellnummer: ' +
				this.modalData.vin +
				')'
		"
	>
		<b-container fluid>
			<b-overlay :show="isLoading">
				<b-row class="border-bottom">
					<b-col>
						<AqFormGroup label="Task" labelcolssm="4">
							<b-form-select
								id="input-task"
								v-model="data.adm_category_id_type"
							>
								<b-form-select-option value="null" disabled>
									Bitte wählen
								</b-form-select-option>
								<b-form-select-option
									v-for="task in this.arrTasks"
									v-bind:key="task.id"
									:value="task.id"
									>{{ task.name }}</b-form-select-option
								>
							</b-form-select>
						</AqFormGroup>
					</b-col>
				</b-row>

				<b-row v-if="data.adm_category_id_type == null">
					<b-col cols="12">
						<b-alert variant="warning" show>
							Bitte einen Task auswählen.
						</b-alert>
					</b-col>
				</b-row>

				<ValidationObserver ref="validationObserverFahrzeuguebergabe">
					<FahrzeuguebergabeTaskComponent
						v-if="data.adm_category_id_type === 1"
						:data="this.data"
						class="pt-5"
					/>
				</ValidationObserver>

				<ValidationObserver ref="validationObserverFahrzeugpruefung">
					<FahrzeugpruefungTaskComponent
						v-if="data.adm_category_id_type === 2"
						:data="this.data"
						class="pt-5"
					/>
				</ValidationObserver>

				<ValidationObserver ref="validationObserverFahrzeugruecknahme">
					<FahrzeuguebergabeTaskComponent
						v-if="data.adm_category_id_type === 3"
						:data="this.data"
						class="pt-5"
					/>
				</ValidationObserver>

				<ValidationObserver ref="validationObserverReparatur">
					<ReparaturTaskComponent
						v-if="data.adm_category_id_type === 4"
						:data="this.data"
						:vehicle-data="this.vehicleData"
						class="pt-5"
					/>
				</ValidationObserver>

				<ValidationObserver ref="validationObserverSchadensereignis">
					<SchadensereignisTaskComponent
						v-if="data.adm_category_id_type === 5"
						:data="this.data"
						:vehicle-data="this.vehicleData"
						class="pt-5"
            @changed-signature="changeSignature"
					/>
				</ValidationObserver>
			</b-overlay>
		</b-container>

		<template #modal-footer="{ ok, cancel }">
			<b-button variant="secondary" @click="cancel">Abbrechen</b-button
			>&nbsp;
			<b-button variant="primary" @click="save()"
				><em class="fad fa-save"></em>&nbsp;Speichern</b-button
			>
		</template>
	</b-modal>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';
import FahrzeuguebergabeTaskComponent from './FahrzeuguebergabeTaskComponent';
import SchadensereignisTaskComponent from './SchadensereignisTaskComponent';
import FahrzeugpruefungTaskComponent from './FahrzeugpruefungTaskComponent';
import AqToast from '@/view/project/components/AqToast';
import { saveTask } from '@/view/project/api/VehicleData';
import { fetchVehicle } from '@/view/project/api/VehicleData';
import ReparaturTaskComponent from './ReparaturTaskComponent';

export default {
	name: 'NewTaskModalComponent',
	components: {
		ReparaturTaskComponent,
		AqFormGroup,
		FahrzeuguebergabeTaskComponent,
		SchadensereignisTaskComponent,
		FahrzeugpruefungTaskComponent,
	},
	props: {
		modalData: Object,
	},
	data() {
		return {
			isLoading: false,
			vehicleData: {},
			data: {
				aqe_vehicle_id: null,
				adm_category_id_type: null,
			},
			arrTasks: [
				{ id: 1, name: 'Fahrzeugübergabe' },
				{ id: 2, name: 'Fahrzeugprüfung' },
				{ id: 3, name: 'Fahrzeugrücknahme' },
				{ id: 4, name: 'Reparatur/Wartung' },
				{ id: 5, name: 'Schadensereignis' },
			],
		};
	},
	methods: {
		showModal() {
			this.data.aqe_vehicle_id = this.modalData.id;
			this.data.damage = this.modalData.damage;

			this.loadData();
		},
		resetModal() {
			this.data.adm_category_id_type = null;
			this.resetData();
		},
		resetData() {
			for (let index in this.data) {
				if (
					index !== 'aqe_vehicle_id' &&
					index !== 'adm_category_id_type' &&
					index !== 'damage'
				) {
					delete this.data[index];
				}
			}
		},
    changeSignature(event) {
		  console.log(event);
    },
		async save() {
			if (this.data.adm_category_id_type === null) {
				new AqToast().showValidationErrors(
					['Keine Task-Kategorie ausgewählt'],
					'Neuer Fahrzeugtask',
				);
			} else {
				let blnValidation = false;
				let arrMessages = [];

				switch (this.data.adm_category_id_type) {
					// Fahrzeugübernahme
					case 1:
						blnValidation = await this.$refs.validationObserverFahrzeuguebergabe.validate();
						arrMessages = this.$refs
							.validationObserverFahrzeuguebergabe.errors;
						break;
					// Fahrzeugprüfung
					case 2:
						blnValidation = await this.$refs.validationObserverFahrzeugpruefung.validate();
						arrMessages = this.$refs
							.validationObserverFahrzeugpruefung.errors;
						break;

					// Fahrzeugrücknahme
					case 3:
						blnValidation = await this.$refs.validationObserverFahrzeugruecknahme.validate();
						arrMessages = this.$refs
							.validationObserverFahrzeugruecknahme.errors;
						break;
					// Schadensereignis
					case 5:
						blnValidation = await this.$refs.validationObserverSchadensereignis.validate();
						arrMessages = this.$refs
							.validationObserverSchadensereignis.errors;

						// Bei Schadensereignis auch die Schäden mitschicken
						this.data.damage = this.vehicleData.damage;

						break;
				}

				if (!blnValidation) {
					new AqToast().showValidationErrors(
						arrMessages,
						'Nicht alle Pflichtfelder wurden ausgefüllt',
					);
				} else {
					saveTask(this.data).then(response => {
						if (response.data.status) {
							new AqToast().callToast(
								'Task wurde erfolgreich angelegt',
								'success',
							);

							this.$bvModal.hide('newTaskModal');
						} else {
							new AqToast().callToast(
								'Eintrag konnte nicht gespeichert werden: ' +
									response.data.message,
								'error',
							);
						}
					});
				}
			}
		},
		loadData() {
			this.isLoading = true;

			fetchVehicle(this.data.aqe_vehicle_id)
				.then(response => {
					if (response.data.status === true) {
						this.vehicleData = response.data.data;
					} else {
						new AqToast().callToast(response.message);
					}
				})
				.catch(error => {
					new AqToast().callToast(error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
	},
	watch: {
		'data.adm_category_id_type': function() {
			this.resetData();
		},
	},
};
</script>

<style scoped></style>
