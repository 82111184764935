<template>
	<v-app style="background:none;">
		<router-view></router-view>
	</v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@fortawesome/fontawesome-pro/css/all.css';

// Main demo style scss
@import 'assets/sass/style.vue';
</style>
<style>
.v-icon.v-icon.fa,
.v-icon.v-icon.fad,
.v-icon.v-icon.fas,
.v-icon.v-icon.far {
	font-size: 16px !important;
}
.v-label {
	margin-bottom: 0;
}
.v-btn.primary.v-btn--active .v-btn__content {
	color: #fff;
}
.v-btn.primary.v-btn--active .v-btn__content .v-icon {
	color: #fff !important;
}
.v-card .v-card__text {
	font-size: 14px;
}
</style>
<script>
export default {
	name: 'App',
	components: {},
};
</script>
