var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Fahrzeugnummer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Fahrzeugnummer","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-vehicle-nr","maxlength":"100"},model:{value:(_vm.vehicle.vehicle_nr),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_nr", $$v)},expression:"vehicle.vehicle_nr"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Kennzeichen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Kennzeichen","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-number_plate","maxlength":"30"},model:{value:(_vm.vehicle.number_plate),callback:function ($$v) {_vm.$set(_vm.vehicle, "number_plate", $$v)},expression:"vehicle.number_plate"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Kurzbezeichnung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Kurzbezeichnung","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-small-description"},model:{value:(_vm.vehicle.small_description),callback:function ($$v) {_vm.$set(_vm.vehicle, "small_description", $$v)},expression:"vehicle.small_description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Art","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Art","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-vehicle-type","options":_vm.formData.arrVehicleTypes},model:{value:(_vm.vehicle.vehicle_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_type", $$v)},expression:"vehicle.vehicle_type"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Marke","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Marke","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-marke"},model:{value:(_vm.vehicle.marke),callback:function ($$v) {_vm.$set(_vm.vehicle, "marke", $$v)},expression:"vehicle.marke"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Modell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Modell","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-modell"},model:{value:(_vm.vehicle.modell),callback:function ($$v) {_vm.$set(_vm.vehicle, "modell", $$v)},expression:"vehicle.modell"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Fahrgestellnummer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Fahrgestellnummer","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-vin","maxlength":"17"},model:{value:(_vm.vehicle.vin),callback:function ($$v) {_vm.$set(_vm.vehicle, "vin", $$v)},expression:"vehicle.vin"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Baujahr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Baujahr","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-manufacture-year","maxlength":"4","max":"9999","min":"1900","type":"number"},model:{value:(_vm.vehicle.manufacture_year),callback:function ($$v) {_vm.$set(_vm.vehicle, "manufacture_year", $$v)},expression:"vehicle.manufacture_year"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Besitzart","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Besitzart","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-ownership-type","options":_vm.formData.arrOwnershipTypes},model:{value:(_vm.vehicle.ownership_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "ownership_type", $$v)},expression:"vehicle.ownership_type"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Anhängerkupplung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Anhängerkupplung","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-trailer-hitch","options":_vm.formData.arrYesNo},model:{value:(_vm.vehicle.trailer_hitch),callback:function ($$v) {_vm.$set(_vm.vehicle, "trailer_hitch", $$v)},expression:"vehicle.trailer_hitch"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Nächstes Service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Nächstes Service","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-next-service","type":"date"},model:{value:(_vm.vehicle.next_service),callback:function ($$v) {_vm.$set(_vm.vehicle, "next_service", $$v)},expression:"vehicle.next_service"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Nächste §57a","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Nächste §57a","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-next-p57avalidation","type":"date"},model:{value:(_vm.vehicle.next_p57avalidation),callback:function ($$v) {_vm.$set(_vm.vehicle, "next_p57avalidation", $$v)},expression:"vehicle.next_p57avalidation"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Versicherung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Versicherung","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-insurance","maxlength":"100"},model:{value:(_vm.vehicle.insurance),callback:function ($$v) {_vm.$set(_vm.vehicle, "insurance", $$v)},expression:"vehicle.insurance"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Polizze","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Polizze","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-insurance-policy","maxlength":"100"},model:{value:(_vm.vehicle.insurance_policy),callback:function ($$v) {_vm.$set(_vm.vehicle, "insurance_policy", $$v)},expression:"vehicle.insurance_policy"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Versicherungsgesellschaft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Versicherungsgesellschaft","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-insurance-company","maxlength":"100"},model:{value:(_vm.vehicle.insurance_company),callback:function ($$v) {_vm.$set(_vm.vehicle, "insurance_company", $$v)},expression:"vehicle.insurance_company"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Fahrer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Fahrer","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-driver-id"},model:{value:(_vm.vehicle.driver_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "driver_id", $$v)},expression:"vehicle.driver_id"}},_vm._l((_vm.formData.arrDrivers),function(employee){return _c('b-form-select-option',{key:employee.id,attrs:{"value":employee.value}},[_vm._v(_vm._s(employee.text))])}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Verwendungszweck","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Verwendungszweck","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-purpose","maxlength":"100"},model:{value:(_vm.vehicle.purpose),callback:function ($$v) {_vm.$set(_vm.vehicle, "purpose", $$v)},expression:"vehicle.purpose"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"HD-Aggregat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"HD-Aggregat","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-hp-unit","maxlength":"100"},model:{value:(_vm.vehicle.hp_unit),callback:function ($$v) {_vm.$set(_vm.vehicle, "hp_unit", $$v)},expression:"vehicle.hp_unit"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"TV-Technik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"TV-Technik","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-tv-technic","maxlength":"100"},model:{value:(_vm.vehicle.tv_technic),callback:function ($$v) {_vm.$set(_vm.vehicle, "tv_technic", $$v)},expression:"vehicle.tv_technic"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }