import Vue from 'vue';
import { BootstrapVue, IconsPlugin, BVToastPlugin } from 'bootstrap-vue';

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//Boostrap Toasts
Vue.use(BVToastPlugin);
