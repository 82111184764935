<template>
	<b-modal
		no-close-on-backdrop
		size="xl"
		id="signatureModal"
		:title="modalData.description"
	>
		<b-container fluid id="signatureModalContainer">
			<b-row>
				<b-col cols="12" class="border" v-b-visible="calcHeight">
					<VueSignaturePad
						:width="'100%'"
						:height="this.height + 'px'"
						ref="signaturePad"
						:options="{
							onBegin: () => {
								$refs.signaturePad.resizeCanvas();
							},
						}"
					/>
				</b-col>
			</b-row>
		</b-container>

		<template #modal-footer="{ ok, cancel }">
			<b-button variant="secondary" @click="cancel">Abbrechen</b-button
			>&nbsp;
			<b-button variant="primary" @click="save()"
				><em class="fad fa-save"></em>&nbsp;Unterschrift
				übernehmen</b-button
			>
		</template>
	</b-modal>
</template>

<script>
import Vue from 'vue';
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

export default {
	name: 'UnterschriftModalComponent',
	props: {
		modalData: Object,
	},
	data() {
		return {
			height: 250,
		};
	},
	methods: {
		calcHeight(isVisible) {
			if (isVisible) {
				let clientWidth = document.getElementById(
					'signatureModalContainer',
				).clientWidth;
				this.height = parseInt(clientWidth / 5);
			}
		},
		save() {
			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

			if (!isEmpty) {
				this.modalData.signatureImageData = data;

				this.$bvModal.hide('signatureModal');

				this.$emit('changed-signature', this.modalData);
			}
		},
	},
};
</script>

<style scoped></style>
