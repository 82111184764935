<template>
	<div>
		<b-row class="py-5">
			<h4>Schäden am Fahrzeug</h4>
		</b-row>
		<b-row class="border-top">
			<b-col cols="12" md="6" class="py-0 border-right align-center">
				<ImageMark
					:imageSrc="vehicleImage"
					:pointList="this.vehicleData.damage"
					:point-style="{}"
					@pointClick="handlePointClick"
					@add="handleAdd"
				></ImageMark>
			</b-col>
			<b-col cols="12" md="6" class="py-0 border-right">
				<b-row>
					<b-col cols="12">
						<span
							>Sichtbare Mängel/Beschädigungen außen: (auch
							Schäden an der Windschutzscheibe!)</span
						>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-row v-if="this.vehicleData.damage.length === 0">
							<b-col cols="12">
								<b-alert variant="success" show>
									Noch keine Schäden vorhanden.
								</b-alert>
							</b-col>
						</b-row>
						<div v-if="this.vehicleData.damage.length > 0">
							<b-row
								v-for="point in this.vehicleData.damage"
								v-bind:key="point.id"
							>
								<b-col cols="12">
									<ValidationProvider
										v-slot="{ errors, state, classes }"
										:name="'Schaden ' + point.id"
										rules="required"
									>
										<AqFormGroup
											:label="'Schaden ' + point.id"
											:boolRequired="true"
											:state="state"
										>
											<b-form-input
												:id="'input-damage-' + point.id"
												v-model="point.comment"
												:class="classes"
												maxlength="255"
											/>
											<span
												class="text-danger"
												v-show="errors[0]"
												>{{ errors[0] }}</span
											>
										</AqFormGroup>
									</ValidationProvider>
								</b-col>
							</b-row>
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-row>
			<b-row class="border-top">
				<b-col cols="12">
					<ValidationProvider
						v-slot="{ errors, state, classes }"
						name="Sonstige Bemerkungen"
						rules=""
					>
						<b-form-group
							id="fieldset-bemerkungen"
							label-size="sm"
							label-cols-sm="4"
							label-cols-lg="3"
							label="Sonstige Bemerkungen: "
							:state="state"
							label-for="input-description"
							label-class="my-auto"
							class="my-0"
						>
							<b-form-textarea
								id="input-description"
								rows="3"
								v-model="data.comment"
								:class="classes"
							/>
							<span class="text-danger" v-show="errors[0]">{{
								errors[0]
							}}</span>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</b-row>
		</b-row>
	</div>
</template>

<script>
import ImageMark from 'vue-image-mark';
import AqFormGroup from '@/view/project/components/AqFormGroup';

export default {
	name: 'FahrzeugschaedenComponent',
	components: { ImageMark, AqFormGroup },
	props: {
		data: Object,
		vehicleData: Object,
	},
	methods: {
		handleAdd(point) {
			point.comment = '';
			point.num = point.id;
			point.active = 1;
			point.fixed_at = null;
			point.aqe_vehicle_protocol_id = null;
			this.vehicleData.damage.push(point);
		},
		handlePointClick(point) {
			if (point.aqe_vehicle_protocol_id === null) {
				this.$bvModal
					.msgBoxConfirm('Schaden löschen?', {
						okTitle: 'Ja',
						cancelTitle: 'Abbrechen',
					})
					.then(value => {
						if (value === true) {
							this.vehicleData.damage.forEach((value, index) => {
								if (value.id === point.id) {
									this.vehicleData.damage.splice(index, 1);
								}
							});
							let cnt = 1;
							this.vehicleData.damage.forEach((value, index) => {
								this.vehicleData.damage[index].id = cnt;
								this.vehicleData.damage[index].num = cnt;
								cnt++;
							});
						}
					});
			}
		},
	},
	computed: {
		vehicleImage() {
			return process.env.BASE_URL + 'assets/img/vehicle_protocoll.png';
		},
	},
};
</script>

<style scoped></style>
