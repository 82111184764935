var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.employmentdata)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Personalnummer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Personalnummer","labelcolssm":"4","state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-staff_number"},model:{value:(_vm.data.employmentdata.staff_number),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "staff_number", $$v)},expression:"data.employmentdata.staff_number"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,130612660)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Vorgesetzte(r)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Vorgesetzte(r)","labelcolssm":"4","state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-geschlecht","options":_vm.supervisors},model:{value:(_vm.data.employmentdata.supervisor_id),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "supervisor_id", $$v)},expression:"data.employmentdata.supervisor_id"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2685674982)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Standort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Standort","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-branchoffice","options":_vm.branchoffices},model:{value:(_vm.data.branchoffice.id),callback:function ($$v) {_vm.$set(_vm.data.branchoffice, "id", $$v)},expression:"data.branchoffice.id"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2682978960)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Abteilung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Abteilung","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-companyscope","options":_vm.companyscopes},model:{value:(_vm.data.employmentdata.company_scope_id),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "company_scope_id", $$v)},expression:"data.employmentdata.company_scope_id"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3457626567)})],1)],1),_c('b-row',[_c('hr')]),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Dienstverhältnis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Dienstverhältnis","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-employmentid","options":_vm.employments},model:{value:(_vm.data.employmentdata.emplyoment_id),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "emplyoment_id", $$v)},expression:"data.employmentdata.emplyoment_id"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,790641955)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Wochenstunden","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Wochenstunden","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-hours_per_week"},model:{value:(_vm.data.employmentdata.hours_per_week),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "hours_per_week", $$v)},expression:"data.employmentdata.hours_per_week"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1891818508)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Vertragsbeginn","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Vertragsbeginn","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"example-objDate_employment_start","type":"date"},model:{value:(
								_vm.data.employmentdata.employment_start_formatted
							),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "employment_start_formatted", $$v)},expression:"\n\t\t\t\t\t\t\t\tdata.employmentdata.employment_start_formatted\n\t\t\t\t\t\t\t"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3731653884)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Vertragsende","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
							var state = ref.state;
							var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Vertragsende","labelcolssm":"4","state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"example-objDate_employment_end","type":"date"},model:{value:(_vm.data.employmentdata.employment_end_formatted),callback:function ($$v) {_vm.$set(_vm.data.employmentdata, "employment_end_formatted", $$v)},expression:"data.employmentdata.employment_end_formatted"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2519571801)})],1)],1),_c('b-row',{staticClass:"pt-4 pl-4"},[_c('blockquote',{staticClass:"blockquote"},[_c('p',{staticClass:"mb-0"},[_vm._v("Fähigkeiten")])])]),_c('b-row',_vm._l((this.data.skillswerte),function(objSkill){return _c('b-col',{key:objSkill.value,attrs:{"cols":"6"}},[_c('b-form-checkbox',{attrs:{"value":"true","unchecked-value":"false"},model:{value:(objSkill.checked),callback:function ($$v) {_vm.$set(objSkill, "checked", $$v)},expression:"objSkill.checked"}},[_vm._v(" "+_vm._s(objSkill.text)+" ")])],1)}),1),_c('b-row',[_c('b-col',[_c('AqFormGroup',{attrs:{"label":"Spezielle Fähigkeiten","labelclass":"mt-0"}},[_c('b-form-input',{attrs:{"id":"input-skills_1"},model:{value:(_vm.data.privatedata.skill_1),callback:function ($$v) {_vm.$set(_vm.data.privatedata, "skill_1", $$v)},expression:"data.privatedata.skill_1"}}),_c('br'),_c('b-form-input',{attrs:{"id":"input-skills_2"},model:{value:(_vm.data.privatedata.skill_2),callback:function ($$v) {_vm.$set(_vm.data.privatedata, "skill_2", $$v)},expression:"data.privatedata.skill_2"}}),_c('br'),_c('b-form-input',{attrs:{"id":"input-skills_3"},model:{value:(_vm.data.privatedata.skill_3),callback:function ($$v) {_vm.$set(_vm.data.privatedata, "skill_3", $$v)},expression:"data.privatedata.skill_3"}}),_c('br')],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }