/**
 *A Controller Class for Roles
 */
import Vue from 'vue';

class Roles extends Vue.Aq.Classes.Controller {
	apiUrl() {
		//return "resources/auth/usergroups/"
		return 'resources/aqe/people/';
	}

	routeNavKey() {
		return 'administration';
	}
}
export default Roles;
