class AqDtOptionsBuilder {
	constructor() {
		this.ajax = {};
		this.dom =
			"Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'pl>>";
		this.responsive = true;
		this.processing = true;
		this.searching = true;
		this.searchDelay = 1500;
		this.destroy = true;
		this.ordering = true;
		this.lengthChange = true;
		this.serverSide = true;
		this.fixedHeader = true;
		this.saveState = true;
		this.language = {
			url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json',
		};
	}

	setAjax(strUrl) {
		this.ajax = {
			url: strUrl,
			xhrFields: {
				withCredentials: true,
			},
			dataSrc: response => {
				return response.data;
			},
		};
		return this;
	}

	buildOptions() {
		return this;
	}
}

export { AqDtOptionsBuilder };
