<template>
	<div>
		<view-list
			:forList="forList"
			:forDelete="forDelete"
			:forTable="forTable"
		>
			<template v-slot:deleteLayout>
				<b-row>
					<div class="col-auto">
						{{ forDelete.passEntry.id }}
					</div>
					<div class="col">
						{{ forDelete.passEntry.marke }}
						{{ forDelete.passEntry.modell }} <br />{{
							forDelete.passEntry.vin
						}}
					</div>
				</b-row>
			</template>
			<template v-slot:item.customactions="{ item }">
				<v-btn
					icon
					color="success"
					@click="openNewTaskModal(item)"
					:title="
						'Task hinzufügen für ' + item.marke + ' ' + item.modell
					"
				>
					<v-icon>far fa-plus</v-icon>
				</v-btn>
			</template>
		</view-list>

		<NewTaskModalComponent :modal-data="this.newTaskModalData" />
	</div>
</template>

<script>
import Vue from 'vue';
import NewTaskModalComponent from './components/NewTaskModalComponent';

const Controller = Vue.Aq.Modules.Fahrzeugmanagement();
const ViewList = Controller.newList();

export default {
	name: 'Fahrzeugverwaltung',
	components: { ...ViewList.initComponents(), NewTaskModalComponent },
	mounted() {
		Controller.setBreadcrump('index');
	},
	data() {
		return {
			...ViewList.forTable({
				headers: [
					ViewList.Column()
						.columnVehicleNumber()
						.build(),
					ViewList.Column()
						.columnBrand()
						.build(),
					ViewList.Column()
						.columnModel()
						.build(),
					ViewList.Column()
						.columnVin()
						.build(),
					ViewList.Column()
						.columnSmallDescription()
						.build(),
					ViewList.Column().columnActions(),
				],
				forApi: function(apiParams) {
					return apiParams;
				},
			}).initData(this),
			newTaskModalData: {},
		};
	},
	methods: {
		openNewTaskModal(item) {
			this.newTaskModalData = item;

			this.$nextTick(() => {
				this.$bvModal.show('newTaskModal');
			});
		},
	},
	computed: ViewList.initComputed(),
};
</script>
