/**
 *A Controller Class for Benutzer
 */
import Vue from 'vue';

class Benutzer extends Vue.Aq.Classes.Controller {
	apiUrl() {
		return 'resources/auth/user/';
	}

	routeNavKey() {
		return 'administration';
	}
}
export default Benutzer;
