/**
 *A loader for Inputs
 * @author E.Neuwirth
 */
import i18n from '@/core/plugins/vue-i18n';

class AqInputs {
	constructor() {
		this.input = {
			label: 'Missing label',
			required: false,
			errorField: null,
		};
	}

	/* helper ----------------------------------------------- start*/

	_addSetting(key, value) {
		if (typeof this.input.settings === 'undefined') {
			this.input.settings = {};
		}
		this.input.settings[key] = value;
	}

	_setType(type) {
		this.input.type = type;
		return this;
	}

	/* helper ----------------------------------------------- end*/

	/* types ----------------------------------------------- start*/

	asAutocomplete() {
		return this._setType('autocomplete');
	}

	asSwitch() {
		return this._setType('switch');
	}

	asCheckbox() {
		return this._setType('checkbox');
	}

	asCheckboxes() {
		return this._setType('checks');
	}

	asRadio() {
		return this._setType('radio');
	}
	asRadioColumn() {
		return this._setType('radio')._addSetting('column', true);
	}

	asRadioBtn() {
		return this._setType('radio-btn');
	}

	asTextarea() {
		return this._setType('textarea');
	}

	asColor() {
		return this._setType('color');
	}

	asDate() {
		return this._setType('date');
	}

	asDates() {
		return this._setType('dates');
	}

	asDateRange() {
		return this._setType('date-range');
	}

	asEmail() {
		return this._setType('email')
			.icon('fa-fw fa-envelope')
			.translateLabel('fields.email');
	}

	asTime() {
		return this._setType('time').icon('fa-fw fa-clock');
	}

	asFile() {
		return this._setType('file').translatePlaceholder('placeholder.file');
	}

	asFiles() {
		return this._setType('files').translatePlaceholder('placeholder.files');
	}

	asSelect() {
		return this._setType('select');
	}

	/* types ----------------------------------------------- end*/

	/* rules ----------------------------------------------- start*/

	required() {
		this.input.required = true;
		return this;
	}

	rules(rules) {
		this.input.rules = rules;
		return this;
	}

	errorField(field) {
		this.input.errorField = field;
		return this;
	}

	/* rules ----------------------------------------------- end*/

	/* style ----------------------------------------------- start*/

	label(label) {
		this.input.label = label;
		return this;
	}
	translateLabel(pattern) {
		return this.label(i18n.tc(pattern));
	}

	placeholder(placeholder) {
		this.input.placeholder = placeholder;
		return this;
	}
	translatePlaceholder(pattern) {
		return this.placeholder(i18n.tc(pattern));
	}

	icon(icon) {
		this.input.icon = icon;
		return this;
	}

	hint(hint) {
		this.input.hint = hint;
		return this;
	}

	/* style ----------------------------------------------- end*/

	/* items ----------------------------------------------- start*/

	items(options) {
		this.input.options = options;
		return this;
	}

	/* items ----------------------------------------------- end*/

	/* settings ----------------------------------------------- start*/

	multiple() {
		this._addSetting('multiple', true);
		return this;
	}

	withPromise(promise, valueKey) {
		this._addSetting('withPromise', promise);
		let itemValue = 'id';
		if (typeof valueKey !== 'undefined') {
			itemValue = valueKey;
		}
		this._addSetting('itemValue', itemValue);
		return this;
	}

	withObject() {
		this._addSetting('withObject', true);
		return this;
	}

	itemValue(key) {
		this._addSetting('itemValue', key);
		return this;
	}

	itemText(key) {
		this._addSetting('itemText', key);
		return this;
	}

	itemDescription(key) {
		this._addSetting('itemDescription', key);
		return this;
	}

	onChange(changeFunction) {
		this._addSetting('onChange', changeFunction);
		return this;
	}

	color(color) {
		this._addSetting('color', color);
		return this;
	}

	/* settings ----------------------------------------------- end*/

	/* states ----------------------------------------------- start*/

	isDisabled() {
		this.input.disabled = true;
		return this;
	}

	isRead() {
		this.input.readonly = true;
		return this;
	}

	/* states ----------------------------------------------- end*/

	build() {
		return this.input;
	}
}
export default AqInputs;
