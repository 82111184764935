var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"pt-4 mx-4"},[_c('blockquote',{staticClass:"blockquote"},[_c('p',{staticClass:"mb-0"},[_vm._v("Neuen Eintrag anlegen")])])]),_c('b-row',{staticClass:"pt-4 mx-4"},[_c('b-col',{staticClass:"py-0"},[_c('ValidationProvider',{attrs:{"name":"Bemerkung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Bemerkung","boolRequired":true,"labelcolssm":"2","state":state}},[_c('b-form-textarea',{class:classes,attrs:{"id":"input-description","rows":"3"},model:{value:(_vm.newBaseAnnotation.description),callback:function ($$v) {_vm.$set(_vm.newBaseAnnotation, "description", $$v)},expression:"newBaseAnnotation.description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mx-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Erinnerung","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Erinnerung am","labelcolssm":"4","state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"example-reminder_date","type":"date"},model:{value:(_vm.newBaseAnnotation.reminder_date),callback:function ($$v) {_vm.$set(_vm.newBaseAnnotation, "reminder_date", $$v)},expression:"newBaseAnnotation.reminder_date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Kategorie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Kategorie","boolRequired":true,"labelcolssm":"4","state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-annotationtype","options":_vm.arrAnnotationTypes},model:{value:(_vm.newBaseAnnotation.annotationTypeCategoryId),callback:function ($$v) {_vm.$set(_vm.newBaseAnnotation, "annotationTypeCategoryId", $$v)},expression:"newBaseAnnotation.annotationTypeCategoryId"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"pb-5"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.resetAnnotation}},[_vm._v("Zurücksetzen")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveAnnotation}},[_c('em',{staticClass:"fad fa-save"}),_vm._v(" Hinzufügen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }