import ApiService from '@/core/services/api.service';

let fetchVehicle = async id => {
	return await ApiService.get('resources/aqe/vehicle/item/', {
		connections: ['damage'],
		'where[id]': id,
		'no_convert': 1
	});
};

let saveVehicle = async objData => {
	return await ApiService.put('resources/aqe/vehicle/item/', objData);
};

let saveTask = async objData => {
	return await ApiService.put('resources/aqe/vehicleprotocol/item/', objData);
};

let fetchVehicleEquipment = async () => {
	return await ApiService.get('resources/aqe/vehicleequipment/items/');
};

let fetchVehicleProtocols = async vehicleId => {
	return await ApiService.get(
		'resources/aqe/vehicleprotocol/items/?connections[]=damage&connections[]=equipmentprotocol:equipment&where[aqe_vehicle_id]=' +
			vehicleId,
	);
};

export {
	fetchVehicle,
	saveVehicle,
	saveTask,
	fetchVehicleEquipment,
	fetchVehicleProtocols,
};
