<template>
	<div>
		<GrunddatenComponent :data="this.data" />

		<FahrzeugschaedenComponent
			:data="this.data"
			:vehicle-data="this.vehicleData"
		/>

		<UnterschriftComponent
			:signature-fields="this.signatureFields"
			class="border-top"
			@changed-signature="changedSignature"
		/>
	</div>
</template>

<script>
import GrunddatenComponent from './GrunddatenComponent.vue';
import FahrzeugschaedenComponent from './FahrzeugschaedenComponent.vue';
import UnterschriftComponent from './UnterschriftComponent.vue';

export default {
	name: 'SchadensereignisTaskComponent',
	components: {
		GrunddatenComponent,
		FahrzeugschaedenComponent,
		UnterschriftComponent,
	},
	props: {
		data: Object,
		vehicleData: Object,
	},
	data() {
		return {
			signatureFields: [
				{
					name: 'signature_acceptor',
					description: 'Unterschrift Fahrzeugnutzer',
					cols: 6,
					disabled: false,
					signatureImageData: null,
				},
				{
					name: 'signature_controller',
					description: 'Unterschrift Prüfer',
					cols: 6,
					disabled: false,
					signatureImageData: null,
				},
			],
		};
	},
	methods: {
		changedSignature(event) {
			this.$emit('changed-signature', {
				event: event,
				signatureFields: this.signatureFields,
			});
		},
	},
};
</script>

<style scoped></style>
