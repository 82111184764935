<template>
	<div v-if="data && data.privatedata">
		<b-row class="pt-4 pl-4">
			<blockquote class="blockquote">
				<p class="mb-0">Neues Dokument hinzufügen</p>
			</blockquote>
		</b-row>
		<b-row>
			<b-col>
				<b-form-file
					v-model="newFile"
					:state="Boolean(newFile)"
					placeholder="Datei auswählen oder hineinziehen..."
					drop-placeholder="File hier ablegen..."
					@change="uploadFile"
				></b-form-file>
			</b-col>
		</b-row>

		<b-row class="pt-5 pl-4">
			<blockquote class="blockquote">
				<p class="mb-0">Mitarbeiter-Dokumente</p>
			</blockquote>
		</b-row>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table
						class="table table-head-custom table-vertical-center"
					>
						<thead>
							<tr>
								<th width="40px">&nbsp;</th>
								<th>Beschreibung</th>
								<th>Kommentar</th>
								<th width="150px">Bearbeitung</th>
								<th class="text-right" width="80px">
									Aktionen
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="objFile in data.mitarbeiterfiles"
								:key="objFile.id"
							>
								<td>
									<div
										class="symbol symbol-50 symbol-light mt-1"
									>
										<span class="symbol-label">
											<em
												:title="objFile.filename"
												:class="
													getIcon(objFile.filetype)
												"
											></em>
										</span>
									</div>
								</td>
								<td>
									<input
										type="text"
										class="form-control"
										v-model="objFile.title"
										maxlength="100"
									/>
								</td>
								<td>
									<textarea
										type="text"
										class="form-control"
										v-model="objFile.comment"
										rows="1"
									/>
								</td>
								<td>{{ objFile.updated | limitTo(16) }}</td>
								<td class="text-right">
									<a
										class="btn btn-icon btn-light btn-hover-primary btn-sm pr-1"
										v-on:click="downloadDokument(objFile)"
										title="Herunterladen"
										><em class="fad fa-download"></em
									></a>
									<a
										class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
										v-on:click="removeDokument(objFile)"
										title="Löschen"
										><em class="fad fa-trash-alt"></em
									></a>
								</td>
							</tr>
							<!--<tr ng-show="!(objItem.mitarbeiterfiles).length">
              <td colspan="5"><em><?= _('Keine Dokumente zum Mitarbeiter vorhanden') ?></em></td>
            </tr>
            -->
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import ApiService from '@/core/services/api.service';
export default {
	name: 'TabDokumente',
	components: {},
	data() {
		return {
			newFile: null,
			file: null,
		};
	},
	props: {
		data: Object,
	},
	mounted() {},
	methods: {
		uploadFile(event) {
			ApiService.uploadFile(event, this.data).then(() => {
				//new AqToast().callToast();
				this.$emit('afterSaveDocument');
				this.newFile = null;
			});
		},
    downloadDokument(file) {
      ApiService.downloadFile(file.id, file.pivot.people_id);
    },
		getIcon(strMimeType) {
			switch (strMimeType) {
				case 'application/pdf':
					return 'fa-2x fad fa-file-pdf';
				case 'image/png':
				case 'image/x-png':
				case 'image/jpeg':
				case 'image/pjpeg':
					return 'fa-2x fad fa-image';
				case 'application/msword':
				case 'application/vnd.ms-office':
				case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
					return 'fa-2x fad fa-file-word';
				case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				case 'application/vnd.ms-excel':
				case 'application/msexcel':
				case 'application/x-msexcel':
				case 'application/x-ms-excel':
				case 'application/x-excel':
				case 'application/x-dos_ms_excel':
				case 'application/xls':
					return 'fa-2x fad fa-file-excel';

				case 'application/zip':
				case 'application/x-zip':
				case 'application/x-zip-compressed':
				case 'application/s-compressed':
				case 'multipart/x-zip':
					return 'fa-2x fad fa-file-archive';

				default:
					'fa-2x fad fa-file';
			}
		},
	},
};
</script>
