/**
 *The Paths of the current projects
 * @author E.Neuwirth
 */

class AqPaths {
	_path_to_build = null;

	capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	validatePath(relative_path) {
		if (typeof relative_path === 'object') {
			relative_path = relative_path.join('/');
		}
		return relative_path.replace(/^\/+|\/+$/g, '');
	}

	checkRelative(relative_path) {
		relative_path = this.validatePath(relative_path);
		let parts = relative_path.split(/[/ ]+/);
		for (let index in parts) {
			this._path_to_build.push(parts[index]);
		}
	}

	checkChain(part, relative_path) {
		if (this._path_to_build === null) {
			this._path_to_build = new Array();
		}
		if (part !== null) {
			this._path_to_build.push(part);
		}

		if (typeof relative_path !== 'undefined') {
			this.checkRelative(relative_path);
		}

		return this;
	}

	finish() {
		if (this._path_to_build !== null) {
			let relative_path = this._path_to_build.join('/');
			this._path_to_build = null;
			return relative_path;
		}
		return false;
	}

	get() {
		return this.finish();
	}

	file(filename) {
		return this.checkChain(null, filename);
	}

	layout(relative_path) {
		return this.checkChain('layout', relative_path);
	}

	input(relative_path) {
		return this.checkChain('inputs', relative_path);
	}

	module(relative_path) {
		return this.checkChain('modules', relative_path);
	}

	project(relative_path) {
		return this.checkChain('project', relative_path);
	}

	component(relative_path) {
		return this.checkChain('components', relative_path);
	}

	partial(relative_path) {
		return this.checkChain('partials', relative_path);
	}

	error(relative_path) {
		return this.checkChain('error', relative_path);
	}
}
export default AqPaths;
