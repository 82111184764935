/**
 * General Class for the creation of forms
 * @author E.Neuwirth
 */
import Vue from 'vue';

class AqForm {
	/**
	 * Called from the extended module from src\core\project\modules\AqController.js
	 * gets the object from where it's called from
	 */
	constructor(Controller) {
		//the controller from the current module
		this._objController = Controller;
	}

	/**
	 * is called to init the data for the current class.
	 */
	initClass(vueForm) {
		///the vue Form object
		this._vueForm = vueForm;

		///params for the current Form
		this.params = {
			primaryKey: false,
			isEdit: false,
		};

		///The available options for the current Form
		this.options = {
			submit: {},
		};

		//init the temp data for chains
		this.temp_data = {
			form: {},
			item: {},
			itemAdd: {},
			itemEdit: {},
			submit: {},
			inputs: {},
			forApi: null,
		};

		//the available form inputs (set via setInputs)
		this.inputs = {};
	}

	/**
	 * Calls the Controller from the current module
	 */
	Controller() {
		return this._objController;
	}

	/**
	 * Returns a new AqError object
	 * from the Controller and sets the current object to it
	 */
	Error() {
		return this.Controller()
			.Error()
			.withClass(this, 'form');
	}

	/**
	 * Calls the Vue Object
	 */
	Form() {
		return this._vueForm;
	}

	/**
	 * Returns a new AqInput Object from the Controller()
	 */
	Input() {
		return this.Controller().Input();
	}

	/**
	 *Returns true if validateRoute sets the _is_edit to true
	 */
	isEdit() {
		return this.params.isEdit;
	}

	/**
	 *Returns the primaryKey if validateRoute is true and Data has been loaded
	 */
	primaryKey() {
		return this.params.primaryKey;
	}

	/**
	 * Loads the default compenents for the Form
	 */
	initComponents(additionalComponents) {
		let viewForm = Vue.Aq.Loader.layout()
			.module('ViewForm')
			.get();
		let AqFormGroup = Vue.Aq.Loader.project()
			.component('AqFormGroup')
			.get();
		let AqInputBuilder = Vue.Aq.Loader.layout()
			.input('BuilderVuetify')
			.get();

		let formComponents = {
			'view-form': viewForm,
			'input-builder': AqInputBuilder,
			AqFormGroup,
		};

		if (typeof additionalComponents === 'object') {
			formComponents = { ...formComponents, ...additionalComponents };
		}

		return formComponents;
	}

	/**
	 * Validates the current route params
	 */
	validateRoute(vueForm) {
		this.initClass(vueForm);

		switch (vueForm.$route.params.view) {
			case 'new':
				break;

			case 'edit':
				if (typeof vueForm.$route.params.viewKey !== 'undefined') {
					this.params.isEdit = true;
					this.params.primaryKey = vueForm.$route.params.viewKey;
				} else {
					this.Error().critical('no-primary-key');
				}
				break;
		}
	}

	/**
	 * chainable function for the initData function
	 * which extends the data from formData()
	 */
	forForm(dataToExtend) {
		this.temp_data.form = dataToExtend;
		return this;
	}

	/**
	 * The current Data and Functions for the Form
	 */
	formData() {
		let obj = this;

		let formData = {
			cancelText: obj
				.Controller()
				.Translate()
				.tc('general.cancel'),
			saveText: obj
				.Controller()
				.Translate()
				.tc('general.save'),
			savingText: obj
				.Controller()
				.Translate()
				.tc('general.savingData'),
			loadingText: obj
				.Controller()
				.Translate()
				.tc('general.loadingData'),
			withSidebar: false,
			loadingActive: false,
			isLoading: false,
			isSaving: false,
			onSubmit: function() {
				obj.onSubmit();
			},
			onCancel: function() {
				obj.Controller().route('index');
			},
		};

		if (typeof this.temp_data.form === 'object') {
			formData = { ...formData, ...this.temp_data.form };
			this.temp_data.form = null;
		}

		return formData;
	}

	/**
	 * Sets the current Form into saving mode
	 */
	isSaving() {
		this.Form().forForm.loadingActive = true;
		this.Form().forForm.isSaving = true;
	}

	/**
	 * Sets the current Form into loading mode
	 */
	isLoading() {
		this.Form().forForm.loadingActive = true;
		this.Form().forForm.isLoading = true;
	}

	/**
	 * Removes the loading mode
	 */
	loadingDone() {
		this.Form().forForm.loadingActive = false;
		this.Form().forForm.isLoading = false;
		this.Form().forForm.isSaving = false;
	}

	/**
	 * chainable function for the loadData function
	 * where you can pass connections for example
	 */
	forSubmit(dataToExtend) {
		this.temp_data.submit = dataToExtend;
		return this;
	}

	/**
	 * options for the onSubmit function
	 */
	initSubmit() {
		let obj = this;

		let submitOptions = {
			observers: ['Stammdaten'],
			entryCreated: obj.Controller().Translation('entryCreated'),
			entryEdited: obj.Controller().Translation('entryEdited'),
			onSuccess: false,
			beforePut: false,
			validateForm: false,
		};
		if (typeof this.temp_data.submit === 'object') {
			submitOptions = { ...submitOptions, ...this.temp_data.submit };
			this.temp_data.submit = null;
		}
		this.options.submit = submitOptions;
	}

	/**
	 * Adds custom errors to the inputs via. vee-validate
	 */
	setValidationErrors(errors, observer_key) {
		let obj = this;
		if (typeof observer_key === 'undefined') {
			observer_key = 'Stammdaten';
		}
		obj.Form().$refs['observer' + observer_key].setErrors(errors);
	}

	/**
	 * Saves for the current Form
	 */
	onSubmit() {
		let obj = this;
		let availableObservers = {};
		let promises = new Array();

		obj.isSaving();

		//lets check for available observers and create promise for all async functions...
		if (obj.options.submit.observers) {
			for (let observer_index in obj.options.submit.observers) {
				let observer_name =
					obj.options.submit.observers[observer_index];
				let observer_key = 'observer' + observer_name;

				if (typeof obj.Form().$refs[observer_key] !== 'undefined') {
					availableObservers[observer_key] = {
						key: observer_key,
						name: observer_name,
					};
					let validateObserver = async function() {
						return await obj
							.Form()
							.$refs[observer_key].validate()
							.then(response => {
								availableObservers[observer_key][
									'isDone'
								] = response;
								if (!response) {
									obj.Form().$refs[observer_key].setErrors();
								}
							});
					};
					promises.push(validateObserver());
				}
			}
		} else {
			promises.push(true);
		}

		Promise.allSettled(promises).then(() => {
			let error_found = false;

			for (let index in availableObservers) {
				let observer = availableObservers[index];
				if (typeof observer.key !== 'undefined') {
					if (!observer.isDone) {
						error_found = true;
						/*
						obj.Controller().Toast().showValidationErrors(
							errors,
							observer.name
						);*/
					}
				}
			}

			if (typeof obj.options.submit.validateForm === 'function') {
				error_found = obj.options.submit.validateForm(error_found);
			}

			if (!error_found) {
				if (typeof obj.options.submit.beforePut === 'function') {
					obj.options.submit.beforePut();
				}

				obj.Controller()
					.putEntry(obj.Form().data)
					.then(response => {
						obj.loadingDone();

						if (response.data.status === true) {
							let createdEntry = response.data.data;

							let onSucces = function() {
								let toast_message;
								if (obj.isEdit()) {
									toast_message =
										obj.options.submit.entryEdited;
								} else {
									toast_message =
										obj.options.submit.entryCreated;
								}
								obj.Controller()
									.Toast()
									.callToast(toast_message, 'success');

								if (!obj.isEdit()) {
									obj.Controller().routeEntry(
										'edit',
										createdEntry,
									);
								}
							};
							if (
								typeof obj.options.submit.onSuccess ===
								'function'
							) {
								obj.options.submit.onSuccess(
									obj,
									onSucces,
									createdEntry,
								);
							} else {
								onSucces();
							}
						} else {
							obj.Error().critical('put', response);
						}
					})
					.catch(error => {
						obj.loadingDone();
						obj.Error().catched('put', error);
					});
			} else {
				obj.loadingDone();
			}
		});
	}

	/**
	 * The Data for the Vue Component
	 */
	initData() {
		this.initSubmit();
		return {
			forForm: this.formData(),
			data: this.itemData(),
			FormInputs: this.inputFields(),
		};
	}

	/**
	 * the default functions for mounted
	 */
	initMounted() {
		let obj = this;

		if (obj.isEdit()) {
			obj.isLoading();

			let onComplete = function(itemData) {
				if (typeof obj.temp_data.item === 'object') {
					itemData = { ...obj.temp_data.item, ...itemData };
					obj.temp_data.item = null;
				}
				if (typeof obj.temp_data.itemEdit === 'object') {
					itemData = { ...obj.temp_data.itemEdit, ...itemData };
					obj.temp_data.itemEdit = null;
				}
				obj.Form().data = itemData;
				obj.loadingDone();
			};
			obj.loadData(onComplete);
		} else {
			obj.temp_data.item = null;
		}
	}

	/**
	 * chainable function for the initData function
	 * which extends the data from itemData() and from loadData()
	 */
	forItem(dataToExtend) {
		this.temp_data.item = dataToExtend;
		return this;
	}

	/**
	 * chainable function for the initData function
	 * which extends the data after it has been loaded
	 */
	forItemAdd(dataToExtend) {
		this.temp_data.itemAdd = dataToExtend;
		return this;
	}

	/**
	 * chainable function for the initData function
	 * which extends the data after it has been loaded
	 */
	forItemEdit(dataToExtend) {
		this.temp_data.itemEdit = dataToExtend;
		return this;
	}

	/**
	 * The current Item Data
	 */
	itemData() {
		let obj = this;
		let itemData = {};

		if (typeof obj.temp_data.item === 'object') {
			itemData = { ...itemData, ...obj.temp_data.item };
		}
		if (typeof obj.temp_data.itemAdd === 'object') {
			itemData = { ...itemData, ...obj.temp_data.itemAdd };
			obj.temp_data.itemAdd = null;
		}
		return itemData;
	}

	/**
	 * chainable function for the loadData function
	 * where you can pass connections for example
	 */
	forApi(apiFunction) {
		this.temp_data.forApi = apiFunction;
		return this;
	}

	/**
	 * The Data for the Vue Component
	 */
	loadData(onComplete) {
		let obj = this;
		let forApiFunction = null;

		if (typeof obj.temp_data.forApi === 'function') {
			forApiFunction = obj.temp_data.forApi;
			obj.temp_data.forApi = null;
		}

		obj.Controller()
			.loadEntry(obj.params.primaryKey, forApiFunction)
			.then(response => {
				if (response.data.status === true) {
					if (typeof onComplete === 'function') {
						onComplete(response.data.data);
					}
				} else {
					obj.loadingDone();
					obj.Error().critical('get', response);
				}
			})
			.catch(error => {
				obj.loadingDone();
				obj.Error().catched('get', error);
			});
	}

	/**
	 * Sets the inputs which are always available
	 */
	setInputs(inputs) {
		this.inputs = inputs;
		return this;
	}

	/**
	 * chainable function for the inputFields function
	 * where you can pass fields which are only availble during the call
	 */
	forInputs(dataToExtend) {
		this.temp_data.inputs = dataToExtend;
		return this;
	}

	/**
	 * The Available Input Fields for the current Form
	 * Takes the this.inputs as default and extends it with the temp_data.inputs if they are available
	 */
	inputFields() {
		let obj = this;
		let input_fields = obj.inputs;

		if (typeof obj.temp_data.inputs === 'object') {
			input_fields = { ...input_fields, ...obj.temp_data.inputs };
			obj.temp_data.inputs = null;
		}
		return input_fields;
	}

	/**
	 * the default functions for computed
	 */
	initComputed(computed) {
		let defaultComputed = {
			backgroundImage() {
				return process.env.BASE_URL + 'assets/img/bg1.jpg';
			},
		};

		if (typeof computed === 'object') {
			return { ...defaultComputed, ...computed };
		} else {
			return defaultComputed;
		}
	}
}

export default AqForm;
