/**
 *For accessing Modules from everywhere wihtout knowing
 * the the path you can create here a function for them
 * @author E.Neuwirth
 */
import Vue from 'vue';

class AqModules {
	Roles() {
		return Vue.Aq.Loader.controller('roles');
	}
	Benutzer() {
		return Vue.Aq.Loader.controller('benutzer');
	}
	Mitarbeiter() {
		return Vue.Aq.Loader.controller('mitarbeiter');
	}
	Fahrzeugmanagement()
	{
		return Vue.Aq.Loader.controller('fahrzeugmanagement');
	}
};
export default AqModules;
