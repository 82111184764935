var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.text)+":")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v("Profil in Ordnung")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-radio-group',{attrs:{"name":'radio-profile-' + _vm.dataBinding.name},model:{value:(_vm.data[_vm.dataBinding.profile]),callback:function ($$v) {_vm.$set(_vm.data, _vm.dataBinding.profile, $$v)},expression:"data[dataBinding.profile]"}},[_c('b-form-radio',{attrs:{"value":"i.O."}},[_vm._v("i.O")]),_c('b-form-radio',{attrs:{"value":"nicht i.O."}},[_vm._v("nicht i.O")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v("Sichtbare Schäden")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-radio-group',{attrs:{"name":'radio-defect-' + _vm.dataBinding.name},model:{value:(_vm.data[_vm.dataBinding.defect]),callback:function ($$v) {_vm.$set(_vm.data, _vm.dataBinding.defect, $$v)},expression:"data[dataBinding.defect]"}},[_c('b-form-radio',{attrs:{"value":"Ja"}},[_vm._v("Ja")]),_c('b-form-radio',{attrs:{"value":"Nein"}},[_vm._v("Nein")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":'Reifentype - ' + _vm.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Reifentype","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,model:{value:(_vm.data[_vm.dataBinding.type]),callback:function ($$v) {_vm.$set(_vm.data, _vm.dataBinding.type, $$v)},expression:"data[dataBinding.type]"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":'Reifenart - ' + _vm.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Reifenart","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,model:{value:(_vm.data[_vm.dataBinding.kind]),callback:function ($$v) {_vm.$set(_vm.data, _vm.dataBinding.kind, $$v)},expression:"data[dataBinding.kind]"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }