import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PASSWORD = 'setPassword';
export const SET_ERROR = 'setError';

const state = {
	errors: null,
	user: {},
	isAuthenticated: !!JwtService.getToken(),
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise(resolve => {
			ApiService.post('auth/login/item', credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					console.log(response);
					context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			ApiService.get('auth/login/item')
				.then(response => {
					context.commit(SET_AUTH, response.data);
				})
				.catch(response => {
					state.isAuthenticated = false;
					context.commit(SET_ERROR, response.data);
				});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && error.errors) {
			state.errors = error;
		}
		state.isAuthenticated = false;
	},
	[SET_AUTH](state, user) {
		const _userData = user.data;
		state.isAuthenticated = true;
		state.user = _userData;
		state.errors = null;
		JwtService.saveToken(_userData.username);
	},
	[SET_PASSWORD](state, password) {
		state.user.password = password;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = null;
		JwtService.destroyToken();
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
