var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"py-5"},[_c('h4',[_vm._v("Grunddaten")])]),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Datum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Datum","boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":"input-date","type":"date"},model:{value:(_vm.data.date),callback:function ($$v) {_vm.$set(_vm.data, "date", $$v)},expression:"data.date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ausgeber/Prüfer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Ausgeber/Prüfer","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-aqe_people_id_controller"},model:{value:(_vm.data.aqe_people_id_controller),callback:function ($$v) {_vm.$set(_vm.data, "aqe_people_id_controller", $$v)},expression:"data.aqe_people_id_controller"}},_vm._l((_vm.formData.employees),function(employee){return _c('b-form-select-option',{key:employee.id,attrs:{"value":employee.value}},[_vm._v(_vm._s(employee.text))])}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Mitarbeiter (Empfänger)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":"Mitarbeiter (Empfänger)","boolRequired":true,"state":state}},[_c('b-form-select',{class:classes,attrs:{"id":"input-aqe_people_id_acceptor"},model:{value:(_vm.data.aqe_people_id_acceptor),callback:function ($$v) {_vm.$set(_vm.data, "aqe_people_id_acceptor", $$v)},expression:"data.aqe_people_id_acceptor"}},_vm._l((_vm.formData.employees),function(employee){return _c('b-form-select-option',{key:employee.id,attrs:{"value":employee.value}},[_vm._v(_vm._s(employee.text))])}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }