<template>
	<view-list :forList="forList" :forDelete="forDelete" :forTable="forTable">
		<template v-slot:deleteLayout>
			<b-row>
				<div class="col-auto">
					{{ forDelete.passEntry.id }}
				</div>
				<div class="col">
					{{ forDelete.passEntry.username }}
					<br />{{ forDelete.passEntry.person.fullname }}
				</div>
			</b-row>
		</template>
		<template v-slot:item.username="{ item }">
			<i class="fad fa-user"></i> {{ item.username }}
		</template>
		<template v-slot:item.customactions="{ item }">
			<v-btn icon color="warning" :title="item.username">
				<v-icon>far fa-users-cog</v-icon>
			</v-btn>
		</template>
	</view-list>
</template>
<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Benutzer();
const ViewList = Controller.newList();

export default {
	name: 'Benutzer-Index',
	components: ViewList.initComponents(),
	mounted() {
		Controller.setBreadcrump('index');
	},
	data() {
		return ViewList.forTable({
			headers: [
				ViewList.Column()
					.columnID()
					.build(),
				ViewList.Column()
					.columnUsername()
					.build(),
				ViewList.Column()
					.crud('person.')
					.columnFirstName()
					.build(),
				ViewList.Column()
					.crud('person.')
					.columnLastName()
					.build(),
				ViewList.Column()
					.columnActive()
					.build(),
				ViewList.Column().columnActions(),
			],
			forApi: function(apiParams) {
				return apiParams.connection('person');
			},
		}).initData(this);
	},
	computed: ViewList.initComputed(),
};
</script>
