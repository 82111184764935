<template>
	<div v-if="data && data.privatedata">
		<b-row class="pt-4 pl-4">
			<blockquote class="blockquote">
				<p class="mb-0">Vergütung anpassen</p>
			</blockquote>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Grundgehalt"
					rules="required|numeric"
				>
					<AqFormGroup
						label="Grundgehalt"
						:boolRequired="true"
						labelcolssm="3"
						:state="state"
					>
						<b-form-input
							id="input-basesalary"
							v-model="newSalary.base_salary"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Bonus"
					rules="required|numeric"
				>
					<AqFormGroup
						label="Bonus"
						:boolRequired="true"
						labelcolssm="3"
						:state="state"
					>
						<b-form-input
							id="input-bonus"
							v-model="newSalary.bonus"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Provision"
					rules="required|numeric"
				>
					<AqFormGroup
						label="Provision"
						:boolRequired="true"
						labelcolssm="3"
						:state="state"
					>
						<b-form-input
							id="input-fee_agreement"
							v-model="newSalary.fee_agreement"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Son. Vereinbarung"
					rules="required|numeric"
				>
					<AqFormGroup
						label="Son. Vereinbarung"
						:boolRequired="true"
						labelcolssm="3"
						:state="state"
					>
						<b-form-input
							id="input-other_agreement"
							v-model="newSalary.other_agreement"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Gültig seit"
					rules="required"
				>
					<b-form-group
						id="fieldset-verguetung-created"
						label-size="sm"
						label-cols-sm="4"
						label-cols-lg="3"
						label="Gültig seit:"
						:state="state"
						label-for="input-verguetung-created"
						label-class="my-auto"
						class="my-0"
					>
						<b-form-input
							id="example-verguetung-created"
							v-model="newSalary.created"
							:class="classes"
							type="date"
						></b-form-input>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</b-form-group>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="py-0 mx-4">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Bemerkung"
					rules=""
				>
					<b-form-group
						id="fieldset-description"
						label-size="sm"
						label-cols-sm="4"
						label-cols-lg="3"
						label="Bemerkung: "
						:state="state"
						label-for="input-description"
						label-class="my-auto"
						class="my-0"
					>
						<b-form-textarea
							id="input-description"
							rows="3"
							v-model="newSalary.description"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</b-form-group>
				</ValidationProvider>
			</b-col>
		</b-row>

		<b-row v-if="boolIsEditEmployee">
			<b-col cols="6">
				<b-button
					v-on:click="getFromLastDataset"
					variant="outline-secondary"
					><em class="fad fa-retweet"></em>&nbsp;Vom letzten Datensatz
					übernehmen</b-button
				>
			</b-col>
			<b-col cols="6" class="text-right">
				<b-button v-on:click="saveNewSalary" variant="primary"
					><em class="fad fa-save"></em>&nbsp;Vergütung
					speichern</b-button
				>
			</b-col>
		</b-row>

		<div class="pt-10 pl-4" v-if="boolIsEditEmployee">
			<blockquote class="blockquote">
				<p class="mb-0">Historie</p>
			</blockquote>
		</div>

		<b-row v-if="boolIsEditEmployee">
			<b-col cols="12">
				<div class="table-responsive">
					<table
						class="table table-head-custom table-vertical-center"
					>
						<thead>
							<tr>
								<th width="20%">Basisgehalt</th>
								<th width="20%">Bonus</th>
								<th width="20%">Provision</th>
								<th width="20%">Son. Vereinbarung</th>
								<th class="text-right">Bearbeitung</th>
								<th width="50px"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="objSalary in data.salary"
								:key="objSalary.id"
							>
								<td>{{ objSalary.base_salary | currency }}</td>
								<td>{{ (objSalary.bonus || 0) | currency }}</td>
								<td>
									{{
										(objSalary.fee_agreement || 0)
											| currency
									}}
								</td>
								<td>
									{{
										(objSalary.other_agreement || 0)
											| currency
									}}
								</td>
								<td class="text-right">
									{{ objSalary.created | limitTo(10) }}
								</td>
								<td>
									<a
										class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
										:id="objSalary.strPopoverId"
									>
										<em class="far fa-info-circle"
											>&nbsp;</em
										>
									</a>
									<b-popover
										:target="objSalary.strPopoverId"
										triggers="hover"
										placement="left"
									>
										<h4>Bemerkung:</h4>
										<div>
											{{ objSalary.description || '-' }}
										</div>
									</b-popover>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';

import { dateGetDate } from '@/core/project/plugins/dayjs';
export default {
	name: 'TabVerguetung',
	components: { AqFormGroup },
	data() {
		return {
			newSalary: {},
		};
	},
	created() {
		this.resetSalary();
	},
	props: {
		data: Object,
		boolIsEditEmployee: Boolean,
	},
	methods: {
		resetSalary() {
			this.newSalary = {
				base_salary: 0,
				bonus: 0,
				fee_agreement: 0,
				other_agreement: 0,
				created: dateGetDate(),
				description: '',
			};
		},
		getFromLastDataset() {
			if (this.data.salary && Array.isArray(this.data.salary)) {
				let _newSalary = { ...this.data.salary[0] };
				delete _newSalary.id;
				_newSalary.created = _newSalary.created.substring(0, 10);
				this.newSalary = _newSalary;
			}
		},
		saveNewSalary() {
			this.$emit('saveNewSalary', this.newSalary);
			this.resetSalary();
		},
	},
};
</script>
