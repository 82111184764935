<template>
	<div>
		<b-row class="pt-4 mx-4">
			<blockquote class="blockquote">
				<p class="mb-0">Neuen Eintrag anlegen</p>
			</blockquote>
		</b-row>
		<b-row class="pt-4 mx-4">
			<b-col class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Bemerkung"
					rules="required"
				>
					<AqFormGroup
						label="Bemerkung"
						:boolRequired="true"
						labelcolssm="2"
						:state="state"
					>
						<b-form-textarea
							id="input-description"
							rows="3"
							v-model="newBaseAnnotation.description"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="mx-4">
			<b-col cols="6">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Erinnerung"
					rules=""
				>
					<AqFormGroup
						label="Erinnerung am"
						labelcolssm="4"
						:state="state"
					>
						<b-form-input
							id="example-reminder_date"
							v-model="newBaseAnnotation.reminder_date"
							:class="classes"
							type="date"
						></b-form-input>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Kategorie"
					rules="required"
				>
					<AqFormGroup
						label="Kategorie"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-select
							id="input-annotationtype"
							:options="arrAnnotationTypes"
							v-model="newBaseAnnotation.annotationTypeCategoryId"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="pb-5">
			<b-col cols="12" class="text-right">
				<b-button v-on:click="resetAnnotation" variant="light"
					>Zurücksetzen</b-button
				>
				&nbsp;
				<b-button v-on:click="saveAnnotation" variant="primary"
					><em class="fad fa-save"></em>&nbsp;Hinzufügen</b-button
				>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';
import { dateGetTimestamp } from '@/core/project/plugins/dayjs';
import { fetchAnnotationTypeCategories } from '@/view/project/api/BaseData';

export default {
	name: 'PersonalaktForm',
	components: { AqFormGroup },
	data() {
		return {
			newBaseAnnotation: {},
			arrAnnotationTypes: [],
		};
	},
	mounted() {
		fetchAnnotationTypeCategories().then(response => {
			this.arrAnnotationTypes = response.data.data[1].map(type => ({
				value: type.id,
				text: type.title,
			}));
		});
	},
	created() {
		this.resetAnnotation();
	},
	methods: {
		resetAnnotation() {
			this.newBaseAnnotation = {
				description: '',
				reminder_date: null,
				annotationTypeCategoryId: 1,
			};
		},
		saveAnnotation() {
			let objNewAnnotation = {
				description: this.newBaseAnnotation.description,
				annotation_type_category_id: this.newBaseAnnotation
					.annotationTypeCategoryId,
				annotation_type_id: this.$Constants
					.annotationTypePersonaleintrag,
				created: dateGetTimestamp(),
			};

			if (this.newBaseAnnotation.reminder_date) {
				objNewAnnotation['reminder'] = {
					module: 'annotation',
					reminder_date: this.newBaseAnnotation.reminder_date,
				};
			}

			this.$emit('saveAnnotation', objNewAnnotation);
			this.resetAnnotation();
		},
	},
};
</script>

<style scoped></style>
