import VueCurrencyFilter from 'vue-currency-filter';
import Vue from 'vue';

Vue.filter('limitTo', function(strValue, intLimit) {
	return strValue.substring(0, intLimit);
});

Vue.filter('uppercase', function(strValue) {
	return !strValue ? '' : strValue.toUpperCase();
});

Vue.use(VueCurrencyFilter, {
	symbol: '€',
	thousandsSeparator: '.',
	fractionCount: 2,
	fractionSeparator: ',',
	symbolPosition: 'back',
	symbolSpacing: true,
	//avoidEmptyDecimals: undefined,
});
