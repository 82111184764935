var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"py-5"},[_c('h4',[_vm._v("Schäden am Fahrzeug")])]),_c('b-row',{staticClass:"border-top"},[_c('b-col',{staticClass:"py-0 border-right align-center",attrs:{"cols":"12","md":"6"}},[_c('ImageMark',{attrs:{"imageSrc":_vm.vehicleImage,"pointList":this.vehicleData.damage,"point-style":{}},on:{"pointClick":_vm.handlePointClick,"add":_vm.handleAdd}})],1),_c('b-col',{staticClass:"py-0 border-right",attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Sichtbare Mängel/Beschädigungen außen: (auch Schäden an der Windschutzscheibe!)")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(this.vehicleData.damage.length === 0)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"success","show":""}},[_vm._v(" Noch keine Schäden vorhanden. ")])],1)],1):_vm._e(),(this.vehicleData.damage.length > 0)?_c('div',_vm._l((this.vehicleData.damage),function(point){return _c('b-row',{key:point.id},[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":'Schaden ' + point.id,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('AqFormGroup',{attrs:{"label":'Schaden ' + point.id,"boolRequired":true,"state":state}},[_c('b-form-input',{class:classes,attrs:{"id":'input-damage-' + point.id,"maxlength":"255"},model:{value:(point.comment),callback:function ($$v) {_vm.$set(point, "comment", $$v)},expression:"point.comment"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)}),1):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-row',{staticClass:"border-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Sonstige Bemerkungen","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var state = ref.state;
var classes = ref.classes;
return [_c('b-form-group',{staticClass:"my-0",attrs:{"id":"fieldset-bemerkungen","label-size":"sm","label-cols-sm":"4","label-cols-lg":"3","label":"Sonstige Bemerkungen: ","state":state,"label-for":"input-description","label-class":"my-auto"}},[_c('b-form-textarea',{class:classes,attrs:{"id":"input-description","rows":"3"},model:{value:(_vm.data.comment),callback:function ($$v) {_vm.$set(_vm.data, "comment", $$v)},expression:"data.comment"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }