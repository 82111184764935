<template>
	<div>
		<b-row>
			<h4 class="py-3">Wartungsdaten</h4>
		</b-row>
		<b-row class="my-2">
			<h5>Öl nachgefüllt</h5>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Liter"
					rules="required"
				>
					<AqFormGroup
						label="Liter"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-oil_refill_quantity"
							v-model="data.oil_refill_quantity"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Datum"
					rules="required"
				>
					<AqFormGroup
						label="Datum"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-oil_refill_date"
							v-model="data.oil_refill_date"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="my-2">
			<h5>Kühlwasser nachgefüllt</h5>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Liter"
					rules="required"
				>
					<AqFormGroup
						label="Liter"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-heat_exchange_water_refill_quantity"
							v-model="data.heat_exchange_water_refill_quantity"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Datum"
					rules="required"
				>
					<AqFormGroup
						label="Datum"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-heat_exchange_water_refill_date"
							v-model="data.heat_exchange_water_refill_date"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row class="border-top mt-2 pt-2">
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="letzte Inspektion am"
					rules="required"
				>
					<AqFormGroup
						label="Letzte Inspektion am"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-last_service"
							v-model="data.last_service"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Ablaufdatum Verbandskasten"
					rules="required"
				>
					<AqFormGroup
						label="Ablaufdatum Verbandskasten"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-first_aid_kid_expiration_date"
							v-model="data.first_aid_kid_expiration_date"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" md="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Gültigkeit Vignette"
					rules="required"
				>
					<AqFormGroup
						label="Gültigkeit Vignette"
						:boolRequired="true"
						:state="state"
					>
						<b-form-input
							id="input-vignette_expiration_date"
							v-model="data.vignette_expiration_date"
							:class="classes"
							type="date"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';

export default {
	name: 'WartungsdatenComponent',
	components: { AqFormGroup },
  props: {
	  data: Object
  }
};
</script>

<style scoped></style>
