<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Dashboard </span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/module') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Module </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Aktivierte Module</span>
                <i class="menu-arrow"></i>
              </h3>
                <ul class="menu-inner">
                <router-link
                  to="/modules/fahrzeugmanagement/index"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <em class="fad fa-car"></em>
                      <span class="ml-5 menu-text">Fahrzeugverwaltung</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    to="/modules/mitarbeiter/index"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <em class="fad fa-users"></em><span class="ml-5 menu-text">Mitarbeiter</span>
                    </a>
                  </li>
                </router-link>
                </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
     <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/administration') }"
    >
     <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Administration </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item">
               <h3 class="menu-heading menu-toggle">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text"> Verwaltung </span>
                  <i class="menu-arrow"></i>
                </h3>

                <ul class="menu-inner">
                <router-link
                  to="/administration/benutzer/index"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <em class="fad fa-user"></em><span class="ml-5 menu-text">Benutzer</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/administration/roles/index"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <em class="fad fa-key"></em><span class="ml-5 menu-text">Rollen</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
