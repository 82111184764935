<template>
	<b-row>
		<b-col
			:cols="field.cols"
			v-for="field in this.signatureFields"
			v-bind:key="field.name"
		>
			<div
				class="container-fluid border text-center py-10 text--darken-4 bg-light cursor-pointer"
				v-if="field.signatureImageData === null"
				@click="openSignatureModal(field)"
			>
				Noch keine Unterschrift
			</div>
			<div
				class="container-fluid border text-center cursor-pointer"
				v-if="field.signatureImageData !== null"
				@click="openSignatureModal(field)"
			>
				<img :src="field.signatureImageData" width="100%" />
			</div>
			<div class="container-fluid text-center">
				{{ field.description }}
			</div>
		</b-col>

		<UnterschriftModalComponent :modal-data="modalData" @changed-signature="changeSignature" />
	</b-row>
</template>

<script>
import UnterschriftModalComponent from './UnterschriftModalComponent';
export default {
	name: 'UnterschriftComponent',
	components: { UnterschriftModalComponent },
	props: {
		signatureFields: Array,
	},
	data() {
		return {
			modalData: {},
		};
	},
	methods: {
		openSignatureModal(field) {
			this.modalData = field;
			this.$bvModal.show('signatureModal');
		},
    changeSignature(event) {
		  this.$emit('changed-signature', event);
    }
	},
};
</script>

<style scoped></style>
