<template>
	<div class="timeline-item align-items-start">
		<div
			class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
		>
			{{ objAnnotation.created | limitTo(16) }}
		</div>

		<div class="timeline-badge" v-if="objAnnotation.annotationtype">
			<em
				class="icon-xxl"
				v-bind:class="objAnnotation.annotationtype.icon_class"
				:title="objAnnotation.annotationtype.title"
			></em>
		</div>

		<div
			class="font-weight-mormal font-size-sm timeline-content text-muted ml-4 bg-light p-2"
		>
			<b
				v-if="
					objAnnotation.annotationtype &&
						objAnnotation.annotationtypecategory
				"
			>
				<span
					v-show="
						objAnnotation.annotation_type_category_id === '0' &&
							objAnnotation.annotation_type_id !==
								this.$Constants.annotationTypePersonaleintrag
					"
				>
					<span>PERSONALEINTRAG</span>
				</span>
				<span
					v-show="
						objAnnotation.annotation_type_category_id !== '0' &&
							objAnnotation.annotation_type_id !==
								this.$Constants.annotationTypePersonaleintrag
					"
				>
					<span>{{
						objAnnotation.annotationtypecategory.title | uppercase
					}}</span>
				</span>
				<span
					v-show="
						objAnnotation.annotation_type_id ===
							this.$Constants.annotationTypePersonaleintrag
					"
				>
					<span>{{
						objAnnotation.annotationtype.title | uppercase
					}}</span>
				</span>
			</b>
			<b-badge
				class="ml-2 pull-right"
				variant="info"
				v-if="objAnnotation.lasteditor"
				>{{ objAnnotation.lasteditor.username | uppercase }}</b-badge
			>
			<br /><br />

			<span class="pl-2">
				<span v-html="objAnnotation.description"></span>
			</span>

			<span
				v-if="
					objAnnotation.reminder &&
						objAnnotation.reminder.active === '1'
				"
				class="pl-4"
			>
				<br /><br />
				<em
					>offene Erinnerung:
					{{ objAnnotation.reminder.reminder_date }}</em
				>&nbsp;
				<br />
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PersonalaktLine',
	components: {},
	data() {
		return {};
	},
	computed: {},
	props: {
		objAnnotation: Object,
	},
};
</script>

<style scoped>
.timeline.timeline-6 .timeline-item .timeline-label {
	width: 140px;
}

.timeline.timeline-6:before {
	left: 140px;
}
</style>
