<template>
	<view-form :forForm="forForm">
		<template v-slot:formLayout>
			<ValidationObserver ref="observerStammdaten">
				<h2>Stammdaten</h2>

				<div class="form-row">
					<div class="col-md-6">
						<input-builder
							:value.sync="data.name"
							:forInput="FormInputs.name"
						></input-builder>
					</div>
					<div class="col-md-3">
						<input-builder
							:value.sync="data.weighting"
							:forInput="FormInputs.weighting"
						></input-builder>
					</div>
					<div class="col-md-3">
						<input-builder
							:value.sync="data.active"
							:forInput="FormInputs.active"
						></input-builder>
					</div>
				</div>
			</ValidationObserver>
		</template>
	</view-form>
</template>
<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Roles();
const ViewForm = Controller.newForm();

const FormInputs = {
	name: ViewForm.Input()
		.required()
		.translateLabel('fields.name')
		.build(),
	weighting: ViewForm.Input()
		.required()
		.icon('fa-fw fa-sort-numeric-up-alt')
		.translateLabel('fields.weighting')
		.build(),
	active: ViewForm.Input()
		.asSwitch()
		.translateLabel('fields.active')
		.build(),
};

export default {
	name: 'Roles-Edit',
	components: ViewForm.initComponents(),
	data() {
		ViewForm.validateRoute(this);
		ViewForm.setInputs(FormInputs).forItem({
			active: 1,
		});
		return ViewForm.initData();
	},
	mounted: function() {
		ViewForm.initMounted();
	},
	computed: ViewForm.initComputed(),
};
</script>
