<template>
	<b-row>
		<b-col cols="3" v-if="forForm.withSidebar">
			<div class="aq-loading-card card card-custom card-stretch gutter-b">
				<slot name="sidebarLayout"></slot>
			</div>
		</b-col>
		<b-col>
			<b-form name="ModuleForm" @submit.prevent="forForm.onSubmit">
				<v-card :loading="forForm.loadingActive" :disabled="forForm.loadingActive">
					<v-alert dense type="info" v-if="forForm.isLoading" icon="fad fa-fw fa-spin fa-sync">
						{{ forForm.loadingText }}
					</v-alert>
					<v-alert dense type="success" v-if="forForm.isSaving" icon="fad fa-fw fa-spin fa-sync">
						{{ forForm.savingText }}
					</v-alert>
					<v-container>
						<slot name="formLayout"></slot>
					</v-container>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn depressed color="success" type="submit" :disabled="forForm.isSaving">
							<v-icon left>
								fad fa-fw fa-save
							</v-icon>
							{{ forForm.saveText }}
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn depressed @click="forForm.onCancel()" :disabled="forForm.isSaving">
							<v-icon left>
								fad fa-fw fa-window-close
							</v-icon>
							{{ forForm.cancelText }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</b-form>
		</b-col>
	</b-row>
</template>
<script>
	export default {
		props: {
			forForm : { type : Object },
			data : { type : Object }
		}
	};
</script>