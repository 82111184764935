<template>
	<ValidationProvider
		v-slot="{ errors, state, classes }"
		:name="forInput.label"
		:rules="inputRules">
		<b-form-group
			:state="state"
			:label-for="input_id"
			:description="forInput.description"
			:label-class="forInput.labelclass"
			:class="forInput.cssclass">
				<template v-slot:label >
					<div class="form-row">
						<div class="col">
							<icon-title :item="{
								text: fullLabel,
								icon : forInput.icon
							}"></icon-title>
						</div>
						<div class="col-auto" v-if="inputKind === 'dropdown'">
							<a @click="clearDropdown" class="text-muted" v-if="settings.showClear"><em class="fad fa-fw fa-trash-alt"></em>&nbsp;Leeren</a>
						</div>
					</div>
				</template>
				<slot name="input">
					<div v-if="inputKind === 'text'"> 
						<b-form-input :id="input_id" :type="inputType" v-model="_value" :placeholder="forInput.placeholder"></b-form-input>
					</div>
					<div v-if="inputKind === 'file'"> 
						<b-form-file :id="input_id" v-model="_value" :placeholder="forInput.placeholder" :multiple="settings.multiple" ref="file-input"></b-form-file>
						<div class="row">
							<div class="col">
							
							</div>
							<div class="col-auto" v-if="valueChanged">
								<a @click="clearFiles" class="text-muted"><em class="fad fa-fw fa-trash-alt"></em>&nbsp;Leeren</a>
							</div>
						</div>
					</div>
					<div v-if="inputKind === 'dropdown'">
						<b-dropdown no-caret>
							<template #button-content>
								<span v-if="settings.current">
									<icon-title :item="settings.current"></icon-title>
								</span>
								<span v-else>
									<icon-title :item="settings.default"></icon-title>
								</span>
							</template>
							<div>
								<b-dropdown-item @click="handleDropdown(item)" v-for="item in forInput.options">
									<icon-title :item="item"></icon-title>
									<p v-if="item.description" class="text-muted m-0">{{item.description}}</p>
								</b-dropdown-item>
							</div>
						</b-dropdown>
					</div>
					<div v-if="inputKind === 'checks'">
						<b-form-checkbox-group :id="input_id" v-model="_value" :options="forInput.options" :stacked="settings.stacked" :buttons="settings.btn" :button-variant="settings.style"></b-form-checkbox-group>
					</div>
					<div v-if="inputKind === 'radio'">
						<b-form-radio-group :id="input_id" v-model="_value" :options="forInput.options" :stacked="settings.stacked" :buttons="settings.btn" :button-variant="settings.style"></b-form-radio-group>
					</div>
					<div v-else-if="inputKind === 'select'">
						<b-form-select :id="input_id" v-model="_value" :options="forInput.options"></b-form-select>
					</div>
					<div v-else-if="inputKind === 'textarea'">
						<b-form-textarea :id="input_id" v-model="_value" :placeholder="forInput.placeholder" :rows="settings.rows" :max-rows="settings.maxRows"></b-form-textarea>
					</div>
				</slot>
				<slot name="error">
					<span class="text-danger" v-if="forInput.required" v-show="errors[0]">{{ errors[0] }}</span>
				</slot>
		</b-form-group>
	</ValidationProvider>
</template>
<script>
	import Vue from 'vue';
	const IconTitle = Vue.Aq.Loader.layout().input('IconTitle').get();

	export default {
		name: 'InputBuilder',
		components : {
			'icon-title' : IconTitle
		},
		props: {
			forInput : { type : Object },
			value : {}
		},
		data: function() 
		{
			let settings = {};
			switch(this.getType())
			{
				case "file":
					settings = this.inputSettings({
						multiple : false
					});
				break;

				case "files":
					settings = this.inputSettings({
						multiple : true
					});
				break;

				case "textarea":
					settings = this.inputSettings({
						rows : 3,
						maxRows : 6
					});
				break;

				case "checks-btn":
				case "radio-btn":
					settings = this.inputSettings({
						stacked : false,
						btn : true,
						style : 'primary'
					});
				break;

				case "radio":
				case "checks":
					settings = this.inputSettings({
						stacked : true,
						btn : false,
						style : 'primary'
					});
				break;

				case "dropdown":
					settings = this.inputSettings({
						default : {
							value : null,
							text : "Bitte auswählen"
						},
						showClear : false,
						current : false
					});
					if(settings.default === null)
					{
						settings.showClear = false;
					}

					if(typeof(this.value) !== "undefined" 
						&& typeof(this.forInput.options) !== "undefined")
					{
						if(settings.default !== null)
						{
							settings.showClear = true;
						}
						for(let option_index in this.forInput.options)
						{
							let item = this.forInput.options[option_index];
							if(item.value == this.value)
							{
								settings.current = item;
							}
						}
					}
				break;
			}

			let valueAvailable = false;
			if(typeof(this.value) !== "undefined")
			{
				valueAvailable = true;
			}
			return {
				formgroup_id: 'fieldset-' + this.forInput.label,
				input_id: 'input-' + this.forInput.label,
				settings : settings,
				valueAvailable : valueAvailable,
				valueChanged : false
			};
		},
		methods : 
		{
			getType : function()
			{
				if(typeof(this.forInput.type) !== "undefined")
				{
					return this.forInput.type;
				}
				return "text";
			},
			inputSettings : function(defaultSettings)
			{
				let settings = {};
				if(typeof(this.forInput.settings) === "object")
				{
					settings = this.forInput.settings;
				}
				return {...defaultSettings, ...settings};
			},
	 		clearFiles() 
			{
				this.$refs['file-input'].reset()
			},
			clearDropdown()
			{
				this.settings.current = false;
				this.settings.showClear = false;
				this.$emit('update:value', null);
			},
			handleDropdown(item)
			{
				this.settings.current = item;
				if(this.settings.default !== null)
				{
					this.settings.showClear = true;
				}
				this.$emit('update:value', item.value);
			}
		},
		computed: 
		{
			_value: 
			{
				get()
				{
					switch(this.getType())
					{
						case "select":
							if(typeof(this.value) === "undefined")
							{
								return null;
							}
						break;
					}
					return this.value;
				},
				set (value)
				{
					this.valueChanged = false;
					if(value !== null)
					{
						switch(this.getType())
						{
							case "files":
								if(typeof(value) === "object" 
									&& value.length)
								{
									this.valueChanged = true;
								}
							break;

							default:
								this.valueChanged = true;
							break;
						}
					}
					this.$emit('update:value', value)
				}
			},
			inputKind : function()
			{
				let input_type = this.getType();
				switch(input_type)
				{
					case "number":
					case "email":
					case "password":
					case "url":
					case "date": 
					case "time":
					case "color":
						return 'text';
					break;

					case "checks-btn":
						return 'checks';
					break;

					case "radio-btn":
						return 'radio';
					break;

					case "files":
						return 'file';
					break;

					default:
						return input_type;
					return;
				}
			},
			inputType : function()
			{
				return this.getType();
			},
			inputRules : function()
			{
				if(typeof(this.forInput.required) !== "undefined" 
					&& this.forInput.required)
				{
					if(typeof(this.forInput.rules) !== "undefined")
					{
						return this.forInput.rules;
					}
					return 'required';
				}
				return '';
			},
			fullLabel: function() 
			{
				let label =this.forInput.label + ': ';
				if(typeof(this.forInput.required) !== "undefined" 
					&& this.forInput.required)
				{
					label += '*';
				}
				return label;
			}
		},
	};
</script>