<template>
	<v-dialog v-model="forDelete.open" max-width="290" persistent>
		<v-card class="m-0 rounded-0">
			<v-card-title class="headline">{{forDelete.title}}</v-card-title>
			<v-alert type="warning" dense v-if="forDelete.isDeleting" icon="fad fa-fw fa-spin fa-sync" class="rounded-0">
				Eintrag wird gelöscht
			</v-alert>
			<v-alert type="warning" dense v-else-if="forDelete.question" class="rounded-0">
				{{ forDelete.question }}
			</v-alert>
			<v-card-text>
				<slot></slot>
			</v-card-text>
			<v-card-actions>
				<v-btn depressed color="error" @click="forDelete.doDelete(forDelete.passEntry)" :disabled="forDelete.isDeleting">
					<v-icon left>
						fa-fw fa-trash
					</v-icon>
					{{ forDelete.doText }}
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn depressed @click="forDelete.closeModal" :disabled=" forDelete.isDeleting">
					<v-icon left>
						fa-fw fa-window-close
					</v-icon>
					{{ forDelete.cancelText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	export default {
		props: {
			forDelete : {
				type: Object
			}
		}
	}
</script>