import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import config from '@/core/config/layout.config.json';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	theme: {
		dark: false,
		options: {
			customProperties: true,
			variations: false,
		},
		themes: {
			light: config.colors.theme.base,
		},
	},
});
