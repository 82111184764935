<template>
	<view-form :forForm="forForm">
		<template v-slot:formLayout>
			<ValidationObserver ref="observerStammdaten">
				<h2>Stammdaten</h2>
				<div class="form-row">
					<div class="col-md-6">
						<input-builder
							:value.sync="data.username"
							:forInput="FormInputs.user"
						></input-builder>
					</div>
					<div class="col-md-6">
						<person-autocomplete
							:value.sync="data.person"
							:forInput="FormInputs.people"
						></person-autocomplete>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-4">
						<input-builder
							:value.sync="data.active"
							:forInput="FormInputs.active"
						></input-builder>
					</div>
				</div>
			</ValidationObserver>
		</template>
	</view-form>
</template>
<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Benutzer();
const Mitarbeiter = Vue.Aq.Modules.Mitarbeiter();
const MitarbeiterAutocomplete = Mitarbeiter.loadInput('Autocomplete');
const ViewForm = Controller.newForm();

const FormInputs = {
	user: ViewForm.Input()
		.required()
		.label(Controller.Translation('title'))
		.icon('fa-fw fa-key')
		.hint(Controller.Translation('hintUser'))
		.build(),
	people: Mitarbeiter.inputEntries('benutzer-form')
		.required()
		.errorField('aqe_people_id')
		.hint(Controller.Translation('hintPeople'))
		.onChange(function(item) {
			if (typeof item !== 'undefined') {
				ViewForm.Form().data.aqe_people_id = item.id;
			} else {
				ViewForm.Form().data.aqe_people_id = null;
			}
		})
		.build(),
	active: ViewForm.Input()
		.asSwitch()
		.label(Controller.Translate().tc('fields.active'))
		.build(),
};

export default {
	name: 'Benutzer-Edit',
	components: ViewForm.initComponents({
		'person-autocomplete': MitarbeiterAutocomplete,
	}),
	data() {
		ViewForm.validateRoute(this);
		return ViewForm.setInputs(FormInputs)
			.forApi(function(apiParams) {
				return apiParams
					.connection('person')
					.connection('person:businessdata');
			})
			.forItem({
				person: {},
			})
			.forSubmit({
				validateForm: function(hasError) {
					if (
						typeof ViewForm.Form().data.aqe_people_id ===
							'undefined' ||
						ViewForm.Form().data.aqe_people_id === null
					) {
						hasError = true;
						ViewForm.setValidationErrors({
							aqe_people_id: [
								Controller.Translation('errorPeople'),
							],
						});
					}
					return hasError;
				},
			})
			.initData();
	},
	mounted: function() {
		ViewForm.initMounted();
	},
	computed: ViewForm.initComputed(),
};
</script>
