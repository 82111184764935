var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline-item align-items-start"},[_c('div',{staticClass:"timeline-label font-weight-bolder text-dark-75 font-size-lg"},[_vm._v(" "+_vm._s(_vm._f("limitTo")(_vm.objAnnotation.created,16))+" ")]),(_vm.objAnnotation.annotationtype)?_c('div',{staticClass:"timeline-badge"},[_c('em',{staticClass:"icon-xxl",class:_vm.objAnnotation.annotationtype.icon_class,attrs:{"title":_vm.objAnnotation.annotationtype.title}})]):_vm._e(),_c('div',{staticClass:"font-weight-mormal font-size-sm timeline-content text-muted ml-4 bg-light p-2"},[(
				_vm.objAnnotation.annotationtype &&
					_vm.objAnnotation.annotationtypecategory
			)?_c('b',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.objAnnotation.annotation_type_category_id === '0' &&
						_vm.objAnnotation.annotation_type_id !==
							this.$Constants.annotationTypePersonaleintrag
				),expression:"\n\t\t\t\t\tobjAnnotation.annotation_type_category_id === '0' &&\n\t\t\t\t\t\tobjAnnotation.annotation_type_id !==\n\t\t\t\t\t\t\tthis.$Constants.annotationTypePersonaleintrag\n\t\t\t\t"}]},[_c('span',[_vm._v("PERSONALEINTRAG")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.objAnnotation.annotation_type_category_id !== '0' &&
						_vm.objAnnotation.annotation_type_id !==
							this.$Constants.annotationTypePersonaleintrag
				),expression:"\n\t\t\t\t\tobjAnnotation.annotation_type_category_id !== '0' &&\n\t\t\t\t\t\tobjAnnotation.annotation_type_id !==\n\t\t\t\t\t\t\tthis.$Constants.annotationTypePersonaleintrag\n\t\t\t\t"}]},[_c('span',[_vm._v(_vm._s(_vm._f("uppercase")(_vm.objAnnotation.annotationtypecategory.title)))])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.objAnnotation.annotation_type_id ===
						this.$Constants.annotationTypePersonaleintrag
				),expression:"\n\t\t\t\t\tobjAnnotation.annotation_type_id ===\n\t\t\t\t\t\tthis.$Constants.annotationTypePersonaleintrag\n\t\t\t\t"}]},[_c('span',[_vm._v(_vm._s(_vm._f("uppercase")(_vm.objAnnotation.annotationtype.title)))])])]):_vm._e(),(_vm.objAnnotation.lasteditor)?_c('b-badge',{staticClass:"ml-2 pull-right",attrs:{"variant":"info"}},[_vm._v(_vm._s(_vm._f("uppercase")(_vm.objAnnotation.lasteditor.username)))]):_vm._e(),_c('br'),_c('br'),_c('span',{staticClass:"pl-2"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.objAnnotation.description)}})]),(
				_vm.objAnnotation.reminder &&
					_vm.objAnnotation.reminder.active === '1'
			)?_c('span',{staticClass:"pl-4"},[_c('br'),_c('br'),_c('em',[_vm._v("offene Erinnerung: "+_vm._s(_vm.objAnnotation.reminder.reminder_date))]),_vm._v(" "),_c('br')]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }