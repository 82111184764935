<template>
	<b-row>
		<b-col cols="12">
			<div class="card card-custom bg-gray-100 card-stretch gutter-b">
				<b-card no-body>
					<b-form name="EditFahrzeugForm" @submit.prevent="onSubmit">
						<b-card-body class="p-0">
							<b-tabs card>
								<ValidationObserver ref="observerFahrzeugdaten">
									<b-tab title="Fahrzeugdaten" active>
										<b-card-text>
											<FahrzeugdatenComponent
												:vehicle="this.vehicle"
											/>
										</b-card-text>
									</b-tab>
								</ValidationObserver>
							</b-tabs>
						</b-card-body>
						<b-card-footer class="card card-body bg-light">
							<div class="text-right">
								<b-button variant="secondary" :to="'/modules/fahrzeugmanagement/index'">Abbrechen</b-button
								>&nbsp;
								<b-button variant="primary" type="submit"
									><em class="fad fa-save"></em
									>&nbsp;Speichern</b-button
								>
							</div>
						</b-card-footer>
					</b-form>
				</b-card>
			</div>
		</b-col>
	</b-row>
</template>

<script>
import FahrzeugdatenComponent from './components/FahrzeugdatenComponent.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import AqToast from '@/view/project/components/AqToast';
import { fetchVehicle, saveVehicle } from '@/view/project/api/VehicleData';

export default {
	name: 'EditFahrzeugmanagement',
	components: { FahrzeugdatenComponent },
	data() {
		return {
			vehicle: {},
		};
	},
	methods: {
		async onSubmit() {
			let _boolFahrzeugdatenValidation = await this.$refs.observerFahrzeugdaten.validate();

			if (!_boolFahrzeugdatenValidation) {
				new AqToast().showValidationErrors(
					this.$refs.observerFahrzeugdaten.errors,
					'Fahrzeugdaten',
				);
			} else {
				saveVehicle(this.vehicle).then(response => {
					if (!this.boolIsEditVehicle) {
						let _data = response.data;
						this.showSuccessToastr();
						this.$router.go({
							name: 'administrationfahrzeugmanagementedit',
							params: { id: _data.data.id },
						});
					} else {
						this.showSuccessToastr();
						this.getData();
					}
				});
			}
		},
		showSuccessToastr() {
			new AqToast().callToast(
				this.boolIsEditVehicle
					? 'Fahrzeug wurde erfolgreich gespeichert'
					: 'Fahrzeug wurde erfolgreich angelegt',
				'success',
			);
		},
		async getData() {
			fetchVehicle(this.$route.params.viewKey)
				.then(response => {
					if (response.data.status === true) {
						this.vehicle = response.data.data;
					} else {
						new AqToast().callToast(response.message);
					}
				})
				.catch(error => {
					new AqToast().callToast(error);
				});
		},
	},
	created() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Module' },
			{
				title: 'Fahrzeugverwaltung',
				route: '/administration/fahrzeugmanagement/index',
			},
			{ title: 'Fahrzeug anlegen' },
		]);
		if (this.boolIsEditVehicle) {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ title: 'Module' },
				{
					title: 'Fahrzeugverwaltung',
					route: '/administration/fahrzeugmanagement/index',
				},
				{ title: 'Fahrzeug bearbeiten' },
			]);

			this.getData();
		}
	},
	computed: {
		boolIsEditVehicle() {
			return typeof this.$route.params.viewKey !== 'undefined';
		},
	},
};
</script>

<style scoped></style>
