<template>
	<view-list :forList="forList" :forDelete="forDelete" :forTable="forTable">
		<template v-slot:deleteLayout>
			<b-row>
				<div class="col-auto">
					{{ forDelete.passEntry.id }}
				</div>
				<div class="col">
					{{ forDelete.passEntry.firstname }}
				</div>
				<div class="col">
					{{ forDelete.passEntry.lastname }}
				</div>
			</b-row>
		</template>
	</view-list>
</template>
<script>
import Vue from 'vue';
const Controller = Vue.Aq.Modules.Roles();
const ViewList = Controller.newList();

export default {
	name: 'Roles-Index',
	components: ViewList.initComponents(),
	mounted() {
		Controller.setBreadcrump('index');
	},
	data() {
		return ViewList.forTable({
			headers: [
				ViewList.Column()
					.columnID()
					.build(),
				ViewList.Column()
					.textTranslate('fields.name')
					.field('fullname')
					.build(),
				ViewList.Column()
					.columnActive()
					.build(),
				ViewList.Column().columnActions(),
			],
		}).initData(this);
	},
	computed: ViewList.initComputed(),
};
</script>
