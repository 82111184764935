<template>
	<component :is="componentInstance" />
</template>

<script>
/**
 * General Component Loader for Modules
 * Every Module is found under @/view/project/modules/{path}/{module}/{view}.vue
 * The relative part : project/modules/ is loaded via window.Aq.Paths
 */
export default {
	computed: {
		componentInstance() {
			let import_parts = [];
			if (this.$route.params.path) {
				import_parts.push(this.$route.params.path);
			}
			if (this.$route.params.module) {
				import_parts.push(this.$route.params.module);
			}
			if (this.$route.params.view) {
				let view = this.$Aq.Paths.capitalize(this.$route.params.view);
				if (view == 'New') {
					view = 'Edit';
				}
				import_parts.push(view);
			}

			let import_relative = import_parts.join('/');
			return () =>
				import(
					'@/view/' +
						this.$Aq.Paths.project()
							.module(import_relative)
							.get()
				)
					.then(m => m.default)
					.catch(() =>
						import(
							'@/view/' +
								this.$Aq.Paths.project()
									.error('Error404')
									.get()
						),
					);
		},
	},
};
</script>
