var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","size":"xl","id":"signatureModal","title":_vm.modalData.description},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":cancel}},[_vm._v("Abbrechen")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_c('em',{staticClass:"fad fa-save"}),_vm._v(" Unterschrift übernehmen")])]}}])},[_c('b-container',{attrs:{"fluid":"","id":"signatureModalContainer"}},[_c('b-row',[_c('b-col',{directives:[{name:"b-visible",rawName:"v-b-visible",value:(_vm.calcHeight),expression:"calcHeight"}],staticClass:"border",attrs:{"cols":"12"}},[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"width":'100%',"height":this.height + 'px',"options":{
						onBegin: function () {
							_vm.$refs.signaturePad.resizeCanvas();
						},
					}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }