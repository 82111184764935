import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';

Vue.config.productionTip = false;

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/metronic';
import '@/core/project/plugins/aqfilters';

// Konstanten (https://www.gorges.us/blog/vue-and-application-constants/)
Vue.prototype.$Constants = {
	annotationTypeMitarbeitergespraech: 1,
	annotationTypePersonaleintrag: 2,
	DOCFILE_CATEGORY_EMPLOYEE_PICTURE: 1,
	DOCFILE_CATEGORY_EMPLOYEE_DOCUMENT: 2,
};

import '@mdi/font/css/materialdesignicons.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'datatables.net';
import 'datatables.net-bs4';

import VueToast from 'vue-toast-notification';
Vue.use(VueToast, {
	icon: false,
});

import VdtnetTable from '@/view/project/components/VdtnetTable';
Vue.component('VdtnetTable', VdtnetTable);

import {
	ValidationProvider,
	ValidationObserver,
} from '@/core/project/plugins/vee-validate.js';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

ApiService.init();

//Aquendo Global Classes/Functions
import AqError from '@/core/project/AqError';
import AqPaths from '@/core/project/AqPaths';
import AqLoader from '@/core/project/AqLoader';
import AqModules from '@/core/project/AqModules';
import AqInputs from '@/core/project/AqInputs';
import AqColumn from '@/core/project/AqColumn';
import AqApiParams from '@/core/project/AqApiParams';
import AqController from '@/core/project/modules/AqController';
import AqList from '@/core/project/modules/AqList';
import AqForm from '@/core/project/modules/AqForm';

const aqPlugin = {
	install() {
		let AqObjects = {
			Paths: new AqPaths(),
			Loader: new AqLoader(),
			Modules: new AqModules(),
			Column: function() {
				return new AqColumn();
			},
			Inputs: function() {
				return new AqInputs();
			},
			ApiParams: function() {
				return new AqApiParams();
			},
			Error: function() {
				return new AqError();
			},
			Classes: {
				Controller: AqController,
				List: AqList,
				Form: AqForm,
			},
		};
		Vue.Aq = AqObjects;
		Vue.prototype.$Aq = AqObjects;
	},
};
Vue.use(aqPlugin);

//router verfügbar machen
Vue.$router = router;
//store verfügbar machen
Vue.$store = store;

//Datatable global ..

new Vue({
	router,
	i18n,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');
