// Germany
export const locale = {
	TRANSLATOR: {
		SELECT: 'Wähle deine Sprache',
	},
	MENU: {
		NEW: 'Neu',
		ACTIONS: 'Aktionen',
		CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
		PAGES: 'Pages',
		FEATURES: 'Eigenschaften',
		APPS: 'Apps',
		DASHBOARD: 'Instrumententafel',
	},
	AUTH: {
		GENERAL: {
			OR: 'Oder',
			SUBMIT_BUTTON: 'einreichen',
			NO_ACCOUNT: 'Hast du kein Konto?',
			SIGNUP_BUTTON: 'Anmelden',
			FORGOT_BUTTON: 'Passwort vergessen',
			BACK_BUTTON: 'Zurück',
			PRIVACY: 'Privatsphäre',
			LEGAL: 'Legal',
			CONTACT: 'Kontakt',
		},
		LOGIN: {
			TITLE: 'Create Account',
			BUTTON: 'Sign In',
		},
		FORGOT: {
			TITLE: 'Forgot Password?',
			DESC: 'Enter your email to reset your password',
			SUCCESS: 'Your account has been successfully reset.',
		},
		REGISTER: {
			TITLE: 'Sign Up',
			DESC: 'Enter your details to create your account',
			SUCCESS: 'Your account has been successfuly registered.',
		},
		INPUT: {
			EMAIL: 'Email',
			FULLNAME: 'Fullname',
			PASSWORD: 'Password',
			CONFIRM_PASSWORD: 'Confirm Password',
			USERNAME: 'Nutzername',
		},
		VALIDATION: {
			INVALID: '{{name}} is not valid',
			REQUIRED: '{{name}} is required',
			MIN_LENGTH: '{{name}} minimum length is {{min}}',
			AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
			NOT_FOUND: 'The requested {{name}} is not found',
			INVALID_LOGIN: 'The login detail is incorrect',
			REQUIRED_FIELD: 'Required field',
			MIN_LENGTH_FIELD: 'Minimum field length:',
			MAX_LENGTH_FIELD: 'Maximum field length:',
			INVALID_FIELD: 'Field is not valid',
		},
	},
	ECOMMERCE: {
		COMMON: {
			SELECTED_RECORDS_COUNT: 'Selected records count: ',
			ALL: 'All',
			SUSPENDED: 'Suspended',
			ACTIVE: 'Active',
			FILTER: 'Filter',
			BY_STATUS: 'by Status',
			BY_TYPE: 'by Type',
			BUSINESS: 'Business',
			INDIVIDUAL: 'Individual',
			SEARCH: 'Search',
			IN_ALL_FIELDS: 'in all fields',
		},
		ECOMMERCE: 'eCommerce',
		CUSTOMERS: {
			CUSTOMERS: 'Customers',
			CUSTOMERS_LIST: 'Customers list',
			NEW_CUSTOMER: 'New Customer',
			DELETE_CUSTOMER_SIMPLE: {
				TITLE: 'Customer Delete',
				DESCRIPTION:
					'Are you sure to permanently delete this customer?',
				WAIT_DESCRIPTION: 'Customer is deleting...',
				MESSAGE: 'Customer has been deleted',
			},
			DELETE_CUSTOMER_MULTY: {
				TITLE: 'Customers Delete',
				DESCRIPTION:
					'Are you sure to permanently delete selected customers?',
				WAIT_DESCRIPTION: 'Customers are deleting...',
				MESSAGE: 'Selected customers have been deleted',
			},
			UPDATE_STATUS: {
				TITLE: 'Status has been updated for selected customers',
				MESSAGE:
					'Selected customers status have successfully been updated',
			},
			EDIT: {
				UPDATE_MESSAGE: 'Customer has been updated',
				ADD_MESSAGE: 'Customer has been created',
			},
		},
	},
	general: {
		newEntry: 'Eintrag hinzufügen',
		editEntry: 'Eintrag bearbeiten',
		deleteEntry: 'Eintrag löschen',
		deleteQuestion: 'Wollen Sie den ausgewählten Eintrag wirklich löschen?',
		deletingText: 'Der Eintrag wird gelöscht',
		search: 'Suche',
		id: 'ID',
		title: 'Titel',
		actions: 'Aktionen',
		delete: 'Löschen',
		cancel: 'Abbrechen',
		save: 'Speichern',
		savingData: 'Daten werden gespeichert',
		loadingData: 'Daten werden geladen',
		entryCreated: 'Der Eintrag wurde erfolgreich erstellt',
		entryEdited: 'Der Eintrag wurde erfolgreich bearbeitet',
		entryDeleted: 'Der Eintrag wurde erfolgreich gelöscht',
	},
  list : {
    noData : "Keine Daten vorhanden",
    isLoading : "Daten werden geladen..."
  },
  fields : {
    name : "Name",
    username : "Benutzername",
    firstname : "Vorname",
    lastname : "Nachname",
    active : "Aktiv",
    date : "Datum",
    email : "E-Mail",
    weighting : "Gewichtung",
    vin: "Fahrgestellnummer",
    vehicle_nr: "Fahrzeugnummer",
    smallDescription: "Kurzbeschreibung",
    brand: "Marke",
    model: "Modell"
  },
  placeholder : {
    file : "Datei auswählen",
    files : "Dateien auswählen"
  },
  navigation :
  {
    administration : "Administration"
  },
  benutzer : {
    title : "Benutzer",
    hintUser : "Der eindeutige Name um sich im System anzumelden",
    hintPeople : "Der zugewiesene Mitarbeiter zu diesem Benutzer",
    errorPeople : "Es muss ein Mitarbeiter ausgewählt werden"
  },
  mitarbeiter : {
    title : "Mitarbeiter",
    branchoffice : "Standort"
  },
  roles : {
    title : "Rollen"
  },
  fahrzeugmanagement: {
    title: "Fahrzeugmanagement"
  }
};
