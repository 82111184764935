/**
 *A Simple Error Handler Class
 * @author E.Neuwirth
 */

class AqError {
	constructor() {
		this._objController = null;
		this._objClass = null;
		this._type = 'controller';
	}

	/**
	 * Recieves the controller object from where the error has been called
	 */
	withController(objController) {
		this._objController = objController;
		return this;
	}

	/**
	 * If the call was made from an AqForm or AqList Object the Error also has acces to this object
	 */
	withClass(objClass, type) {
		this._objClass = objClass;
		this._type = type;
		return this;
	}

	/**
	 * A critical error, for example missing params from a route
	 */
	critical(key, response) {
		let message = false;
		let gotoindex = false;

		if (typeof response === 'object' && typeof response.data === 'object') {
			if (typeof response.data.message !== 'undefined') {
				message = response.data.message;
			}
		}

		switch (this._type) {
			case 'form':
				switch (key) {
					case 'no-primary-key':
						gotoindex = true;
						this._objController
							.Toast()
							.callToast('Es wurde kein Parameter übergeben!');
						break;

					case 'get':
					case 'put':
						gotoindex = true;
						this._objController.Toast().callToast(message);
						break;
				}
				break;

			case 'list':
				switch (key) {
					case 'load':
					case 'delete':
						this._objController.Toast().callToast(message);
						break;
				}
				break;
		}

		if (gotoindex) {
			this._objController.route('index');
		}
	}

	/**
	 * A critical catched error from the api
	 */
	catched(key, response) {
		switch (this._type) {
			case 'form':
				switch (key) {
					case 'get':
					case 'put':
						this._objController.Toast().callToast(response);
						break;
				}
				break;

			case 'list':
				switch (key) {
					case 'load':
					case 'delete':
						this._objController.Toast().callToast(response);
						break;
				}
				break;
		}
	}
}
export default AqError;
