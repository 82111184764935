<template>
	<div v-if="data && data.privatedata">
		<b-row class="pt-4">
			<b-col cols="10">
				<apexchart
					class="card-rounded-bottom"
					:options="chartOptions"
					:series="series"
					type="area"
				></apexchart>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'TabGehaltsentwicklung',
	components: {},
	data() {
		return {
			series: [],
			chartOptions: {},
		};
	},
	props: {
		data: Object,
	},
	mounted() {
		this.createChart();
	},
	computed: {
		arrSalary() {
			return this.data.salary;
		},
	},
	watch: {
		data() {
			this.createChart();
		},
	},
	methods: {
		createChart() {
			this.series = [
				{
					name: 'Vergütung',
					data: this.arrSalary.map(sal => sal.base_salary).reverse(),
				},
			];
			this.chartOptions = {
				chart: {
					type: 'bar',
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					sparkline: {
						enabled: false,
					},
				},
        lotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				fill: {
					type: 'solid',
					opacity: 1,
				},
				stroke: {
					curve: 'smooth',
					show: true,
					width: 3,
				},
				xaxis: {
					categories: this.arrSalary
						.map(sal => 'ab ' + sal.created.substr(0, 10))
						.reverse(),
					axisBorder: {
						show: true,
					},
					axisTicks: {
						show: true,
					},
					labels: {
						show: false,
						style: {
							fontSize: '10px',
						},
					},
					crosshairs: {
						show: true,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					show: true,
					labels: {
						show: true,
						style: {
							fontSize: '12px',
						},
					},
				},
				states: {},
				tooltip: {
					style: {
						fontSize: '14px',
					},
					y: {
						formatter: function(val) {
							return val + ' €';
						},
					},
				},
				markers: {
					strokeWidth: 3,
				},
				grid: {
					show: true,
					padding: {
						left: 10,
						right: 10,
					},
				},
			};
		},
	},
};
</script>
