<template>
	<ValidationProvider
		:vid="forInput.errorField" 
		:name="forInput.label"
		:rules="inputRules"
		v-slot="{ errors, state, classes }">
		<div v-if="inputKind === 'text'"> 
			<v-text-field
				filled
				v-model="_value"
				:hint="forInput.hint"
				:type="inputType"
				:label="forInput.label"
				:prefix="settings.prefix"
				:suffix="settings.suffix"
				:readonly="inputReadOnly"
				:disabled="inputDisabled"
				:prepend-inner-icon="forInput.icon"
				:error-messages="errors">
			</v-text-field>
		</div>
		<div v-else-if="inputKind === 'date'"> 
			<v-menu ref="vDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :disabled="inputDisabled">
				<template v-slot:activator="{ on }">
					<v-text-field
						v-model="_value"
						:label="forInput.label"
						:hint="forInput.hint"
						:prepend-inner-icon="settings.icon"
						:error-messages="errors"
						:disabled="inputDisabled"
						filled
						readonly
						clearable
						@click:clear="clearValue"
						v-on="on"></v-text-field>
				</template>
				<v-date-picker v-model="_value" @input="closeVModal" :locale="settings.locale"></v-date-picker>
			</v-menu>
		</div>
		<div v-else-if="inputKind === 'dates'"> 
			<v-menu ref="vDialog" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" :disabled="inputDisabled">
				<template v-slot:activator="{ on }">
					<v-combobox
						v-model="_value"
						:label="forInput.label"
						:hint="forInput.hint"
						:error-messages="errors"
						:prepend-inner-icon="settings.icon"
						@click:clear="clearValue"
						:disabled="inputDisabled"
						filled
						multiple
						chips
						small-chips
						readonly
						clearable
						v-on="on"></v-combobox>
				</template>
				<v-date-picker v-model="_value" :locale="settings.locale" :multiple="settings.multipleDates" :range="settings.dateRange" multiple no-title scrollable>
					<v-spacer></v-spacer>
					<v-btn depressed color="primary" @click="closeVModal">OK</v-btn>
				</v-date-picker>
			</v-menu>
		</div> 
		<div v-else-if="inputKind === 'file'">
			<v-file-input 
				:truncate-length="settings.truncate" 
				:label="forInput.label" 
				filled
				:disabled="inputDisabled"
				:placeholder="forInput.placeholder"
				:hint="forInput.hint" 
				:prepend-icon="settings.icon"
				:show-size="settings.showSize" 
				:counter="settings.counter" 
				:multiple="settings.multiple"
				:error-messages="errors">
				<template v-slot:selection="{ text }">
					<v-chip small label color="primary">
						{{ text }}
					</v-chip>
				</template>
			</v-file-input>
		</div>
		<div v-else-if="inputKind === 'checkbox'">
			<v-checkbox v-model="_value" :error-messages="errors" :readonly="inputReadOnly" :disabled="inputDisabled">
				<template v-slot:label>
					<div>{{ forInput.label }}</div>
				</template>
			</v-checkbox>
		</div>
		<div v-else-if="inputKind === 'switch'">
			<v-switch v-model="_value" 
				:label="forInput.label" 
				:color="settings.color" 
				:false-value="settings.falseValue" 
				:true-value="settings.trueValue" 
				:error-messages="errors" 
				:readonly="inputReadOnly" 
				:disabled="inputDisabled"></v-switch>
		</div>
		<div v-else-if="inputKind === 'radio'">
			<v-radio-group v-model="_value" :colum="settings.colum" :row="settings.row" :error-messages="errors" :readonly="inputReadOnly" :disabled="inputDisabled">
				<v-radio
					v-for="option in forInput.options"
					:key="option.value"
					:label="option.text"
					:value="option.value"></v-radio>
			</v-radio-group>
		</div>
		<div v-else-if="inputKind === 'radio-btn'">
			<v-btn-toggle v-model="_value" borderless :active-class="settings.color" :multiple="settings.multiple" :error-messages="errors">
				<v-btn
					v-for="option in forInput.options"
					:value="option.value">
					<v-icon left v-if="option.icon">
						{{ option.icon }}
					</v-icon>
					{{option.text}}
				</v-btn>
			</v-btn-toggle>
		</div>
		<div v-else-if="inputKind === 'select'">
			<v-select v-model="_value" 
				:items="forInput.options" 
				:label="forInput.label" 
				filled
				:hint="forInput.hint"
				:prepend-inner-icon="forInput.icon"
				:item-text="settings.itemText" 
				:item-value="settings.itemValue" 
				:error-messages="errors" 
				:readonly="inputReadOnly" 
				:disabled="inputDisabled"
				:multiple="settings.multiple"
				persistent-hint 
				single-line>
			</v-select>
		</div>
		<div v-else-if="inputKind === 'textarea'">
			<v-textarea 
				v-model="_value" 
				:hint="forInput.hint" 
				:label="forInput.label" 
				:auto-grow="settings.autogrow" 
				:counter="settings.counter" 
				:prepend-inner-icon="forInput.icon"
				:error-messages="errors" 
				:readonly="inputReadOnly" 
				:disabled="inputDisabled"
				:rows="settings.rows"
				filled>
			</v-textarea>
		</div>
		<div v-else-if="inputKind === 'autocomplete'">
			<v-autocomplete 
				v-model="_value" 
				:items="forInput.options" 
				:label="forInput.label" 
				filled
				:hint="forInput.hint"
				:prepend-inner-icon="forInput.icon"
				:item-text="settings.itemText" 
				:item-value="settings.itemValue" 
				:clearable="settings.clearable"
				:multiple="settings.multiple"
				:loading="settings.loading"
				:readonly="inputReadOnly" 
				:disabled="inputDisabled"
				:error-messages="errors"
				:return-object="settings.withObject">
				<template v-slot:selection="data">
					<template v-if="typeof data.item !== 'object'">
						{{ data.item }}
					</template>
					<template v-else>
						{{data.item[settings.itemText]}}
					</template>
				</template>
				<template v-slot:item="data">
					<template v-if="typeof data.item !== 'object'">
						<v-list-item-content v-text="data.item"></v-list-item-content>
					</template>
					<template v-else>
						<v-list-item-avatar v-if="data.item.icon">
							<v-icon>{{data.item.icon}}</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-html="data.item[settings.itemText]"></v-list-item-title>
							<v-list-item-subtitle v-if="data.item[settings.itemDescription]" v-html="data.item[settings.itemDescription]"></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</template>
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
					<slot :name="slot" v-bind="scope" />
				</template>
			</v-autocomplete>
		</div>
	</ValidationProvider>
</template>
<script>
	import Vue from 'vue';

	export default {
		name: 'InputBuilder',
		props: {
			forInput : { type : Object },
			value : {}
		},
		data: function() 
		{
			let obj = this;
			let settings = {};

			let inputReadOnly = false;
			let inputDisabled = false;
			if(typeof(this.forInput.disabled) === "boolean")
			{
				inputDisabled = this.forInput.disabled;
			}
			if(typeof(this.forInput.readonly) === "boolean")
			{
				inputReadOnly = this.forInput.readonly;
			}

			switch(this.getType())
			{
				case "autocomplete":
					settings = this.inputSettings({
						itemValue : 'value',
						itemText : 'text',
						itemDescription : 'description',
						clearable : true,
						multiple : false,
						loading : false,
						withObject : false,
						withPromise : false,
						setItems : false
					});

					if(settings.multiple)
					{
						settings.chips = true;
						settings.deletableChips = true;
					}
					if(typeof(settings.withPromise) === "object")
					{
						settings.loading = true;
						Promise.resolve(settings.withPromise).then(function(value) 
						{
							settings.loading = false;

							if(typeof(settings.setItems) === "function")
							{
								value = settings.setItems(value);
							}
							obj.forInput.options = value;
						});
					}else
					{
						if(typeof(settings.setItems) === "function")
						{
							obj.forInput.options = settings.setItems(obj.forInput.options);
						}
					}
				break;

				case "file":
					settings = this.inputSettings({
						multiple : false,
						truncate : 25,
						showSize : true,
						counter : false
					});
				break;

				case "files":
					settings = this.inputSettings({
						icon: 'fa-paperclip',
						multiple : true,
						truncate : 25,
						showSize : true,
						counter : true
					});
				break;

				case "textarea":
					settings = this.inputSettings({
						counter : true,
						autogrow : false,
						rows : 3
					});
				break;

				case "select":
					settings = this.inputSettings({
						itemValue : 'value',
						itemText : 'text',
					});
				break;

				case "radio":
					settings = this.inputSettings({
						row : true
					});
				break;

				case "switch":
					settings = this.inputSettings({
						color : "primary",
						falseValue : "0",
						trueValue : "1"
					});
				break;

				case "radio-btn":
					settings = this.inputSettings({
						multiple : false,
						color : "primary",
						icon : false
					});
				break;

				case "checks-btn":
				case "checks":
					settings = this.inputSettings({
						multiple : true,
						color : "primary"
					});
				break;

				case "date":
					settings = this.dateSettings();
				break;

				case "dates":
					settings = this.dateSettings();
					settings.multipleDates = true;
					settings.dateRange = false;
				break;

				case "date-range":
					settings = this.dateSettings();
					settings.multipleDates = false;
					settings.dateRange = true;
				break;

				default:
					settings = this.inputSettings({});
				break;
			}

			let valueAvailable = false;
			if(typeof(this.value) !== "undefined")
			{
				valueAvailable = true;
			}
			return {
				settings : settings,
				valueAvailable : valueAvailable,
				valueChanged : false,
				modalOpen : false,
				inputReadOnly : inputReadOnly,
				inputDisabled : inputDisabled
			};
		},
		methods : 
		{
			getType : function()
			{
				if(typeof(this.forInput.type) !== "undefined")
				{
					return this.forInput.type;
				}
				return "text";
			},
			inputSettings : function(defaultSettings)
			{
				let settings = {};
				if(typeof(this.forInput.settings) === "object")
				{
					settings = this.forInput.settings;
				}
				return {...defaultSettings, ...settings};
			},
			dateSettings : function()
			{
				return this.inputSettings({
					locale : "de-de",
					icon : "far fa-calendar-day"
				});
			},
			availableRules : function(defaultRules)
			{
				let rules = {};
				if(typeof(this.forInput.rules) === "object")
				{
					rules = this.forInput.rules;
				}
				return {...defaultRules, ...rules};
			},
			closeVModal : function()
			{
				let dialog = this.$refs['vDialog'];
				dialog.isActive = false;
			},
			clearValue : function()
			{
				this.$emit('update:value', null);
			}
		},
		computed: 
		{
			_value: 
			{
				get()
				{
					switch(this.getType())
					{
						case "select":
							if(typeof(this.value) === "undefined")
							{
								return null;
							}
						break;
					}
					return this.value;
				},
				set (value)
				{
					this.valueChanged = false;
					if(value !== null)
					{
						switch(this.getType())
						{
							case "files":
								if(typeof(value) === "object" 
									&& value.length)
								{
									this.valueChanged = true;
								}
							break;

							default:
								this.valueChanged = true;
							break;
						}
					}
					if(typeof(this.settings.onChange) === "function")
					{
						this.settings.onChange(value);
					}
					this.$emit('update:value', value);
				}
			},
			inputKind : function()
			{
				let input_type = this.getType();
				switch(input_type)
				{
					case "number":
					case "email":
					case "password":
					case "url":
					case "time":
					case "color":
						return 'text';
					break;

					case "checks":
					case "checks-btn":
						return 'radio-btn';
					break;

					case "files":
						return 'file';
					break;

					case "date-range":
						return 'dates'
					break;

					default:
						return input_type;
					return;
				}
			},
			inputType : function()
			{
				return this.getType();
			},
			inputRules : function()
			{
				if(typeof(this.forInput.required) !== "undefined" 
					&& this.forInput.required)
				{
					if(typeof(this.forInput.rules) !== "undefined")
					{
						return this.forInput.rules;
					}
					return 'required';
				}
				return '';
			},
			fullLabel: function() 
			{
				let label =this.forInput.label + ': ';
				if(typeof(this.forInput.required) !== "undefined" 
					&& this.forInput.required)
				{
					label += '*';
				}
				return label;
			}
		},
	};
</script>