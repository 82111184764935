/**
 * Fahrzeugmanagement
 */
import Vue from 'vue';

class Fahrzeugmanagement extends Vue.Aq.Classes.Controller
{
    apiUrl()
    {
        return "resources/aqe/vehicle/"
    }

    routeNavKey()
    {
        return "modules";
    }
};
export default Fahrzeugmanagement;
