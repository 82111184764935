//import AqDtColumn from './aqDtColumn';
import i18n from '@/core/plugins/vue-i18n';

class AqDtColumnBuilder {
	constructor() {
		this.column = {
			sortable: true,
			searchable: true,
		};
		return this;
	}

	setName(sName) {
		this.column.name = sName;
		return this;
	}

	setTitle(sLabel) {
		this.column.label = sLabel;
		return this;
	}

	notSortable() {
		this.column.sortable = false;
		return this;
	}

	notSearchable() {
		this.column.searchable = false;
		return this;
	}

	columnID() {
		return this.setName('id')
			.setTitle(i18n.tc('general.id'))
			.build();
	}

	columnTitle() {
		return this.setName('title')
			.setTitle(i18n.tc('general.title'))
			.build();
	}

	columnFirstName() {
		return this.setName('firstname')
			.setTitle(i18n.tc('fields.firstname'))
			.build();
	}

	columnLastName() {
		return this.setName('lastname')
			.setTitle(i18n.tc('fields.lastname'))
			.build();
	}

	build() {
		return this.column;
	}
}

export { AqDtColumnBuilder };
