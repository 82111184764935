/**
 *A loader for Classes
 * @author E.Neuwirth
 */
import AqPaths from '@/core/project/AqPaths';

class AqLoader extends AqPaths {
	require(relative_path) {
		try {
			const createdObject = require(`@/view/${relative_path}`).default;
			return createdObject;
		} catch (e) {
			console.log(e);
		}
	}

	get() {
		return this.require(this.finish());
	}

	controller(relative_path) {
		relative_path = this.validatePath(relative_path);

		let parts = relative_path.split(/[/ ]+/);
		let module = parts.pop();
		let path = parts.pop();
		let controllerName = this.capitalize(module) + '.js';

		let moduleController = this.project()
			.module(relative_path + '/' + controllerName)
			.get();

		return new moduleController(module, path);
	}
}
export default AqLoader;
