<template>
	<span>
		<span v-if="item.icon">
			<em v-bind:class="item.icon"></em>&nbsp;{{item.text}}
		</span>
		<span v-else>
			{{item.text}}
		</span>
	</span>
</template>
<script>
	export default {
		name: 'AqIconTitle',
		props: {
			item: {
				type: Object,
				required: true,
			}
		}
	};
</script>