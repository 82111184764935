import ApiService from '@/core/services/api.service';

let fetchSupervisors = async () => {
	return await ApiService.get('resources/aqe/people/items');
};

let fetchSkills = async () => {
	return await ApiService.get('resources/adm/skill/items');
};
let fetchBranchoffices = async () => {
	return await ApiService.get('resources/aqe/branchoffice/items');
};
let fetchCompanyscopes = async () => {
	return await ApiService.get('resources/adm/companyscope/items');
};

let fetchEmployments = async () => {
	return await ApiService.get('resources/adm/employment/items');
};

let fetchAnnotationTypeCategories = async () => {
	return await ApiService.get('resources/adm/annotationtypecategory/items');
};

let fetchGenders = () => {
	return [
		{ value: 'm', text: 'männlich' },
		{ value: 'f', text: 'weiblich' },
	];
};

let fetchCountries = async () => {
	return await ApiService.get('resources/adm/country/items');
};

export {
	fetchSupervisors,
	fetchSkills,
	fetchAnnotationTypeCategories,
	fetchBranchoffices,
	fetchCompanyscopes,
	fetchEmployments,
	fetchCountries,
	fetchGenders,
};
