<template>
	<div v-if="data && data.employmentdata">
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Personalnummer"
				>
					<AqFormGroup
						label="Personalnummer"
						labelcolssm="4"
						:state="state"
					>
						<b-form-input
							id="input-staff_number"
							v-model="data.employmentdata.staff_number"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Vorgesetzte(r)"
				>
					<AqFormGroup
						label="Vorgesetzte(r)"
						labelcolssm="4"
						:state="state"
					>
						<b-form-select
							id="input-geschlecht"
							:options="supervisors"
							v-model="data.employmentdata.supervisor_id"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Standort"
					rules="required"
				>
					<AqFormGroup
						label="Standort"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-select
							id="input-branchoffice"
							:options="branchoffices"
							v-model="data.branchoffice.id"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Abteilung"
					rules="required"
				>
					<AqFormGroup
						label="Abteilung"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-select
							id="input-companyscope"
							:options="companyscopes"
							v-model="data.employmentdata.company_scope_id"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<hr />
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Dienstverhältnis"
					rules="required"
				>
					<AqFormGroup
						label="Dienstverhältnis"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-select
							id="input-employmentid"
							:options="employments"
							v-model="data.employmentdata.emplyoment_id"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Wochenstunden"
					rules="required"
				>
					<AqFormGroup
						label="Wochenstunden"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-input
							id="input-hours_per_week"
							v-model="data.employmentdata.hours_per_week"
							:class="classes"
						/>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Vertragsbeginn"
					rules=""
				>
					<AqFormGroup
						label="Vertragsbeginn"
						:boolRequired="true"
						labelcolssm="4"
						:state="state"
					>
						<b-form-input
							id="example-objDate_employment_start"
							v-model="
								data.employmentdata.employment_start_formatted
							"
							:class="classes"
							type="date"
						></b-form-input>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
			<b-col cols="6" class="py-0">
				<ValidationProvider
					v-slot="{ errors, state, classes }"
					name="Vertragsende"
					rules=""
				>
					<AqFormGroup
						label="Vertragsende"
						labelcolssm="4"
						:state="state"
					>
						<b-form-input
							id="example-objDate_employment_end"
							v-model="data.employmentdata.employment_end_formatted"
							:class="classes"
							type="date"
						></b-form-input>
						<span class="text-danger" v-show="errors[0]">{{
							errors[0]
						}}</span>
					</AqFormGroup>
				</ValidationProvider>
			</b-col>
		</b-row>

		<b-row class="pt-4 pl-4">
			<blockquote class="blockquote">
				<p class="mb-0">Fähigkeiten</p>
			</blockquote>
		</b-row>

		<b-row>
			<b-col
				cols="6"
				v-for="objSkill in this.data.skillswerte"
				v-bind:key="objSkill.value"
			>
						<b-form-checkbox
              value="true"
              unchecked-value="false"
							v-model="objSkill.checked"
						>
						  {{ objSkill.text }}
            </b-form-checkbox>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<AqFormGroup label="Spezielle Fähigkeiten" labelclass="mt-0">
					<b-form-input
						id="input-skills_1"
						v-model="data.privatedata.skill_1"
					/><br />
					<b-form-input
						id="input-skills_2"
						v-model="data.privatedata.skill_2"
					/><br />
					<b-form-input
						id="input-skills_3"
						v-model="data.privatedata.skill_3"
					/><br />
				</AqFormGroup>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import AqFormGroup from '@/view/project/components/AqFormGroup';
import {
	fetchSupervisors,
	fetchBranchoffices,
	fetchSkills,
	fetchCompanyscopes,
	fetchEmployments,
} from '@/view/project/api/BaseData';

export default {
	name: 'TabStammdaten',
	props: {
		data: Object,
	},
	components: {
		AqFormGroup,
	},
	data() {
		return {
			supervisors: [],
			branchoffices: [],
			skills: [],
			companyscopes: [],
			employments: [],
		};
	},
  mounted: function () {
    fetchSupervisors().then(response => {
      this.supervisors = response.data.data[1].map(object => ({
        value: object.id,
        text: object.fullname,
      }));
    });

    fetchBranchoffices().then(response => {
      this.branchoffices = response.data.data[1].map(object => ({
        value: object.id,
        text: object.title,
      }));
    });

    fetchSkills().then(response => {
      this.skills = response.data.data[1].map(object => ({
        value: object.id,
        text: object.title,
      }));

      const boolIsNewObject = this.data.skillswerte && Object.keys(this.data.skillswerte).length === 0;
      const selectedSkills = (boolIsNewObject) ? this.data.skillswerte : [...this.data.skillswerte];
      this.data.skillswerte = [...this.skills.map((item) => Object.assign({}, item, (boolIsNewObject) ? {} :
          selectedSkills.find(skill => item.value === skill.skill_id) ?? {}
      ))
      ];
      this.data.skillswerte.map(item => {
        return item.checked = (item.people_id) ? true : false;
      });

    });

    fetchCompanyscopes().then(response => {
      this.companyscopes = response.data.data[1].map(object => ({
        value: object.id,
        text: object.title,
      }));
    });

    fetchEmployments().then(response => {
      this.employments = response.data.data[1].map(object => ({
        value: object.id,
        text: object.title,
      }));
    });
  },
};
</script>
