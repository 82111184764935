<template>
	<b-row>
		<b-col cols="12" class="py-0">
			<b-row v-for="option in this.options" v-bind:key="option.name">
				<b-col cols="6">
					<span>{{ option.title }}</span>
				</b-col>
				<b-col cols="6">
					<b-form-radio-group
						v-model="option.value"
						:name="'radio-' + option.title"
						@change="changeEquipment($event, option)"
						v-if="option.yes_no == 1"
					>
						<b-form-radio
							v-for="opt in ['Ja', 'Nein']"
							v-bind:key="opt"
							:value="opt"
							>{{ opt }}</b-form-radio
						>
					</b-form-radio-group>
					<b-form-radio-group
						v-model="option.value"
						:name="'radio-' + option.title"
						@change="changeEquipment($event, option)"
						v-else
					>
						<b-form-radio
							v-for="opt in ['i.O.', 'nicht i.O.']"
							v-bind:key="opt"
							:value="opt"
							>{{ opt }}</b-form-radio
						>
					</b-form-radio-group>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
export default {
	name: 'AusstattungPartialComponent',
	props: {
		options: Array,
		data: Object,
	},
	methods: {
		changeEquipment(event, option) {
			let obj = {
				equipment_id: option.id,
				note: event,
			};

			let blnAdded = false;

			this.data.equipment.forEach((item, index) => {
				if (item.equipment_id == option.id) {
					this.data.equipment[index] = obj;
					blnAdded = true;
				}
			});

			if (!blnAdded) {
				this.data.equipment.push(obj);
			}
		},
	},
};
</script>

<style scoped></style>
